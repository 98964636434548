/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getRecurringInvoiceList,
  recurringInvoiceCreate,
} from "../../../redux/actions/RecurringAction";
import { formatDateYYMMDD } from "../constant";
import AddEditRecurringModal from "./addEditRecurringModal";
import {
  getLedgerModuleList,
  postLedgerModuleList,
} from "../../../redux/actions/LedgerModuleAction";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { RECURRINGINVOICE_CREATE_RESET } from "../../../redux/constants/RecurringInvoiceConstant";
import { message } from "antd";
import { POST_LEDGERLIST_RESET } from "../../../redux/constants/ledgerListModuleConstant";
import { Link, useHistory } from "react-router-dom";
import Sidebar from "../../../layouts/Sidebar";
import Header from "../../../layouts/Header";
import { useSelector } from "react-redux";
import {
  getCompanyList,
  getCustomerList,
} from "../../../redux/actions/commonApiAction";
import useCalculateTotals from "../../../helpers/calculation";
import useUpdateFooterData from "../../../customers/updateFooterData";
import CustomerModal from "./customerModal";
import {
  customerCreation,
  getCustomerModuleList,
} from "../../../redux/actions/CustomerModuleAction";
import { CUSTOMER_ADD_RESET } from "../../../redux/constants/CustomerModuleCnstant";

const schema = yup.object({
  start_date: yup
    .date()
    .required("Start date is required")
    .nullable()
    .required("State date is required"),
  end_date: yup
    .date()
    .required("End date is required")
    .nullable()
    .required("End date is required"),
  customer: yup
    .number()
    .required("Customer is required")
    .typeError("Customer must be a number"),
});

const AddRecurring = () => {
  const [menu, setMenu] = useState(false);
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const selectRefs = useRef({});
  const datePickerRef = {
    start_date: useRef(null),
    end_date: useRef(null),
    invoice_date: useRef(null),
  };
  const history = useHistory();

  const [recurringInvoiceData, setRecurringInvoiceData] = useState({
    start_date: "",
    end_date: "",
    description: "",
    customer: null,
  });
  const [ledgerData, setLedgerData] = useState([
    {
      description: "",
      quantity: "",
      rate: "",
      gst_rate: "",
      gst_amount: "",
      amount: "",
      total: "",
      taxes: [],
    },
  ]);
  const [addError, setAddError] = useState({});
  const [itemsError, setItemsError] = useState(null);
  const [customerDetail, setCustomerDetail] = useState({});
  const [editable, setEditable] = useState({});
  const [data, setData] = useState([
    {
      ledger: null,
      description: "",
      product_code: "",
      quantity: "",
      rate: "",
      amount: "",
      gst_rate: "",
      gst_amount: "",
      total: "",
    },
  ]);
  const [ledgerListData, setLedgerListData] = useState([]);
  const [ledgerIndexData, setLedgerIndexData] = useState("");
  const [incomeId, setIncomeId] = useState([]);
  const [ledgerPage, setLedgerPage] = useState({ page: 1, perPage: 10 });
  const [footerData, setFooterData] = useState({});
  const [addLedgerData, setAddLedgerData] = useState({
    title: "",
    group: null,
    description: "",
  });
  const [apiError, setApiError] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [preventApiCall, setPreventApiCall] = useState(false);
  const [calculationData, setCalculationData] = useState({});
  const [show, setShow] = useState(false);

  const postLedgerList = useSelector((state) => state?.postLedgerList);
  const {
    loading: postLedgerLoading,
    error: postLedgerError,
    success: postLedgerSuccess,
    data: postLedgerData,
  } = postLedgerList;

  const groupList = useSelector((state) => state?.groupList);
  const { groupData } = groupList;

  const createRecurringInvoice = useSelector(
    (state) => state.addRecurringInvoice
  );
  const { success, loading, error } = createRecurringInvoice;
  const ledgerList = useSelector((state) => state?.ledgerList);
  const { loading: ledgerListLoading, data: expenseList } = ledgerList;
  const [showErrors, setShowErrors] = useState(true);

  //customer
  const [addCustomer, setAddCustomer] = useState({});
  const customerModuleList = useSelector((state) => state?.customerModuleList);
  const { page, perPage } = customerModuleList;
  const createCustomer = useSelector((state) => state?.createCustomer);
  const [customerApiError, setCustomerApiError] = useState({});
  const [isDisable, setIsDisable] = useState(false);
  const {
    loading: createCustomerLoading,
    error: createCustomerError,
    success: createCustomerSuccess,
    data: postCustomerData,
  } = createCustomer;

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: !isModalVisible && yupResolver(schema),
    defaultValues: recurringInvoiceData,
  });

  useEffect(() => {
    const fieldOrder = [
      "start_date",
      "end_date",
      "customer",
      // "company",
    ];
    const firstErrorField = fieldOrder.find((field) => errors[field]);
    if (firstErrorField) {
      let inputElement;
      if (firstErrorField === "customer") {
        inputElement = selectRefs.current[firstErrorField];
      } else {
        inputElement = document.getElementById(firstErrorField);
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [errors]);

  useEffect(() => {
    const fieldOrder = [
      "start_date",
      "end_date",
      "customer",
      // "company",
    ];
    const firstErrorField = fieldOrder.find((field) => error && error[field]);
    if (firstErrorField) {
      let inputElement;
      if (firstErrorField === "customer") {
        inputElement = selectRefs.current[firstErrorField];
      } else {
        inputElement = document.getElementById(firstErrorField);
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      dispatch({ type: RECURRINGINVOICE_CREATE_RESET });
      reset();
      setRecurringInvoiceData({
        start_date: "",
        end_date: "",
        description: "",
        customer: null,
      });
      setCustomerDetail({});
      setLedgerData([
        {
          description: "",
          quantity: "",
          rate: "",
          gst_rate: "",
          gst_amount: "",
          amount: "",
          total: "",
          taxes: [],
        },
      ]);
      // dispatch(
      //   getRecurringInvoiceList(page, perPage, "", "", "", "", "", "", "")
      // );
      history.push("/recurring-invoices");
      closeModal();
      messageApi.open({
        type: "success",
        content: "Recurring Invoice created successfully",
        duration: 5,
      });
    }
  }, [success]);

  useEffect(() => {
    setCustomerApiError(createCustomerError);
  }, [createCustomerError]);

  useEffect(() => {
    if (createCustomerSuccess && postCustomerData) {
      dispatch({ type: CUSTOMER_ADD_RESET });
      dispatch(getCustomerList());
      // dispatch(getCustomerModuleList(page, perPage));
      setAddCustomer({
        pan_number: "",
        gstin_number: "",
        name: "",
        zoho_contact_id: null,
        customer_code: "",
        address: "",
        zip_code: "",
        state: "",
        phone: "",
        contact_name: "",
        email: "",
        zoho_address_id: null,
        location: "",
      });
      handleModalClose("#customer_modal");
      setRecurringInvoiceData((prevState) => ({
        ...prevState,
        customer: postCustomerData ? postCustomerData?.id : null,
      }));
      setValue("customer", postCustomerData?.id);
      closeModal();
      messageApi.open({
        type: "success",
        content: "customer created successfully",
        duration: 5,
      });
    }
  }, [createCustomerSuccess, postCustomerData]);

  useEffect(() => {
    if ((ledgerPage?.page > 1 && expenseList?.next) || ledgerPage?.page === 1) {
      dispatch(
        getLedgerModuleList("", ledgerPage?.page, ledgerPage?.perPage, true)
      );
    }
  }, [ledgerPage?.page, ledgerPage?.perPage]);

  useEffect(() => {
    if (expenseList?.results) {
      const newVendors = expenseList?.results;

      // Create a Set of the current vendor ids/names for quick look-up
      const existingVendorIds = new Set(
        ledgerListData.map((vendor) => vendor.id)
      ); // Assuming `id` is the unique identifier

      // Filter newVendors to only include those that are not already in vendorList

      const uniqueNewVendors = newVendors.filter(
        (vendor) => !existingVendorIds.has(vendor.id)
      );

      // Combine the previous vendorList with the unique new ones
      const combinedVendorList = [...ledgerListData, ...uniqueNewVendors];
      setLedgerListData(combinedVendorList); // Update state with the combined list
    }
  }, [expenseList]);

  const handleLedgerPopupScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollHeight - scrollTop <= clientHeight + 10 &&
      !ledgerListLoading &&
      expenseList?.results?.length
    ) {
      setLedgerPage((prevState) => ({
        ...prevState,
        page: prevState?.page + 1,
        perPage: prevState?.perPage,
      }));
    }
  };

  const customerList = useSelector((state) => state?.customerList);
  const { customer } = customerList;

  const companyList = useSelector((state) => state.companyList);
  const { company } = companyList;

  useEffect(() => {
    dispatch(getCustomerList());
  }, [dispatch]);

  useEffect(() => {
    if (recurringInvoiceData?.customer) {
      const customerDetails = customer?.find(
        (el) => el?.id === recurringInvoiceData?.customer
      );
      setCustomerDetail({
        customer_code: customerDetails?.customer_code,
        gstin_number: customerDetails?.gstin_number,
        location: customerDetails?.location,
      });
    }
  }, [customer, recurringInvoiceData?.customer]);

  useEffect(() => {
    if (!customerDetail?.gstin_number && recurringInvoiceData?.customer) {
      const updatedData = data.map((item) => ({
        ...item,
        gst_rate: 0,
        gst_amount: 0,
      }));
      setData(updatedData);
      setFooterData((prevState) => ({
        ...prevState,
        gst_section: {},
        total_tax: 0,
      }));
    }
  }, [customerDetail?.gstin_number, recurringInvoiceData?.customer]);

  useEffect(() => {
    if (
      calculationData?.indexId !== "" &&
      calculationData?.key !== "gst_amount" &&
      calculationData?.key !== "amount" &&
      calculationData?.key !== "total"
    ) {
      const updatedData = [...data];
      if (updatedData[calculationData?.indexId]) {
        const rate =
          parseFloat(updatedData[calculationData?.indexId]["rate"]) || 0;
        const quantity =
          parseFloat(updatedData[calculationData?.indexId]["quantity"]) || 0;
        const gstRate =
          parseFloat(updatedData[calculationData?.indexId]["gst_rate"]) || 0;
        const amount = rate * quantity;

        updatedData[calculationData?.indexId]["amount"] = amount.toFixed(2);

        const gstAmount = (amount * gstRate) / 100;
        updatedData[calculationData?.indexId]["gst_amount"] = gstAmount.toFixed(
          2
        );

        const total = amount + gstAmount;
        updatedData[calculationData?.indexId]["total"] = total.toFixed(2);
      }
      setData(updatedData);
    }
  }, [calculationData]);

  const [scheduleData, setScheduleData] = useState({
    schedule_type: null,
    quarterly: {
      month_of_quarter: "", // 1st month of the quarter

      day_of_month: "", // 15th day of the month

      repeat_every: "", // Every quarter
    },
    weekly: {
      days_of_week: [], // 1 = Monday, 5 = Friday

      repeat_every: "", // Every week
    },
    yearly: {
      month_of_year: "", // January

      day_of_month: "", // 15th day of the month

      repeat_every: "", // Every year
    },
    monthly: {
      repeat_every: "", // Every month
    },
    dayOfMonth: true,
  });

  const [editData, setEditData] = useState({
    schedule_type: null,
    quarterly: {
      month_of_quarter: "", // 1st month of the quarter

      day_of_month: "", // 15th day of the month

      repeat_every: "", // Every quarter
    },
    weekly: {
      days_of_week: [], // 1 = Monday, 5 = Friday

      repeat_every: "", // Every week
    },
    yearly: {
      month_of_year: "", // January

      day_of_month: "", // 15th day of the month

      repeat_every: "", // Every year
    },
    monthly: {
      repeat_every: "", // Every month
    },
    dayOfMonth: true,
  });

  const totals = useCalculateTotals(data);

  useUpdateFooterData(
    footerData,
    setFooterData,
    totals,
    [],
    company,
    customerDetail
  );

  const handleDoubleClick = (rowId, field) => {
    setEditable({ rowId, field });
  };

  const handleSelectCellChange = (selectedOption, id, rowKey, index) => {
    const updatedData = [...data];
    updatedData[index][id] = selectedOption;
    if (id === "tds_ledger") {
      setData(updatedData);
      // setItemId(index);
      const data = {
        tds_section_id: selectedOption?.value,
        item_id: rowKey,
      };

      dispatch(tdsCalculationApi(data));
    }
    setData(updatedData);
  };

  useEffect(() => {
    if (postLedgerError) {
      setApiError(postLedgerError);
    }
  }, [postLedgerError]);

  useEffect(() => {
    if (groupData?.length) {
      const fetchIncomeIds = groupData
        ?.filter((el) => el?.title === "Income" || el?.title === "Other Income")
        ?.map((el) => el?.id);
      setIncomeId(fetchIncomeIds);
    }
  }, [groupData]);

  useEffect(() => {
    if (postLedgerSuccess && postLedgerData && incomeId?.length) {
      dispatch({ type: POST_LEDGERLIST_RESET });
      if (incomeId?.includes(postLedgerData?.ledger_group)) {
        const updatedData = [...data];
        updatedData[ledgerIndexData]["ledger"] = postLedgerData?.id;
        setData(updatedData);
        setLedgerListData([...ledgerListData, postLedgerData]);
      }
      handleModalClose("#add_ledger");
      setAddLedgerData({ title: "", group: null, description: "" });
      setApiError({});
      setIsModalVisible(false);
      messageApi.open({
        type: "success",
        content: "Ledger Created Successfully",
        duration: 5,
      });
    }
  }, [postLedgerSuccess, ledgerIndexData, postLedgerData, incomeId]);

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
    setPreventApiCall(false);
    setIsModalVisible(false);
    setAddLedgerData({
      title: "",
      group: null,
      description: "",
    });
    setApiError({});
  };

  const closeModal = () => {
    setShow(false); // This will hide the modal
  };

  const handleCellChange = (rowId, field, value, index) => {
    const updatedData = [...data];
    updatedData[index][field] = value;
    setData(updatedData);
    setCalculationData((prevState) => ({
      ...prevState,
      indexId: index,
      key: field,
    }));
  };

  // useEffect(() => {
  //   onModalClose();
  //   onModalOpen();
  // }, []);

  useEffect(() => {
    dispatch(getCompanyList());
  }, []);

  const handleCheckBox = (e, selectedOption) => {
    const { checked } = e.target;
    if (checked) {
      const updatedData = { ...scheduleData };
      updatedData["weekly"] &&
        updatedData["weekly"]["days_of_week"]?.push(selectedOption);
      setScheduleData(updatedData);
      const edit_data = { ...editData };
      edit_data["weekly"] &&
        edit_data["weekly"]["days_of_week"]?.push(selectedOption);
      setEditData(edit_data);
    } else {
      setScheduleData((prevState) => ({
        ...prevState,
        weekly: {
          ...scheduleData?.weekly,
          days_of_week: scheduleData?.weekly?.days_of_week?.filter(
            (el) => el !== selectedOption
          ),
        },
      }));
      setEditData((prevState) => ({
        ...prevState,
        weekly: {
          ...editData?.weekly,
          days_of_week: editData?.weekly?.days_of_week?.filter(
            (el) => el !== selectedOption
          ),
        },
      }));
    }
  };

  const handleRepeatSelectChange = (selectedOption, scheduleType, id) => {
    setScheduleData((prevState) => ({
      ...prevState,
      [scheduleType]: { ...scheduleData[scheduleType], [id]: selectedOption },
    }));
    setEditData((prevState) => ({
      ...prevState,
      [scheduleType]: {
        ...editData[scheduleType],
        [id]: selectedOption?.value,
      },
    }));
  };

  useEffect(() => {
    return () => {
      dispatch({ type: RECURRINGINVOICE_CREATE_RESET });
    };
  }, [dispatch]);

  useEffect(() => {
    if (error?.invoice) {
      setShowErrors(error.invoice.items ? true : false);
      setItemsError(error.invoice.items ? error.invoice.items : null);
    } else {
      setAddError(error?.error ? error?.error : {});
    }
  }, [error]);

  const addLedgerSubmit = (event) => {
    event.preventDefault();
    if (isModalVisible) {
      const postLedgerData = {
        title: addLedgerData?.title,
        ledger_group: addLedgerData?.group ? addLedgerData?.group?.value : null,
        description: addLedgerData?.description,
      };
      dispatch(postLedgerModuleList(postLedgerData));
    }
  };

  const handleAddSubmit = (event) => {
    event.preventDefault();
    const postData = {
      pan_number: addCustomer?.pan_number ? addCustomer?.pan_number : null,
      gstin_number: addCustomer?.gstin_number
        ? addCustomer?.gstin_number
        : null,
      name: addCustomer?.name ? addCustomer?.name : "",
      address: addCustomer?.address ? addCustomer?.address : "",
      zip_code: addCustomer?.zip_code ? addCustomer?.zip_code : "",
      state: addCustomer?.state ? addCustomer?.state : "",
      phone: addCustomer?.phone ? addCustomer?.phone : "",
      contact_name: addCustomer?.contact_name ? addCustomer?.contact_name : "",
      email: addCustomer?.email ? addCustomer?.email : "",
      location: addCustomer?.location,
      customer_code: addCustomer?.customer_code
        ? addCustomer?.customer_code
        : null,
    };
    dispatch(customerCreation(postData));
    setIsDisable(false);
  };

  const recurringInvoiceSubmit = (postData) => {
    const ledger = data?.map((ele) => ({
      ...ele,
      ledger: ele?.ledger ? ele?.ledger : "",
      taxes: [],
    }));
    const payload = {
      invoice: {
        customer: postData?.customer,
        start_date: postData?.start_date
          ? formatDateYYMMDD(postData?.start_date)
          : "",
        end_date: postData?.end_date
          ? formatDateYYMMDD(postData?.end_date)
          : "",
        description: recurringInvoiceData?.description,
        items: ledger,
      },
      schedule: {
        schedule_type: scheduleData?.schedule_type?.value,
      },
    };

    if (editData?.schedule_type) {
      if (editData?.schedule_type === "monthly") {
        if (editData?.dayOfMonth && payload["schedule"]) {
          payload["schedule"]["monthly"] = {
            day_of_month: editData["monthly"]["day_of_month"],
            repeat_every: editData["monthly"]["repeat_every"],
          };
        } else if (editData?.nthWeekday && payload["schedule"]) {
          payload["schedule"]["monthly"] = {
            nth_day_of_week: editData["monthly"]["nth_day_of_week"],
            repeat_every: editData["monthly"]["repeat_every"],
            day_of_week: editData["monthly"]["day_of_week"],
          };
        }
      } else {
        payload["schedule"][editData?.schedule_type] =
          editData[editData?.schedule_type];
      }
    }

    dispatch(recurringInvoiceCreate(payload));
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setRecurringInvoiceData((prevState) => ({ ...prevState, [id]: value }));
    setAddError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleSelectChange = (id, selectedOption) => {
    setAddError((prevState) => ({ ...prevState, [id]: "" }));

    setRecurringInvoiceData((prevState) => ({
      ...prevState,
      [id]: selectedOption,
    }));
  };

  const handleDateChange = (id, date) => {
    setRecurringInvoiceData((prevState) => ({ ...prevState, [id]: date }));
    setAddError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleItemChange = (e, itemIndex) => {
    const { id, value } = e.target;
    const filterData = [...ledgerData];
    if (filterData[itemIndex]) {
      filterData[itemIndex][id] = value;
    }
    const newErrors = [...itemsError];
    if (newErrors[itemIndex]) {
      newErrors[itemIndex][id] = "";
    }
    setItemsError(newErrors);
    setLedgerData(filterData);
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        {contextHolder}
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        {/* <!-- Page Wrapper --> */}
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="card mb-0">
              <div className="card-body">
                <div className="page-header">
                  <div className="content-page-header">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a>AR module</a>
                      </li>
                      <li className="breadcrumb-item active">
                        <Link to="/recurring-invoices">
                          Recurring Invoice List
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Schedule Recurring invoice
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row">
                  <AddEditRecurringModal
                    id={"addrecurring_invoice"}
                    handleSubmit={handleSubmit}
                    recurringInvoiceSubmit={recurringInvoiceSubmit}
                    control={control}
                    handleChange={handleChange}
                    errors={errors}
                    showErrors={showErrors}
                    setShowErrors={setShowErrors}
                    itemsError={itemsError}
                    addError={addError}
                    handleDateChange={handleDateChange}
                    datePickerRef={datePickerRef}
                    recurringInvoiceData={recurringInvoiceData}
                    setRecurringInvoiceData={setRecurringInvoiceData}
                    error={error}
                    selectRefs={selectRefs}
                    customer={customer}
                    handleSelectChange={handleSelectChange}
                    customerDetail={customerDetail}
                    scheduleData={scheduleData}
                    setScheduleData={setScheduleData}
                    // day={day}
                    handleCheckBox={handleCheckBox}
                    handleItemChange={handleItemChange}
                    ledgerData={ledgerData}
                    setLedgerData={setLedgerData}
                    handleModalClose={handleModalClose}
                    loading={loading}
                    handleRepeatSelectChange={handleRepeatSelectChange}
                    setEditData={setEditData}
                    editable={editable}
                    setEditable={setEditable}
                    expenseList={expenseList}
                    ledgerListLoading={ledgerListLoading}
                    data={data}
                    setData={setData}
                    handleCellChange={handleCellChange}
                    handleSelectCellChange={handleSelectCellChange}
                    handleDoubleClick={handleDoubleClick}
                    footerData={footerData}
                    setFooterData={setFooterData}
                    // editModal={editModal}
                    setAddLedgerData={setAddLedgerData}
                    addLedgerData={addLedgerData}
                    apiError={apiError}
                    setApiError={setApiError}
                    setIsModalVisible={setIsModalVisible}
                    isModalVisible={isModalVisible}
                    postLedgerLoading={postLedgerLoading}
                    addLedgerSubmit={addLedgerSubmit}
                    moduleName="arModule"
                    handleLedgerPopupScroll={handleLedgerPopupScroll}
                    ledgerListData={ledgerListData}
                    setLedgerIndexData={setLedgerIndexData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomerModal
          setAddCustomer={setAddCustomer}
          addCustomer={addCustomer}
          setCustomerApiError={setCustomerApiError}
          customerApiError={customerApiError}
          handleModalClose={handleModalClose}
          handleEditSubmit={handleAddSubmit}
          loading={createCustomerLoading}
          isDisable={isDisable}
          setIsDisable={setIsDisable}
          setIsModalVisible={setIsModalVisible}
          isModalVisible={isModalVisible}
        />
      </div>
    </>
  );
};

export default AddRecurring;
