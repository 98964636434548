/* Pricing Plans Component */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { HTTP } from '../redux/api';
import { message as antdMessage, Spin } from 'antd';


const PricingPlans = ({ planData, loading, onPlanUpdate }) => {
  const [period, setPeriod] = useState('monthly');
  const [messageApi, contextHolder] = antdMessage.useMessage();
  const [loadingUpdate, setLoadingUpdate] = useState(false)



  const styles = {
    container: {
      padding: '3rem 0',
      maxWidth: '1200px',
      margin: '0 auto',
      background: '#f8f9ff',
      borderRadius: '16px',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '3rem',
      padding: '0 2rem',
    },
    title: {
      fontSize: '2.5rem',
      color: '#1a1a3f',
      margin: '0',
      fontWeight: '600',
    },
    periodToggle: {
      display: 'flex',
      gap: '0.5rem',
      background: '#ffffff',
      padding: '0.4rem',
      borderRadius: '12px',
      boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
      border: '1px solid #e5e7eb',
    },
    periodButton: {
      padding: '0.75rem 1.75rem',
      border: 'none',
      borderRadius: '8px',
      cursor: 'pointer',
      background: 'none',
      transition: 'all 0.3s ease',
      fontSize: '0.9rem',
      fontWeight: '500',
    },
    periodButtonActive: {
      background: '#6366f1',
      color: 'white',
      boxShadow: '0 2px 4px rgba(99,102,241,0.2)',
    },
    plansGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
      gap: '2rem',
      padding: '0 2rem',
    },
    planCard: {
      background: 'white',
      border: '1px solid #e5e7eb',
      borderRadius: '16px',
      padding: '2rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '1.5rem',
      transition: 'all 0.3s ease',
      boxShadow: '0 4px 6px rgba(0,0,0,0.02)',
      position: 'relative',
      overflow: 'hidden',
    },
    planCardCurrent: {
      border: '2px solid #6366f1',
      background: '#fafaff',
      boxShadow: '0 8px 16px rgba(99,102,241,0.12)',
    },
    currentLabel: {
      position: 'absolute',
      top: '1rem',
      right: '-2rem',
      background: '#6366f1',
      color: 'white',
      padding: '0.25rem 2rem',
      transform: 'rotate(45deg)',
      fontSize: '0.8rem',
      fontWeight: '500',
    },
    planName: {
      fontSize: '1.75rem',
      fontWeight: 'bold',
      color: '#1a1a3f',
      margin: '0',
    },
    planDescription: {
      color: '#6b7280',
      fontSize: '1rem',
      margin: '0',
      lineHeight: '1.5',
    },
    priceContainer: {
      marginTop: '1rem',
    },
    planPrice: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
      color: '#1a1a3f',
      display: 'flex',
      alignItems: 'flex-start',
      gap: '0.25rem',
    },
    currencySymbol: {
      fontSize: '1.5rem',
      fontWeight: '500',
      marginTop: '0.5rem',
    },
    planPeriod: {
      color: '#6b7280',
      fontSize: '1rem',
      marginLeft: '0.5rem',
      fontWeight: 'normal',
    },
    button: {
      padding: '1rem',
      borderRadius: '12px',
      border: 'none',
      cursor: 'pointer',
      fontWeight: '600',
      fontSize: '1rem',
      transition: 'all 0.3s ease',
      marginTop: 'auto',
    },
    upgradeButton: {
      background: '#6366f1',
      color: 'white',
      '&:hover': {
        background: '#4f46e5',
        transform: 'translateY(-2px)',
      },
    },
    downgradeButton: {
      background: 'white',
      border: '2px solid #6366f1',
      color: '#6366f1',
      '&:hover': {
        background: '#f5f5ff',
        transform: 'translateY(-2px)',
      },
    },
    disabledButton: {
      background: '#e5e7eb',
      color: '#9ca3af',
      cursor: 'not-allowed',
      border: 'none',
    },
    featuresList: {
      margin: '0',
      padding: '0',
      listStyle: 'none',
      display: 'flex',
      flexDirection: 'column',
      gap: '0.75rem',
    },
    featureItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      color: '#4b5563',
      fontSize: '0.95rem',
    },
  };

  const handlePlanUpdate = async (planId) => {
    setLoadingUpdate(planId); // Set the loading state to the clicked plan ID
    try {
      const response = await HTTP.patch('/payment/subscription-update/', {
        plan: planId,
      });
  
      // Handle successful update
      onPlanUpdate && onPlanUpdate();
      messageApi.open({
        type: "success",
        content: response?.response?.data?.message || 'Success',
        duration: 8,
      });
      setLoadingUpdate(false); // Clear loading state
    } catch (error) {
      console.error('Error updating plan:', error.response.data.message);
      // Handle error appropriately
      messageApi.open({
        type: "error",
        content: error?.response?.data?.message || 'Something went wrong',
        duration: 8,
      });
      setLoadingUpdate(false); // Clear loading state
    }
  };

  const formatPrice = (priceString) => {
    const [currency, amount] = priceString.split(' ');
    return {
      currency,
      amount: parseFloat(amount).toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    };
  };

  const filteredPlans = planData?.filter(plan => 
    plan.period.toLowerCase() === period.toLowerCase()
  ) || [];

  if (loading) {
    return (
    <div style={styles.container}>Loading plans...<Spin/> </div>
    )
  }


  return (
    <div style={styles.container}>
      {contextHolder}
    <div style={styles.header}>
      <h2 style={styles.title}>Choose your plan</h2>
      <div style={styles.periodToggle}>
        <button 
          style={{
            ...styles.periodButton,
            ...(period === 'monthly' ? styles.periodButtonActive : {})
          }}
          onClick={() => setPeriod('monthly')}
        >
          MONTHLY
        </button>
        <button 
          style={{
            ...styles.periodButton,
            ...(period === 'yearly' ? styles.periodButtonActive : {})
          }}
          onClick={() => setPeriod('yearly')}
        >
          ANNUALLY
        </button>
      </div>
    </div>

    <div style={styles.plansGrid}>
      {filteredPlans.map((plan) => {
        const isCurrentPlan = plan.cta === 'Current Plan';
        const isUpgrade = plan.cta === 'Upgrade';
        const isDowngrade = plan.cta === 'Downgrade';
        const { currency, amount } = formatPrice(plan.price);

        return (
          <div 
            key={plan.plan_id}
            style={{
              ...styles.planCard,
              ...(isCurrentPlan ? styles.planCardCurrent : {})
            }}
          >
            {isCurrentPlan && (
              <div style={styles.currentLabel}>Current Plan</div>
            )}
            
            <div>
              <h3 style={styles.planName}>{plan.name}</h3>
              <p style={styles.planDescription}>
                {plan.description || 'Professional plan with advanced features'}
              </p>
            </div>

            <div style={styles.priceContainer}>
              <div style={styles.planPrice}>
                <span style={styles.currencySymbol}>{currency}</span>
                {amount}
                <span style={styles.planPeriod}>/{plan.period}</span>
              </div>
            </div>
            
            <button
              style={{
                ...styles.button,
                ...(isCurrentPlan ? styles.disabledButton : {}),
                ...(isUpgrade ? styles.upgradeButton : {}),
                ...(isDowngrade ? styles.downgradeButton : {})
              }}
              onClick={() => handlePlanUpdate(plan.id)}
              disabled={isCurrentPlan}
            >
             {loadingUpdate === plan.id ? <Spin /> : plan.cta}
            </button>
          </div>
        );
      })}
    </div>
  </div>
  );
};

export default PricingPlans;

PricingPlans.propTypes = {
    planData : PropTypes.array.isRequired,
    loading : PropTypes.string,
   
    onPlanUpdate : PropTypes.func,
  
  };
  