export const GET_LEDGERLIST_REQUSET = "GET_LEDGERLIST_REQUSET";
export const GET_LEDGERLIST_SUCCESS = "GET_LEDGERLIST_SUCCESS";
export const GET_LEDGERLIST_FAIL = "GET_LEDGERLIST_FAIL";

export const GET_LEDGERLIST_BYGROUP_REQUSET = "GET_LEDGERLIST_BYGROUP_REQUSET";
export const GET_LEDGERLIST_BYGROUP_SUCCESS = "GET_LEDGERLIST_BYGROUP_SUCCESS";
export const GET_LEDGERLIST_BYGROUP_FAIL = "GET_LEDGERLIST_BYGROUP_FAIL";

export const LEDGERLISTSET_PAGE = "LEDGERLISTSET_PAGE";
export const LEDGERLIST_INVOICE_RESET = "LEDGERLIST_INVOICE_RESET";
export const POST_LEDGERLIST_REQUSET = "POST_LEDGERLIST_REQUSET";
export const POST_LEDGERLIST_SUCCESS = "POST_LEDGERLIST_SUCCESS";
export const POST_LEDGERLIST_FAIL = "POST_LEDGERLIST_FAIL";
export const POST_LEDGERLIST_RESET = "POST_LEDGERLIST_RESET";
export const UPDATE_LEDGERLIST_REQUSET = "UPDATE_LEDGERLIST_REQUSET";
export const UPDATE_LEDGERLIST_SUCCESS = "UPDATE_LEDGERLIST_SUCCESS";
export const UPDATE_LEDGERLIST_FAIL = "UPDATE_LEDGERLIST_FAIL";
export const UPDATE_LEDGERLIST_RESET = "UPDATE_LEDGERLIST_RESET";

export const GROUP_CREATE_REQUEST = "GROUP_CREATE_REQUEST";
export const GROUP_CREATE_SUCCESS = "GROUP_CREATE_SUCCESS";
export const GROUP_CREATE_FAIL = "GROUP_CREATE_FAIL";
export const GROUP_CREATE_RESET = "GROUP_CREATE_RESET";

export const GROUP_UPDATE_REQUEST = "GROUP_UPDATE_REQUEST";
export const GROUP_UPDATE_SUCCESS = "GROUP_UPDATE_SUCCESS";
export const GROUP_UPDATE_FAIL = "GROUP_UPDATE_FAIL";
export const GROUP_UPDATE_RESET = "GROUP_UPDATE_RESET";

export const GROUP_DETAIL_REQUEST = "GROUP_DETAIL_REQUEST";
export const GROUP_DETAIL_SUCCESS = "GROUP_DETAIL_SUCCESS";
export const GROUP_DETAIL_FAIL = "GROUP_DETAIL_FAIL";
export const GROUP_DETAIL_RESET = "GROUP_DETAIL_RESET";

export const GET_GROUPLIST_REQUSET = "GET_GROUPLIST_REQUSET";
export const GET_GROUPLIST_SUCCESS = "GET_GROUPLIST_SUCCESS";
export const GET_GROUPLIST_FAIL = "GET_GROUPLIST_FAIL";

export const GET_LEDGER_LIST_REQUSET = "GET_LEDGER_LIST_REQUSET";
export const GET_LEDGER_LIST_SUCCESS = "GET_LEDGER_LIST_SUCCESS";
export const GET_LEDGER_LIST_FAIL = "GET_LEDGER_LIST_FAIL";

export const GET_LEDGER_GROUP_REQUSET = "GET_LEDGER_GROUP_REQUSET";
export const GET_LEDGER_GROUP_SUCCESS = "GET_LEDGER_GROUP_SUCCESS";
export const GET_LEDGER_GROUP_FAIL = "GET_LEDGER_GROUP_FAIL";

export const LEDGER_DETAIL_REQUEST = "LEDGER_DETAIL_REQUEST";
export const LEDGER_DETAIL_SUCCESS = "LEDGER_DETAIL_SUCCESS";
export const LEDGER_DETAIL_FAIL = "LEDGER_DETAIL_FAIL";
export const LEDGER_DETAIL_RESET = "LEDGER_DETAIL_RESET";
