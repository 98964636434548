/* eslint-disable */
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import {
  clearButtonHoverStyle,
  clearButtonStyle,
  containerStyle,
} from "../sales/invoices/constant";
import DateRangePickerComponent from "./dateRange";
import { RefreshCw } from "lucide-react";

const APModuleHeader = ({
  filterData,
  setFilterData,
  userListData,
  handleSelectFilterChange,
  handleDateChange,
  permissions,
  isHovered,
  setIsHovered,
  handleMouseEnter,
  handleMouseLeave,
  setRefresh,
  refresh,
  handleDateFilterChange,
}) => {
  const statusOptions = [
    { id: "", text: "All" },
    { id: "Pending", text: "Pending" },
    { id: "ready to post", text: "Ready to Post" },
    { id: "Rejected", text: "Rejected" },
  ];

  const approvalStatusOptions = [
    { id: "", text: "All" },
    { id: "pending approval", text: "Pending Approval" },
    { id: "user approved", text: "User Approved" },
    { id: "rejected approval", text: "Rejected Approval" },
  ];

  return (
    <>
      <div className="content-page-header">
        <div className="col">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a>AP module</a>
            </li>
            <li className="breadcrumb-item active">Invoice List</li>
          </ul>
        </div>
        {permissions?.apModule?.submitInvoice && (
          <div className="list-btn">
            <ul className="filter-list filterbutton apmoduleHeader">
              <li>
                <Link
                  className="btn btn-primary  me-2 "
                  to="/ap-module/add-invoice/"
                  role="button"
                >
                  <i className="fa fa-plus-circle me-2" aria-hidden="true" />
                  Create Invoice
                </Link>
              </li>
              &nbsp;
              <li>
                <Link
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  to="#upload_invoice"
                  role="button"
                >
                  <i className="fa fa-upload me-2" aria-hidden="true" />
                  Upload Invoice
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
      <div className="list-btn">
        <ul className="filter-list" style={{ position: "relative" }}>
          <li>
            <div
              className="customDateRangePicker"
              style={containerStyle}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <DateRangePickerComponent
                startDate={filterData?.startDate?.startDate || null}
                endDate={filterData?.startDate?.endDate || null}
                handleDateChange={(start, end) =>
                  handleDateChange({ startDate: start, endDate: end })
                }
              />
              {(filterData?.startDate || filterData?.endDate) && (
                <button
                  type="button"
                  // style={{
                  //   position: "absolute",
                  //   right: "10px", // Position the button inside the input field
                  //   top: "50%", // Center vertically
                  //   transform: "translateY(-50%)",
                  //   background: "none", // No background
                  //   border: "none", // No border
                  //   cursor: "pointer", // Pointer cursor on hover
                  //   color: "#999", // Adjust the icon color
                  // }}
                  onClick={() => handleDateChange(null, null)} // Clear the date
                  aria-label="Clear Date"
                >
                  <i className="fas fa-times" />
                </button>
              )}
            </div>
          </li>
          <li>
            <div
              style={containerStyle}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <DatePicker
                className="datetimepicker form-control"
                id="invoice_date"
                selected={filterData?.invoice_date}
                placeholderText="Invoice Date"
                onChange={(selectedDate) =>
                  handleDateFilterChange(selectedDate, "invoice_date")
                }
              />
              {filterData?.invoice_date && (
                <button
                  type="button"
                  style={{
                    ...clearButtonStyle,
                    ...(isHovered ? clearButtonHoverStyle : {}),
                  }}
                  onClick={() => handleDateFilterChange(null, "invoice_date")}
                  aria-label="Clear Date"
                >
                  <i className="fas fa-times" />
                </button>
              )}
            </div>
          </li>
          <li className="customDrpdwn">
            <Select
              options={[
                { value: "", label: "All" },
                ...((userListData?.results?.length &&
                  userListData?.results?.map((option) => ({
                    value: option.id,
                    label: option.username,
                  }))) ||
                  []),
              ]}
              placeholder="Select User"
              value={filterData?.user}
              onChange={(selectedOption) =>
                handleSelectFilterChange(selectedOption, "user")
              }
            />
          </li>

          <li className="customDrpdwn">
            <Select
              options={statusOptions.map((option) => ({
                value: option.id,
                label: option.text,
              }))}
              value={filterData?.status}
              onChange={(selectedOption) =>
                handleSelectFilterChange(selectedOption, "status")
              }
              placeholder="Select Status"
            />
          </li>

          <li className="customDrpdwn">
            <Select
              options={approvalStatusOptions.map((option) => ({
                value: option.id,
                label: option.text,
              }))}
              value={filterData?.approval_status}
              onChange={(selectedOption) =>
                handleSelectFilterChange(selectedOption, "approval_status")
              }
              placeholder="Select Approval Status"
            />
          </li>
          <li
            className="refresh-icon"
            style={{
              position: "absolute",
              top: "5px",
              right: "10px",
              color: "#7539FF",
            }}
          >
            <RefreshCw
              style={{ cursor: "pointer" }}
              onClick={() => setRefresh(!refresh)}
            />
          </li>
        </ul>
      </div>
    </>
  );
};

APModuleHeader.propTypes = {
  filterData: PropTypes.object.isRequired,
  setFilterData: PropTypes.func.isRequired,
  userListData: PropTypes.object,
  handleSelectFilterChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
};

export default APModuleHeader;
