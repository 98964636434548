/* eslint-disable */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import "../../_components/antd.css";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { Spin, message } from "antd";
import { useDispatch } from "react-redux";
import {
  getCompanyList,
  getCustomerList,
} from "../../redux/actions/commonApiAction";
import { INVOICE_EDIT_RESET } from "../../redux/constants/ARmoduleConstant";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  getInvoiceEditDetail,
  updateInvoice,
} from "../../redux/actions/ARmoduleAction";
import { formatDateYYMMDD } from "./constant";
import AddEditInvoiceModal from "./addEditInvoiceModal";
import {
  getGroupList,
  getLedgerModuleList,
  postLedgerModuleList,
} from "../../redux/actions/LedgerModuleAction";
import { POST_LEDGERLIST_RESET } from "../../redux/constants/ledgerListModuleConstant";

import useUpdateFooterData from "../../customers/updateFooterData";
import useCalculateTotals from "../../helpers/calculation";
import CustomerModal from "./recurring/customerModal";
import { CUSTOMER_ADD_RESET } from "../../redux/constants/CustomerModuleCnstant";
import { customerCreation } from "../../redux/actions/CustomerModuleAction";

const schema = yup.object({
  customer: yup
    .number()
    .required("Customer is required")
    .typeError("Customer must be a number"),
  nature_of_supply: yup
    .string()
    .required("Nature of supply is required")
    .trim(),
  invoice_date: yup
    .date()
    .required("Invoice date is required")
    .nullable()
    .required("Invoice date is required"),
});

const EditInvoice = () => {
  const [menu, setMenu] = useState(false);
  const [invoiceEditData, setInvoiceEditData] = useState({});
  const [ledgerData, setLedgerData] = useState([]);
  const [itemsError, setItemsError] = useState(null);
  const [editError, setEditError] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const [file, setFile] = useState(null);
  const [customerDetail, setCustomerDetail] = useState({});
  const [footerData, setFooterData] = useState({});
  const [data, setData] = useState([]);
  const [ledgerListData, setLedgerListData] = useState([]);
  const [ledgerIndexData, setLedgerIndexData] = useState("");
  const [ledgerPage, setLedgerPage] = useState({ page: 1, perPage: 10 });
  const [incomeId, setIncomeId] = useState([]);
  const [editable, setEditable] = useState({});
  const [calculationData, setCalculationData] = useState({});
  const [gstSection, setGstSection] = useState([]);
  const dispatch = useDispatch();
  const companyList = useSelector((state) => state?.companyList);
  const { company } = companyList;
  const editInvoiceDetail = useSelector((state) => state.editInvoiceDetail);
  const { invoiceEditDetail, loading: editInvoiceLoading } = editInvoiceDetail;
  const editInvoice = useSelector((state) => state.editInvoice);
  const { success, loading, error } = editInvoice;

  const [messageApi, contextHolder] = message.useMessage();
  const customerList = useSelector((state) => state?.customerList);
  const { customer } = customerList;

  const ledgerList = useSelector((state) => state?.ledgerList);
  const { loading: ledgerListLoading, data: expenseList } = ledgerList;

  const [addLedgerData, setAddLedgerData] = useState({
    title: "",
    group: null,
    description: "",
  });
  const [apiError, setApiError] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showErrors, setShowErrors] = useState(true);

  //customer
  const [addCustomer, setAddCustomer] = useState({});
  const customerModuleList = useSelector((state) => state?.customerModuleList);
  const { page, perPage } = customerModuleList;
  const createCustomer = useSelector((state) => state?.createCustomer);
  const [customerApiError, setCustomerApiError] = useState({});
  const [isDisable, setIsDisable] = useState(false);
  const {
    loading: createCustomerLoading,
    error: createCustomerError,
    success: createCustomerSuccess,
    data: postCustomerData,
  } = createCustomer;

  const totals = useCalculateTotals(data);

  useUpdateFooterData(
    footerData,
    setFooterData,
    totals,
    gstSection,
    company,
    customerDetail
  );

  // const onModalOpen = () => {
  //   $("#add_ledger").on("shown.bs.modal", () => {
  //     console.log("Open");
  //     setIsModalVisible(true);
  //     setAddLedgerData({ title: "", type: "", description: "" });
  //     setApiError({});
  //   });
  // };

  // const onModalClose = () => {
  //   $("#add_ledger").on("hide.bs.modal", () => {
  //     console.log("Close");
  //     setIsModalVisible(false);
  //     setAddLedgerData({ title: "", type: "", description: "" });
  //   });
  // };

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
    setIsModalVisible(false);
    setAddLedgerData({
      title: "",
      group: null,
      description: "",
    });
    setApiError({});
  };

  const postLedgerList = useSelector((state) => state?.postLedgerList);
  const {
    loading: postLedgerLoading,
    error: postLedgerError,
    success: postLedgerSuccess,
    data: postLedgerData,
  } = postLedgerList;

  const groupList = useSelector((state) => state?.groupList);
  const { groupData } = groupList;

  useEffect(() => {
    if (postLedgerError) {
      setApiError(postLedgerError);
    }
  }, [postLedgerError]);

  useEffect(() => {
    if (groupData?.length) {
      const fetchIncomeIds = groupData
        ?.filter((el) => el?.title === "Income" || el?.title === "Other Income")
        ?.map((el) => el?.id);
      setIncomeId(fetchIncomeIds);
    }
  }, [groupData]);

  useEffect(() => {
    if (postLedgerSuccess && incomeId?.length) {
      dispatch({ type: POST_LEDGERLIST_RESET });
      if (incomeId?.includes(postLedgerData?.ledger_group)) {
        const updatedData = [...data];
        updatedData[ledgerIndexData]["ledger"] = postLedgerData?.id;
        setData(updatedData);
        setLedgerListData([...ledgerListData, postLedgerData]);
        setIsChanged(true);
      }
      handleModalClose("#add_ledger");
      setAddLedgerData({ title: "", group: null, description: "" });
      setApiError({});
      setIsModalVisible(false);
      messageApi.open({
        type: "success",
        content: "Ledger Created Successfully",
        duration: 5,
      });
    }
  }, [postLedgerSuccess, postLedgerData, ledgerIndexData, incomeId]);

  const datePickerRef = useRef(null);
  const selectRefs = useRef({});

  const { id } = useParams();

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    dispatch(getCustomerList());
    dispatch(getGroupList());
  }, []);

  useEffect(() => {
    if (invoiceEditData?.customer) {
      const customerDetails = customer?.find(
        (el) => el?.id === invoiceEditData?.customer
      );

      setCustomerDetail({
        customer_code: customerDetails?.customer_code,
        gstin_number: customerDetails?.gstin_number,
        location: customerDetails?.location,
      });
    }
  }, [customer, invoiceEditData?.customer]);

  const handleAddSubmit = (event) => {
    event.preventDefault();
    const postData = {
      pan_number: addCustomer?.pan_number ? addCustomer?.pan_number : null,
      gstin_number: addCustomer?.gstin_number
        ? addCustomer?.gstin_number
        : null,
      name: addCustomer?.name ? addCustomer?.name : "",
      address: addCustomer?.address ? addCustomer?.address : "",
      zip_code: addCustomer?.zip_code ? addCustomer?.zip_code : "",
      state: addCustomer?.state ? addCustomer?.state : "",
      phone: addCustomer?.phone ? addCustomer?.phone : "",
      contact_name: addCustomer?.contact_name ? addCustomer?.contact_name : "",
      email: addCustomer?.email ? addCustomer?.email : "",
      location: addCustomer?.location,
      customer_code: addCustomer?.customer_code
        ? addCustomer?.customer_code
        : null,
    };
    dispatch(customerCreation(postData));
    setIsDisable(false);
  };

  useEffect(() => {
    setCustomerApiError(createCustomerError);
  }, [createCustomerError]);

  useEffect(() => {
    if (createCustomerSuccess && postCustomerData) {
      dispatch({ type: CUSTOMER_ADD_RESET });
      dispatch(getCustomerList());
      // dispatch(getCustomerModuleList(page, perPage));
      setAddCustomer({
        pan_number: "",
        gstin_number: "",
        name: "",
        zoho_contact_id: null,
        customer_code: "",
        address: "",
        zip_code: "",
        state: "",
        phone: "",
        contact_name: "",
        email: "",
        zoho_address_id: null,
        location: "",
      });
      setInvoiceEditData((prevState) => ({
        ...prevState,
        customer: postCustomerData ? postCustomerData?.id : null,
      }));
      setIsChanged(true);
      handleModalClose("#customer_modal");
      messageApi.open({
        type: "success",
        content: "customer created successfully",
        duration: 5,
      });
    }
  }, [createCustomerSuccess, postCustomerData]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setInvoiceEditData((prevState) => ({ ...prevState, [id]: value }));
    setIsChanged(true);
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleCellChange = (rowId, field, value, index) => {
    const updatedData = [...data];
    updatedData[index][field] = value;
    setData(updatedData);
    setCalculationData((prevState) => ({
      ...prevState,
      indexId: index,
      key: field,
    }));
    setIsChanged(true);
  };

  useEffect(() => {
    if (
      calculationData?.indexId !== "" &&
      calculationData?.key !== "gst_amount" &&
      calculationData?.key !== "amount" &&
      calculationData?.key !== "total"
    ) {
      const updatedData = [...data];
      if (updatedData[calculationData?.indexId]) {
        const rate =
          parseFloat(updatedData[calculationData?.indexId]["rate"]) || 0;
        const quantity =
          parseFloat(updatedData[calculationData?.indexId]["quantity"]) || 0;
        const gstRate =
          parseFloat(updatedData[calculationData?.indexId]["gst_rate"]) || 0;
        const amount = rate * quantity;

        updatedData[calculationData?.indexId]["amount"] = amount.toFixed(2);

        const gstAmount = (amount * gstRate) / 100;
        updatedData[calculationData?.indexId]["gst_amount"] = gstAmount.toFixed(
          2
        );

        const total = amount + gstAmount;
        updatedData[calculationData?.indexId]["total"] = total.toFixed(2);
      }
      setData(updatedData);
    }
  }, [calculationData]);

  const handleDoubleClick = (rowId, field) => {
    setEditable({ rowId, field });
  };

  const handleSelectCellChange = (selectedOption, id, rowKey, index) => {
    if (id === "tds_ledger") {
      setData((prevData) =>
        prevData.map((row, tableIndex) =>
          tableIndex === index ? { ...row, [id]: selectedOption } : row
        )
      );
      // setItemId(index);
      const data = {
        tds_section_id: selectedOption,
        item_id: rowKey,
      };

      dispatch(tdsCalculationApi(data));
    }
    setIsChanged(true);
    setData((prevData) =>
      prevData.map((row, tableIndex) =>
        tableIndex === index ? { ...row, [id]: selectedOption } : row
      )
    );
  };

  const handleCommonChange = (e) => {
    const { id, value } = e.target;
    setIsChanged(true);
    setInvoiceEditData((prevState) => ({ ...prevState, [id]: value }));
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleCommonDateChange = (id, date) => {
    setIsChanged(true);
    setInvoiceEditData((prevState) => ({ ...prevState, [id]: date }));
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleSelectChange = (id, selectedOption) => {
    setIsChanged(true);
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
    setInvoiceEditData((prevState) => ({ ...prevState, [id]: selectedOption }));
  };

  const handleDateChange = (id, date) => {
    setIsChanged(true);
    setInvoiceEditData((prevState) => ({ ...prevState, [id]: date }));
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleItemChange = (e, itemIndex) => {
    const { id, value } = e.target;
    setIsChanged(true);
    const filterData = [...ledgerData];
    filterData[itemIndex][id] = value;
    setLedgerData(filterData);
    const newErrors = [...itemsError];
    if (newErrors[itemIndex]) {
      newErrors[itemIndex][id] = "";
    }
    setItemsError(newErrors);
  };

  const handleAddClick = (event) => {
    event.preventDefault();
    setData([
      ...data,
      {
        ledger: null,
        description: "",
        quantity: "",
        rate: "",
        amount: "",
        gst_rate: "",
        gst_amount: "",
        tds_ledger: null,
        tds_amount: "",
      },
    ]);
    // setAddLedgerData({ title: "", type: "", description: "" });
  };

  const handleRemove = (event, tabIndex) => {
    event.preventDefault();
    const filterData = data?.filter((el, index) => index !== tabIndex);
    setIsChanged(true);
    setData(filterData);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setIsChanged(true);
    setEditError((prevState) => ({ ...prevState, photo: "" }));
  };

  const parseDate = (dateString) => {
    return dateString ? new Date(dateString) : null;
  };

  const initialValues = {
    invoice_number: invoiceEditData?.invoice_number,
    nature_of_supply: invoiceEditData?.nature_of_supply,
    invoice_date: invoiceEditData?.invoice_date,
    company: invoiceEditData?.company,
  };

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const editLedgerSubmit = (event) => {
    event.preventDefault();
    if (isModalVisible) {
      const postData = {
        title: addLedgerData?.title,
        ledger_group: addLedgerData?.group ? addLedgerData?.group?.value : null,
        description: addLedgerData?.description,
      };
      dispatch(postLedgerModuleList(postData));
    }
  };

  const handleEditInvoice = (postData) => {
    const ledger = data?.map((ele) => ({
      ...ele,
      ledger: ele?.ledger,
    }));

    const payload = {
      invoice_date: postData?.invoice_date
        ? formatDateYYMMDD(postData?.invoice_date)
        : "",
      customer: postData?.customer,
      nature_of_supply: postData?.nature_of_supply,
      items: ledger,
    };
    dispatch(updateInvoice(payload, id));
    setIsChanged(false);
  };

  useEffect(() => {
    if (error?.items) {
      setShowErrors(error?.items ? true : false);
      setItemsError(error?.items ? error?.items : null);
    } else {
      setEditError(error?.error ? error?.error : {});
    }
  }, [error]);

  useEffect(() => {
    const fieldOrder = [
      "invoice_number",
      "nature_of_supply",
      "invoice_date",
      "company",
    ];
    const firstErrorField = fieldOrder.find((field) => errors[field]);
    if (firstErrorField) {
      let inputElement;
      if (firstErrorField === "company") {
        inputElement = selectRefs.current[firstErrorField];
      } else {
        inputElement = document.getElementById(firstErrorField);
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [errors]);

  useEffect(() => {
    return () => {
      dispatch({ type: INVOICE_EDIT_RESET });
    };
  }, [dispatch]);

  // useEffect(() => {
  //   onModalOpen();
  //   onModalClose();
  // }, []);

  useEffect(() => {
    const fieldOrder = [
      "invoice_number",
      "invoice_date",
      "remark",
      "payment_term",
      "nature_of_supply",
      "invoice_date",
      "company",
      "po_num",
      "po_date",
      "photo",
    ];
    const firstErrorField = fieldOrder.find((field) => error && error[field]);
    if (firstErrorField) {
      let inputElement;
      if (firstErrorField === "company") {
        inputElement = selectRefs.current[firstErrorField];
      } else {
        inputElement = document.getElementById(firstErrorField);
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [error]);

  useEffect(() => {
    setEditError(error);
  }, [error]);

  useEffect(() => {
    if (invoiceEditData) {
      Object.keys(invoiceEditData).forEach((field) => {
        setValue(field, invoiceEditData[field]);
      });
      if (invoiceEditData?.invoice_date) {
        clearErrors("invoice_date");
      }
    }
  }, [invoiceEditData, setValue]);

  useEffect(() => {
    if (success) {
      dispatch({ type: INVOICE_EDIT_RESET });
      dispatch(getInvoiceEditDetail(id));
      reset();
      messageApi.open({
        type: "success",
        content: "invoice updated successfully",
        duration: 5,
      });
    }
  }, [success]);

  useEffect(() => {
    dispatch(getInvoiceEditDetail(id));
  }, [id]);

  useEffect(() => {
    if (invoiceEditDetail) {
      setInvoiceEditData({
        ...invoiceEditDetail,
        invoice_date: parseDate(invoiceEditDetail?.invoice_date)
          ? parseDate(invoiceEditDetail?.invoice_date)
          : "",
        po_date: parseDate(invoiceEditDetail?.po_date),
        customer: invoiceEditDetail?.customer_id
          ? invoiceEditDetail?.customer_id
          : null,
      });
      setCustomerDetail({
        customer_code: invoiceEditDetail?.customer_code,
        gstin_number: invoiceEditDetail?.customer_gst,
        location: invoiceEditDetail?.customer_location,
      });
      setFile({
        ...file,
        name: invoiceEditDetail?.photo ? invoiceEditDetail?.photo : "",
      });
      setFooterData(invoiceEditDetail);
      setGstSection(
        invoiceEditDetail?.gst_section
          ? Object?.keys(invoiceEditDetail?.gst_section)
          : []
      );
      const ledger =
        invoiceEditDetail?.items?.length &&
        invoiceEditDetail?.items?.map((ele) => ({
          ...ele,
          ledger: ele?.ledger?.id,
        }));
      const postingLedgerData = invoiceEditDetail?.items?.map(
        (el) => el?.ledger
      );
      postingLedgerData?.forEach((el) => {
        // Check if the object is already in ledgerListData
        const exists = ledgerListData.some(
          (ledger) => JSON.stringify(ledger) === JSON.stringify(el)
        );

        // Only push if the object doesn't exist
        if (!exists) {
          ledgerListData.push(el);
        }
      });

      setData(
        invoiceEditDetail?.items?.length
          ? ledger
          : [
              {
                description: "",
                quantity: "",
                rate: "",
                total: "",
              },
            ]
      );
    }
  }, [invoiceEditDetail]);

  useEffect(() => {
    if (!customerDetail?.gstin_number && invoiceEditData?.customer) {
      const updatedData = data.map((item) => ({
        ...item,
        gst_rate: 0,
        gst_amount: 0,
      }));
      setData(updatedData);
      setFooterData((prevState) => ({
        ...prevState,
        gst_section: {},
        total_tax: 0,
      }));
    }
  }, [customerDetail?.gstin_number, invoiceEditData?.customer]);

  // useEffect(() => {
  //   if (customer?.length) {
  //     const filteredCustomerList = customer?.filter(
  //       (el) => el?.name === invoiceEditDetail?.customer
  //     );
  //     setInvoiceEditData((prevState) => ({
  //       ...prevState,
  //       customer:
  //         filteredCustomerList?.length !== 0 && filteredCustomerList
  //           ? {
  //               label: filteredCustomerList[0]?.name,
  //               value: filteredCustomerList[0]?.id,
  //             }
  //           : "",
  //     }));
  //   }
  // }, [invoiceEditDetail, customer]);

  useEffect(() => {
    if ((ledgerPage?.page > 1 && expenseList?.next) || ledgerPage?.page === 1) {
      dispatch(
        getLedgerModuleList("", ledgerPage?.page, ledgerPage?.perPage, true)
      );
    }
  }, [ledgerPage?.page, ledgerPage?.perPage]);

  useEffect(() => {
    setLedgerListData([]);
    return () => {
      setLedgerListData([]);
    };
  }, []);

  useEffect(() => {
    if (expenseList?.results) {
      const newVendors = expenseList?.results;

      // Create a Set of the current vendor ids/names for quick look-up
      const existingVendorIds = new Set(
        ledgerListData?.map((vendor) => vendor?.id)
      ); // Assuming `id` is the unique identifier

      // Filter newVendors to only include those that are not already in vendorList
      const uniqueNewVendors = newVendors?.filter(
        (vendor) => !existingVendorIds?.has(vendor?.id)
      );

      // Combine the previous vendorList with the unique new ones
      const combinedVendorList = [...ledgerListData, ...uniqueNewVendors];
      setLedgerListData(combinedVendorList); // Update state with the combined list
    }
  }, [expenseList]);

  const handleLedgerPopupScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollHeight - scrollTop <= clientHeight + 10 &&
      !ledgerListLoading &&
      expenseList?.results?.length
    ) {
      setLedgerPage((prevState) => ({
        ...prevState,
        page: prevState?.page + 1,
        perPage: prevState?.perPage,
      }));
    }
  };

  useEffect(() => {
    dispatch(getCompanyList());
  }, []);

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        {contextHolder}
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        {/* <!-- Page Wrapper --> */}
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="card mb-0">
              <div className="card-body">
                <div className="page-header">
                  <div className="content-page-header">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a>AR module</a>
                      </li>
                      <li className="breadcrumb-item active">
                        <Link to="/invoice-list">Invoice List</Link>
                      </li>
                      <li className="breadcrumb-item active">Edit Invoice</li>
                    </ul>
                  </div>
                </div>
                {editInvoiceLoading ? (
                  <Spin />
                ) : (
                  <div className="row">
                    <AddEditInvoiceModal
                      id={""}
                      handleSubmit={handleSubmit}
                      addInvoiceSubmit={handleEditInvoice}
                      control={control}
                      handleChange={handleChange}
                      errors={errors}
                      error={error}
                      showErrors={showErrors}
                      setShowErrors={setShowErrors}
                      addError={editError}
                      handleDateChange={handleDateChange}
                      invoiceData={invoiceEditData}
                      handleCommonChange={handleCommonChange}
                      handleCommonDateChange={handleCommonDateChange}
                      datePickerRef={datePickerRef}
                      selectRefs={selectRefs}
                      customer={customer}
                      handleSelectChange={handleSelectChange}
                      customerDetail={customerDetail}
                      handleFileChange={handleFileChange}
                      file={file}
                      ledgerData={ledgerData}
                      handleItemChange={handleItemChange}
                      itemsError={itemsError}
                      handleAddClick={handleAddClick}
                      handleRemove={handleRemove}
                      loading={loading}
                      isChanged={isChanged}
                      data={data}
                      setData={setData}
                      footerData={footerData}
                      setFooterData={setFooterData}
                      setEditable={setEditable}
                      editable={editable}
                      handleDoubleClick={handleDoubleClick}
                      handleSelectCellChange={handleSelectCellChange}
                      handleCellChange={handleCellChange}
                      editModal={true}
                      expenseList={expenseList}
                      ledgerListLoading={ledgerListLoading}
                      handleModalClose={handleModalClose}
                      setAddLedgerData={setAddLedgerData}
                      addLedgerData={addLedgerData}
                      apiError={apiError}
                      setApiError={setApiError}
                      setIsModalVisible={setIsModalVisible}
                      isModalVisible={isModalVisible}
                      postLedgerLoading={postLedgerLoading}
                      addLedgerSubmit={editLedgerSubmit}
                      handleLedgerPopupScroll={handleLedgerPopupScroll}
                      ledgerListData={ledgerListData.filter(
                        (item) => item !== null
                      )}
                      setLedgerIndexData={setLedgerIndexData}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <CustomerModal
          setAddCustomer={setAddCustomer}
          addCustomer={addCustomer}
          setCustomerApiError={setCustomerApiError}
          customerApiError={customerApiError}
          handleModalClose={handleModalClose}
          handleEditSubmit={handleAddSubmit}
          loading={createCustomerLoading}
          isDisable={isDisable}
          setIsDisable={setIsDisable}
          setIsModalVisible={setIsModalVisible}
          isModalVisible={isModalVisible}
        />
      </div>
    </>
  );
};

export default EditInvoice;
