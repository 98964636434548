/* eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import "../../_components/antd.css";
import { message, Spin, Table } from "antd";
import {
  onShowSizeChange,
  itemRender,
} from "../../_components/paginationfunction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  addVendor,
  getVendorList,
  setPage,
} from "../../redux/actions/VendorAction";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { commonVendorFieldOrder, vendorFieldOrder } from ".";
import { VENDOR_ADD_RESET } from "../../redux/constants/VendorConstant";
import CreateVendorModal from "./createVendor";

const schema = yup.object({
  name: yup
    .string()
    .required("Please fill this field")
    .trim(),
  // state_code: yup
  //   .string()
  //   .required("Please fill this field")
  //   .trim(),
  // email: yup
  //   .string()
  //   .required("Please fill this field")
  //   .trim(),
  // state: yup
  //   .string()
  //   .required("Please fill this field")
  //   .trim(),
  // address: yup
  //   .string()
  //   .required("Please fill this field")
  //   .trim(),
  // vendor_code: yup
  //   .string()
  //   .required("Please fill this field")
  //   .trim(),
  // contact_name: yup
  //   .string()
  //   .required("Please fill this field")
  //   .trim(),
  // phone: yup
  //   .string()
  //   .required("Please fill this field")
  //   .trim(),
  // zip_code: yup
  //   .string()
  //   .required("Please fill this field")
  //   .trim(),
  // branch_name: yup
  //   .string()
  //   .required("Please fill this field")
  //   .trim(),
});

const VendorList = ({ activeTab }) => {
  const [addData, setAddData] = useState({
    pan_number: "",
    start_date: "",
    end_date: "",
    approved_amount: "",
    tds_certificate_number: "",
    tds_certificate: "",
    gstin_number: "",
    name: "",
    vendor_code: "",
    address: "",
    zip_code: "",
    state: "",
    // state_code: "",
    phone: "",
    contact_name: "",
    country_code: null,
    email: "",
    msme: "",
    bank_name: "",
    branch_name: "",
    account_number: "",
    ifsc_code: "",
    swift: "",
    ap_invoice_id: "",
  });
  const [apiError, setApiError] = useState({});
  const [show, setShow] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();
  const [file, setFile] = useState(null);
  const vendorList = useSelector((state) => state?.vendorList);
  const { loading, error, page, perPage, vendor } = vendorList;
  const addvendor = useSelector((state) => state.addvendor);
  const {
    success,
    loading: addVendorLoading,
    error: addVendorError,
  } = addvendor;

  const modalRef = useRef(null);

  const dispatch = useDispatch();

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      render: (text, record, index) => perPage * (page - 1) + index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Address",
      dataIndex: "address",
      sorter: (a, b) => a.address.length - b.address.length,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      sorter: (a, b) => a.phone.length - b.phone.length,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (text, record) => (
        <>
          <Link
            to={`/vendoredit/${record?.id}`}
            className="btn btn-primary me-2"
          >
            <i className="far fa-edit" />
          </Link>
        </>
      ),
    },
  ];

  const {
    handleSubmit,
    reset,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: addData,
  });

  useEffect(() => {
    const firstErrorField = vendorFieldOrder.find((field) => errors[field]);
    if (firstErrorField) {
      const inputElement = document.getElementById(`add-${firstErrorField}`);
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [errors]);

  useEffect(() => {
    const commonErrorField = commonVendorFieldOrder.find(
      (field) => apiError && apiError[field]
    );
    if (commonErrorField) {
      const commonInputElement = document.getElementById(
        `add-${commonErrorField}`
      );
      if (commonInputElement) {
        commonInputElement.focus();
      }
    }
  }, [apiError]);

  useEffect(() => {
    setApiError(addVendorError);
  }, [addVendorError]);

  const onModalClose = () => {
    $("#add_vendor").on("hide.bs.modal", () => {
      setAddData({
        pan_number: "",
        start_date: "",
        end_date: "",
        approved_amount: "",
        tds_certificate_number: "",
        tds_certificate: "",
        gstin_number: "",
        name: "",
        vendor_code: "",
        address: "",
        zip_code: "",
        state: "",
        // state_code: "",
        phone: "",
        contact_name: "",
        country_code: null,
        email: "",
        msme: "",
        bank_name: "",
        branch_name: "",
        account_number: "",
        ifsc_code: "",
        swift: "",
        ap_invoice_id: "",
      });
      setApiError({});
      reset();
      dispatch({ type: VENDOR_ADD_RESET });
    });
  };

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
  };

  useEffect(() => {
    onModalClose();
  }, []);

  useEffect(() => {
    if (success) {
      dispatch({ type: VENDOR_ADD_RESET });
      setAddData({
        pan_number: "",
        start_date: "",
        end_date: "",
        approved_amount: "",
        tds_certificate_number: "",
        tds_certificate: "",
        gstin_number: "",
        name: "",
        vendor_code: "",
        address: "",
        zip_code: "",
        state: "",
        // state_code: "",
        phone: "",
        contact_name: "",
        country_code: null,
        email: "",
        msme: "",
        bank_name: "",
        branch_name: "",
        account_number: "",
        ifsc_code: "",
        swift: "",
        ap_invoice_id: "",
      });
      dispatch(getVendorList(page, perPage));
      handleModalClose("#add_vendor");
      closeModal();
      messageApi.open({
        type: "success",
        content: "Vendor created successfully",
        duration: 5,
      });
    }
  }, [success]);

  const closeModal = () => {
    setShow(false); // This will hide the modal
  };

  const handleCommonChange = (e) => {
    const { id, value } = e.target;
    const editId = id?.split("-")[1];
    setAddData((prevState) => ({ ...prevState, [editId]: value }));
    setApiError((prevState) => ({ ...prevState, [editId]: "" }));
  };

  const handleTableChange = (page, perPage) => {
    dispatch(setPage(page, perPage));
  };

  useEffect(() => {
    if (activeTab && activeTab?.includes("#vendorlist")) {
      dispatch(getVendorList(page, perPage));
    }
  }, [dispatch, page, perPage, activeTab]);

  return (
    <>
      {contextHolder}
      <div className="row">
        <div className="col-sm-12">
          <Link
            className="btn btn-primary addBtn"
            data-bs-toggle="modal"
            to="#add_vendor"
            role="button"
          >
            <i className="fa fa-plus-circle me-2" aria-hidden="true" />
            Create Vendor
          </Link>

          <div className="col-sm-12">
            <div className=" card">
              <div className="card-body">
                {error && <p style={{ color: "red" }}>{error}</p>}
                <div className="table-responsive">
                  <Table
                    loading={loading}
                    pagination={{
                      total: vendor?.count,
                      current: page,
                      pageSize: perPage,
                      onChange: handleTableChange,
                      showTotal: (total, range) =>
                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                      onShowSizeChange: onShowSizeChange,
                      itemRender: itemRender,
                    }}
                    columns={columns}
                    dataSource={vendor?.results}
                    rowKey={(record) => record.Id}
                    className="chartofAccTables"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Add Vendor */}
        <CreateVendorModal
          addData={addData}
          modalRef={modalRef}
          show={show}
          handleSubmit={handleSubmit}
          control={control}
          setApiError={setApiError}
          apiError={apiError}
          errors={errors}
          setAddData={setAddData}
          setFile={setFile}
          file={file}
          addVendorLoading={addVendorLoading}
        />
      </div>
    </>
  );
};
VendorList.propTypes = {
  activeTab: PropTypes.string,
};
export default VendorList;
