import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { uploadInvoice } from "../../redux/actions/APmoduleAction";
import { UPLOAD_AP_INVOICE_RESET } from "../../redux/constants/APmoduleConstant";
import CreateModal from "../../_components/modalbox/createmodal";
import { Spin, Select as AntdSelect } from "antd";
import PropTypes from "prop-types";
import { getBankDropdownList } from "../../redux/actions/BankModuleAction";
import { HTTP } from "../../redux/api";

const UploadInvoiceModal = ({
  file,
  uploadData,
  setFile,
  fileInputRef,
  invoiceUploadError,
  setUploadData,
  data,
  // inputValue,
  // setInputValue,
  // debouncedLedgerListFetch,
  invoiceUploadLoading,
  onPopUpScroll,
  ledgerListLoading,
}) => {
  const dispatch = useDispatch();


  const [paymentMethod, setPaymentMethod] = useState([])  


  useEffect(()=>{
    const fetchData = async()=>{
    const response =  await HTTP.get('/bank/payment-methods/')
    const data =  response.data
          setPaymentMethod(data)
    }
    fetchData()
  },[])

  

  

  const handleOnSubmit = (event) => {
    
    event.preventDefault();
    const form_data = new FormData();
    // file.forEach((f) => form_data.append("invoices[]", f));
    file.forEach((f) => form_data.append("invoices", f));
    uploadData?.payment_method?.value &&
      form_data.append("payment_method", uploadData?.payment_method?.value);
    uploadData?.expense_head &&
      form_data.append("expense_head", uploadData?.expense_head);
    dispatch(uploadInvoice(form_data));
  };

  const handleFileChange = (event) => {
    setFile(Array.from(event.target.files));
    dispatch({ type: UPLOAD_AP_INVOICE_RESET });
  };

  useEffect(() => {
    dispatch(getBankDropdownList());
  }, []);



  const uploadInvoicemodal = (
    <CreateModal
      id="upload_invoice"
      show={false}
      title="Upload Invoice"
      modalbody={
        <>
          <form onSubmit={handleOnSubmit}>
            <div className="modal-body p-4">
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="form-label">File Input</label>
                    <input
                      className="form-control"
                      type="file"
                      ref={fileInputRef}
                      multiple
                      onChange={handleFileChange}
                      required
                    />
                    {file?.map((el, index) => (
                      <blockquote key={index}>
                        <p className="mb-0">{el?.name}</p>
                      </blockquote>
                    ))}
                    {invoiceUploadError?.detail && (
                      <p className="text-danger">
                        {invoiceUploadError?.detail}
                      </p>
                    )}
                    {invoiceUploadError?.errors && (
                      <p className="text-danger">
                        {invoiceUploadError?.errors}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label>Payment Method</label>
                    

                        <Select
                          value={uploadData?.payment_method}
                          // ref={(el) => (selectRefs.current["bank"] = el)}
                          options={paymentMethod?.map((option) => ({
                            value: option.id,
                            label: option.bank_name && option.bank_name === 'Cash' ?  
                            option.bank_name : option.account_number 
                            ? `${option.bank_name} - ${option.account_number}` 
                            : `${option.bank_name} - ${option.account_name}`,
                          }))}
                          // required
                          onChange={(selectedOption) => {
                            setUploadData((prevState) => ({
                              ...prevState,
                              payment_method: selectedOption,
                            }));
                          }}
                        />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label>Expense Head</label>
                    <br />
                    <AntdSelect
                    placeholder='Select..'
                      value={uploadData?.expense_head}
                      options={
                        data?.length > 0
                          ? data?.map((option) => ({
                              value: option.id,
                              label: option.title,
                            }))
                          : []
                      }
                      style={{ width: 420 }}
                      onChange={(selectedOption) => {
                        setUploadData((prevState) => ({
                          ...prevState,
                          expense_head: selectedOption,
                        }));
                      }}
                      onPopupScroll={onPopUpScroll}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          {ledgerListLoading && (
                            <div
                              style={{
                                textAlign: "center",
                                padding: 8,
                              }}
                            >
                              <Spin />
                            </div>
                          )}
                        </>
                      )}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    />
                  </div>
                </div>
                {/* <div className="col-md-12">
                      <FileUpload
                        setFiles={setFile}
                        files={file}
                        reset={"UPLOAD_AP_INVOICE_RESET"}
                      />
                      
                    </div> */}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-primary waves-effect waves-light"
                disabled={invoiceUploadLoading}
              >
                {invoiceUploadLoading ? <Spin /> : "Submit"}
              </button>
            </div>
          </form>
        </>
      }
    />
  );
  return <>{uploadInvoicemodal}</>;
};

UploadInvoiceModal.propTypes = {
  file: PropTypes.array.isRequired,
  uploadData: PropTypes.object,
  setFile: PropTypes.func.isRequired,
  fileInputRef: PropTypes.object.isRequired,
  invoiceUploadError: PropTypes.object,
  setUploadData: PropTypes.func.isRequired,
  data: PropTypes.array,
  inputValue: PropTypes.string.isRequired,
  setInputValue: PropTypes.func.isRequired,
  debouncedLedgerListFetch: PropTypes.func.isRequired,
  invoiceUploadLoading: PropTypes.bool,
  onPopUpScroll: PropTypes.func,
  ledgerListLoading: PropTypes.bool,
};

export default UploadInvoiceModal;
