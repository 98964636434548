/* eslint-disable */
import { message, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import CreateModal from "../../_components/modalbox/createmodal";
import CustomInput from "../../_components/inputField/custominput";
import { useDispatch, useSelector } from "react-redux";
import { userProfileUpdateApi } from "../../redux/actions/UserAction";
import { USER_PROFILE_UPDATE_RESET } from "../../redux/constants/UserConstant";

const schema = yup.object({
  first_name: yup
    .string()
    .required("First Name is required")
    .trim(),
  last_name: yup
    .string()
    .required("Last Name is required")
    .trim(),
  username: yup
    .string()
    .required("User Name is required")
    .trim(),
  email: yup
    .string()
    .email("Invalid email")
    .required("Email is required")
    .trim(),
  glance_email: yup
    .string()
    .email("Invalid email")
    .required("Email is required")
    .trim(),
  role: yup
    .string()
    .required("Role is required")
    .trim(),
  phone: yup
    .string()
    .required("Phone Number is required")
    .trim(),
});

const ProfileDetailUpdateModal = ({ id, title, profileData }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [profileDetail, setProfileDetail] = useState({});

  const userProfileUpdation = useSelector(
    (state) => state?.userProfileUpdation
  );
  const {
    loading: userProfileUpdateLoading,
    success: userProfileUpdateSuccess,
    error: userProfileUpdateError,
  } = userProfileUpdation;

  const modalRef = useRef();
  const dispatch = useDispatch();
  const [editError, setEditError] = useState({});

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: profileDetail,
  });

  const onModalClose = () => {
    $("#edit_profile").on("hide.bs.modal", () => {
      reset();
      dispatch({ type: USER_PROFILE_UPDATE_RESET });
    });
  };

  useEffect(() => {
    onModalClose();
  }, []);

  useEffect(() => {
    if (profileData) setProfileDetail(profileData);
  }, [profileData]);

  useEffect(() => {
    if (userProfileUpdateError) {
      setEditError(userProfileUpdateError);
    }
  }, [userProfileUpdateError]);

  useEffect(() => {
    if (userProfileUpdateSuccess) {
      dispatch({ type: USER_PROFILE_UPDATE_RESET });
      setEditError({});
      setProfileDetail({});
      handleModalClose("#edit_profile");
      messageApi.open({
        type: "success",
        content: "Profile updated successfully",
        duration: 5,
      });
    }
  }, [userProfileUpdateSuccess]);

  useEffect(() => {
    if (profileDetail) {
      Object.keys(profileDetail).forEach((field) => {
        setValue(field, profileDetail[field]);
      });
    }
  }, [profileDetail, setValue]);

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
  };

  const ProfileSubmit = (data) => {
    const postData = {
      first_name: data.first_name,
      last_name: data.last_name,
      username: data.username,
      email: data.email,
      glance_email: data.glance_email,
      phone: data.phone,
    };
    dispatch(userProfileUpdateApi(postData));
    console.log("postData", postData);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setProfileDetail((prevState) => ({ ...prevState, [id]: value }));
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const profileEdit = (
    <CreateModal
      id={id}
      modalRef={modalRef}
      title={title}
      modalbody={
        <>
          <div className="modal-body p-4">
            <div className="row">
              <form onSubmit={handleSubmit(ProfileSubmit)}>
                <div className="col-md-12">
                  <div className="form-group-item border-0 mb-0">
                    <div className="row align-item-center">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <Controller
                          name="first_name"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <CustomInput
                              id="first_name"
                              type="text"
                              value={value}
                              onChange={(e) => {
                                handleChange(e);
                                onChange(e);
                              }}
                              placeholder="First Name"
                              label="First Name"
                              errorMsg={errors.first_name?.message}
                              error={errors.first_name}
                              apiError={editError.first_name}
                              // required
                            />
                          )}
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <Controller
                          name="last_name"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <CustomInput
                              id="last_name"
                              type="text"
                              value={value}
                              onChange={(e) => {
                                handleChange(e);
                                onChange(e);
                              }}
                              placeholder="Last Name"
                              label="Last Name"
                              errorMsg={errors.last_name?.message}
                              error={errors.last_name}
                              apiError={editError.last_name}
                              // required
                            />
                          )}
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <Controller
                          name="username"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <CustomInput
                              id="username"
                              type="text"
                              value={value}
                              onChange={(e) => {
                                handleChange(e);
                                onChange(e);
                              }}
                              placeholder="User Name"
                              label="User Name"
                              errorMsg={errors.username?.message}
                              error={errors.username}
                              apiError={editError.username}
                              disabled={true}
                              // required
                            />
                          )}
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <Controller
                          name="email"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <CustomInput
                              id="email"
                              type="email"
                              value={value}
                              onChange={(e) => {
                                handleChange(e);
                                onChange(e);
                              }}
                              placeholder="Email"
                              label="Email"
                              errorMsg={errors.email?.message}
                              error={errors.email}
                              apiError={editError.email}
                              disabled={true}
                              // required
                            />
                          )}
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <Controller
                          name="glance_email"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <CustomInput
                              id="glance_email"
                              type="glance_email"
                              value={value}
                              onChange={(e) => {
                                handleChange(e);
                                onChange(e);
                              }}
                              placeholder="Glance Email"
                              label="Glance Email"
                              errorMsg={errors.glance_email?.message}
                              error={errors.glance_email}
                              apiError={editError.glance_email}
                              disabled={true}
                              // required
                            />
                          )}
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <Controller
                          name="phone"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <CustomInput
                              id="phone"
                              type="text"
                              value={value}
                              onChange={(e) => {
                                handleChange(e);
                                onChange(e);
                              }}
                              placeholder="Phone"
                              label="Phone"
                              errorMsg={errors.phone?.message}
                              error={errors.phone}
                              apiError={editError.phone}
                              disabled={true}
                              // required
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    type="reset"
                    className="btn btn-primary cancel me-2"
                    onClick={() => handleModalClose("#edit_profile")}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {userProfileUpdateLoading ? <Spin /> : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      }
    />
  );

  return (
    <>
      {contextHolder}
      {profileEdit}
    </>
  );
};

ProfileDetailUpdateModal.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  profileData: PropTypes.object.isRequired,
};

export default ProfileDetailUpdateModal;
