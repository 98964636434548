import React, { useMemo } from "react";
import { Select, Divider, Space, Spin } from "antd";
import { Plus } from "lucide-react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const GroupedSelect = ({
  id,
  value,
  onChange,
  options,
  suggestedEntry,
  row,
  onPopupScroll,
  loading,
  placeholder = "Select..",
  index,
  setLedgerIndexData,
}) => {
  const handleClick = (event) => {
    event.preventDefault();
    if (id !== "customer") {
      setLedgerIndexData(index);
    }
  };
  
  const { groupedOptions, suggestedOption } = useMemo(() => {
    let suggestedOpt = null;

    // Process suggested entry if available
    if (
      suggestedEntry &&
      row?.suggested_posting_ledger &&
      Object.keys(row.suggested_posting_ledger).length > 0
    ) {
      suggestedOpt = {
        value: row.suggested_posting_ledger.id,
        label: (
          <div className="suggested-entry-item">
            <span className="star-icon">🌟</span>
            <span>{row.suggested_posting_ledger.title}</span>
          </div>
        ),
        searchLabel: row.suggested_posting_ledger.title.toLowerCase(),
      };
    }

    // Format the grouped options
    const formattedOptions =
      (options.length > 0 &&
        options?.map((group) => ({
          label: group.label,
          options: group?.options?.map((option) => ({
            value: option.id,
            label: option.title,
            searchLabel: option.title.toLowerCase(),
          })),
        }))) ||
      [];

    return {
      groupedOptions: formattedOptions,
      suggestedOption: suggestedOpt,
    };
  }, [options, suggestedEntry, row]);

  // Combine suggested entry with grouped options
  const allOptions = useMemo(() => {
    if (suggestedOption) {
      return [
        {
          label: "Suggested Entry",
          options: [suggestedOption],
        },
        ...groupedOptions,
      ];
    }
    return groupedOptions;
  }, [groupedOptions, suggestedOption]);

  return (
    <div className="grouped-select-container">
      <Select
        style={{ width: 300 }}
        id={id}
        value={value}
        onChange={onChange}
        showSearch
        // style={{ width: '100%' }}
        placeholder={placeholder}
        optionFilterProp="searchLabel"
        filterOption={(input, option) =>
          option?.searchLabel?.indexOf(input.toLowerCase()) >= 0
        }
        onPopupScroll={onPopupScroll}
        dropdownRender={(menu) => (
          <>
            {menu}
            {loading ? (
              <div className="loading-container">
                <Spin />
              </div>
            ) : (
              <>
                <Divider style={{ margin: "8px 0" }} />
                <Space className="add-item-container">
                  <Link
                    className="add-item-button"
                    data-bs-toggle="modal"
                    // to="#customer_modal"
                    to={id === "customer" ? "#customer_modal" : "#add_ledger"}
                    role="button"
                    onClick={handleClick}
                  >
                    <Plus size={16} />
                    <span>Add item</span>
                  </Link>
                </Space>
              </>
            )}
          </>
        )}
        options={allOptions}
      />
      <style>
        {`
          .grouped-select-container {
            width: 100%;
          }
          
          .suggested-entry-item {
            display: flex;
            align-items: center;
            gap: 8px;
          }
          
          .star-icon {
            font-size: 16px;
          }
          
          .loading-container {
            padding: 8px;
            display: flex;
            justify-content: center;
          }
          
          .add-item-container {
            padding: 0 8px 4px;
          }
          
          .add-item-button {
            display: flex;
            align-items: center;
            gap: 8px;
            background: none;
            border: none;
            cursor: pointer;
            color: #1677ff;
            padding: 4px 8px;
            font-size: 14px;
          }
          
          .add-item-button:hover {
            background-color: rgba(0, 0, 0, 0.04);
          }
          
          .ant-select-item-group {
            font-weight: bold;
            color: #000;
          }
        `}
      </style>
    </div>
  );
};

GroupedSelect.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          title: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  suggestedEntry: PropTypes.bool,
  row: PropTypes.object,
  onPopupScroll: PropTypes.func,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  setLedgerIndexData: PropTypes.func,
  index: PropTypes.number,
};

export default GroupedSelect;
