/* eslint-disable */

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
// import FeatherIcon from "feather-icons-react";
// import Data from "../assets/jsons/customers";
// import Table from "../_components/Datatable/datatable";
import "../_components/antd.css";
import { Spin, Table, Dropdown, Button, message, Menu } from "antd";
import Select from "react-select";
import {
  onShowSizeChange,
  itemRender,
} from "../_components/paginationfunction";
import AddVendor from "../customers/vendors/addVendor";
// import { img14, img15 } from "../_components/Imagepath";
// import FileUpload from "../components/FileUpload";
import { useDispatch } from "react-redux";
import { getVendorSearchList } from "../redux/actions/VendorAction";
import { useSelector } from "react-redux";
import {
  VENDOR_ADD_RESET,
  VENDOR_EXISTING_ADD_RESET,
  VENDOR_SEARCH_RESET,
} from "../redux/constants/VendorConstant";
import {
  apEditInvoiceDetail,
  getApInvoiceList,
  getManagerList,
  invoiceApproval,
  invoiceApprovalPost,
  setPage,
} from "../redux/actions/APmoduleAction";
import {
  AP_INVOICE_APPROVAL_RESET,
  AP_INVOICE_POST_APPROVAL_RESET,
  UPLOAD_AP_INVOICE_RESET,
  UPLOAD_SEND_RESET,
  UPLOAD_SUBMIT_ZOHO_RESET,
} from "../redux/constants/APmoduleConstant";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import ImagePreview from "./imagepreview";
import {
  commonVendorFieldOrder,
  vendorFieldOrder,
} from "../settings/chartsOfAccount";
import { getLedgerModuleList } from "../redux/actions/LedgerModuleAction";
import { LEDGERLIST_INVOICE_RESET } from "../redux/constants/ledgerListModuleConstant";
import APModuleHeader from "./apModuleHeader";
import {
  getExpenseManagerListApi,
  getUserList,
  getUserListwithRole,
} from "../redux/actions/commonApiAction";
import ApprovalModal from "./approvalModal";
import ConfirmationModal from "./confirmationModal";

export const dropdownClassNames = {
  "pending approval": "fa fa-dot-circle-o text-warning me-1",
  Approve: "fa fa-dot-circle-o text-success me-1",
  "user approved": "fa fa-dot-circle-o text-success me-1",
  "rejected approval": "fa fa-dot-circle-o text-danger me-1",
  Reject: "fa fa-dot-circle-o text-danger me-1",
};
import { permission } from "../redux/permission";
import UploadInvoiceModal from "./vendors/uploadinvoicemodal";
import { formatDateYYMMDD } from "../sales/invoices/constant";

const schema = yup.object({
  name: yup
    .string()
    .required("Please fill this field")
    .trim(),
  state: yup
    .string()
    .required("Please fill this field")
    .trim(),
  address: yup
    .string()
    .required("Please fill this field")
    .trim(),
});

const Customers = () => {
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [file, setFile] = useState([]);
  const [addVendorData, setAddVendorData] = useState({});
  const [isExistingVendor, setIsExistingVendor] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [apiError, setApiError] = useState({});
  const [apiSubmitZohoError, setApiSubmitZohoError] = useState({});
  const [apiSendError, setApiSendError] = useState({});
  const [currentStatus, setCurrentStatus] = useState("");
  const [newStatus, setNewStatus] = useState("");
  const [showStatusChangeModal, setShowStatusChangeModal] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [vendorPage, setVendorPage] = useState({ page: 1, perPage: 10 });
  const [rejectionReason, setRejectionReason] = useState("");
  const [apInvoiceData, setApInvoiceData] = useState({});
  const [ledgerListData, setLedgerData] = useState([]);
  const [ledgerPage, setLedgerPage] = useState({ page: 1, perPage: 10 });
  const [filterData, setFilterData] = useState({
    invoice_date: "",
    user: "",
    status: null,
    approval_status: null,
    approver: null,
  });
  const [requestedTo, setRequestedTo] = useState({ user: null });
  const [uploadData, setUploadData] = useState(null);
  const [existingVendorError, setExistingVendorError] = useState({});
  const apInvoiceList = useSelector((state) => state?.apInvoiceList);
  const { loading: load, page, perPage } = apInvoiceList;
  const addvendor = useSelector((state) => state.addvendor);
  const { success, loading, error } = addvendor;
  const addExistingVendor = useSelector((state) => state.existingVendorCreate);
  const {
    success: addExistingVendorSuccess,
    loading: addExistingVendorLoading,
    error: addExistingVendorError,
  } = addExistingVendor;
  const invoiceUpload = useSelector((state) => state?.invoiceUpload);
  const {
    loading: invoiceUploadLoading,
    error: invoiceUploadError,
    success: invoiceUploadSuccess,
  } = invoiceUpload;
  const apInvoiceApproval = useSelector((state) => state?.apInvoiceApproval);
  const {
    loading: invoiceApprovalLoading,
    error: invoiceApprovalError,
    success: invoiceApprovalSuccess,
  } = apInvoiceApproval;

  const apInvoicePostApproval = useSelector(
    (state) => state?.apInvoicePostApproval
  );
  const {
    loading: invoicePostApprovalLoading,
    error: invoicePostApprovalError,
    success: invoicePostApprovalSuccess,
  } = apInvoicePostApproval;

  const invoiceEditDetailInvoice = useSelector(
    (state) => state.apInvoiceEditDetail
  );
  const ledgerList = useSelector((state) => state?.ledgerList);
  const { loading: ledgerListLoading, data } = ledgerList;
  const {
    apInvoiceEditDetail,
    error: apInvoiceListError,
  } = invoiceEditDetailInvoice;
  const vendorListSearch = useSelector((state) => state.vendorListSearch);
  const { vendorSearchList, loading: vendorListLoading } = vendorListSearch;

  const userList = useSelector((state) => state.userList);
  const { userListData } = userList;
  const userListOnRole = useSelector((state) => state.userListOnRole);
  const { userListData: roleUser } = userListOnRole;

  const submitZoho = useSelector((state) => state.submitZoho);
  const {
    zohoApprovalSuccess,
    zohoApprovalLoading,
    zohoApprovalError,
  } = submitZoho;

  const sendApproval = useSelector((state) => state.sendApproval);
  const {
    sendApprovalSuccess,
    sendApprovalLoading,
    sendApprovalError,
  } = sendApproval;

  const modalRef = useRef(null);
  const fileInputRef = useRef(null);
  const selectRefs = useRef({});
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [isDisable, setIsDisable] = useState(false);
  const userData = useSelector((state) => state.userSignin);
  const { permissions, userId } = userData;
  const [isHovered, setIsHovered] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    setApiError(error);
    setApiSubmitZohoError(zohoApprovalError);
    setApiSendError(sendApprovalError);
  }, [error, zohoApprovalError, sendApprovalError]);

  useEffect(() => {
    dispatch(getUserList());
    dispatch(getUserListwithRole("expense_manager"));
    // dispatch(getManagerList());
  }, []);

  const handleSelectFilterChange = (selectedOption, id) => {
    dispatch(setPage(1, 10));
    setFilterData((prevState) => ({
      ...prevState,
      [id]: selectedOption,
    }));
  };

  const handleDateChange = (start, end) => {
    setFilterData({
      ...filterData,
      startDate: start,
      endDate: end,
    });
  };

  const handleDateFilterChange = (selectedDate, id) => {
    dispatch(setPage(1, 10));
    setFilterData((prevState) => ({
      ...prevState,
      [id]: selectedDate,
    }));
  };

  const inputValues = {
    name: "",
    state_code: "",
    state: "",
    address: "",
    gstin_number: "",
    zip_code: "",
    account_number: "",
    ifsc_code: "",
    swift: "",
  };

  useEffect(() => {
    if (
      (vendorPage?.page > 1 && vendorSearchList?.next) ||
      vendorPage?.page === 1
    ) {
      dispatch(getVendorSearchList("", vendorPage?.page, vendorPage?.perPage));
    }
  }, [vendorPage?.page, vendorPage?.perPage]);

  useEffect(() => {
    if ((ledgerPage?.page > 1 && data?.next) || ledgerPage?.page === 1) {
      dispatch(getLedgerModuleList("", ledgerPage?.page, ledgerPage?.perPage));
    }
  }, [dispatch, ledgerPage?.page, ledgerPage?.perPage]);

  useEffect(() => {
    if (data?.results) {
      const newVendors = data?.results;

      // Create a Set of the current vendor ids/names for quick look-up
      const existingVendorIds = new Set(
        ledgerListData.map((vendor) => vendor.id)
      ); // Assuming `id` is the unique identifier

      // Filter newVendors to only include those that are not already in vendorList
      const uniqueNewVendors = newVendors.filter(
        (vendor) => !existingVendorIds.has(vendor.id)
      );

      // Combine the previous vendorList with the unique new ones
      const combinedVendorList = [...ledgerListData, ...uniqueNewVendors];
      setLedgerData(combinedVendorList); // Update state with the combined list
    }
  }, [data]);

  useEffect(() => {
    if (vendorSearchList?.results) {
      const newVendors = vendorSearchList.results;

      // Create a Set of the current vendor ids/names for quick look-up
      const existingVendorIds = new Set(vendorList.map((vendor) => vendor.id)); // Assuming `id` is the unique identifier

      // Filter newVendors to only include those that are not already in vendorList
      const uniqueNewVendors = newVendors.filter(
        (vendor) => !existingVendorIds.has(vendor.id)
      );

      // Combine the previous vendorList with the unique new ones
      const combinedVendorList = [...vendorList, ...uniqueNewVendors];

      setVendorList(combinedVendorList); // Update state with the combined list
    }
  }, [vendorSearchList]);

  const handlePopupScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollHeight - scrollTop <= clientHeight + 10 &&
      !vendorListLoading &&
      vendorSearchList?.results?.length
    ) {
      setVendorPage((prevState) => ({
        ...prevState,
        page: prevState?.page + 1,
        perPage: prevState?.perPage,
      }));
    }
  };

  const handleLedgerPopupScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollHeight - scrollTop <= clientHeight + 10 &&
      !ledgerListLoading &&
      data?.results?.length
    ) {
      setLedgerPage((prevState) => ({
        ...prevState,
        page: prevState?.page + 1,
        perPage: prevState?.perPage,
      }));
    }
  };

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: inputValues,
  });

  useEffect(() => {
    const firstErrorField = vendorFieldOrder.find((field) => errors[field]);
    if (firstErrorField) {
      const inputElement = document.getElementById(firstErrorField);
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [errors]);

  useEffect(() => {
    const commonErrorField = commonVendorFieldOrder.find(
      (field) => apiError && apiError[field]
    );
    if (commonErrorField) {
      const commonInputElement = document.getElementById(commonErrorField);
      if (commonInputElement) {
        commonInputElement.focus();
        setIsDisable(false);
      }
    }
  }, [apiError]);

  const onModalClose = () => {
    $("#add_vendor").on("hide.bs.modal", () => {
      setAddVendorData({
        pan_number: "",
        gstin_number: "",
        name: "",
        vendor_code: "",
        address: "",
        zip_code: "",
        state: "",
        state_code: "",
        phone: "",
        contact_name: "",
        country_code: null,
        email: "",
        msme: "",
        bank_name: "",
        branch_name: "",
        account_number: "",
        ifsc_code: "",
        swift: "",
        ap_invoice_id: "",
      });
      setInputValue("");
      setSelectedVendor(null);
      setExistingVendorError({});
      setIsExistingVendor(false);
      setApiError({});
      reset();
      dispatch({ type: VENDOR_ADD_RESET });
      dispatch({ type: VENDOR_SEARCH_RESET });
    });
    $("#upload_invoice").on("hide.bs.modal", () => {
      setFile([]);
      setInputValue("");
      setUploadData({ payment_method: "", expense_head: "" });
      fileInputRef.current.value = "";
      dispatch({ type: UPLOAD_AP_INVOICE_RESET });
      dispatch({ type: LEDGERLIST_INVOICE_RESET });
    });
    $("#send-approval").on("hide.bs.modal", () => {
      setRequestedTo({ user: null });
      setApiSubmitZohoError({});
      dispatch({ type: UPLOAD_SEND_RESET });
      dispatch({ type: UPLOAD_SUBMIT_ZOHO_RESET });
      setApiSendError({});
    });
  };

  const handleEditClick = (record) => {
    setApInvoiceData(record);
  };

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
  };

  useEffect(() => {
    if (success) {
      dispatch({ type: VENDOR_ADD_RESET });
      setAddVendorData({
        pan_number: "",
        gstin_number: "",
        name: "",
        vendor_code: "",
        address: "",
        zip_code: "",
        state: "",
        state_code: "",
        phone: "",
        contact_name: "",
        country_code: null,
        email: "",
        msme: "",
        bank_name: "",
        branch_name: "",
        account_number: "",
        ifsc_code: "",
        swift: "",
        ap_invoice_id: "",
      });
      dispatch(getApInvoiceList(page, perPage,"", "", "", "", "",""));
      handleModalClose("#add_vendor");
      closeModal();
      messageApi.open({
        type: "success",
        content: "Vendor created successfully",
        duration: 5,
      });
    }
  }, [success]);

  useEffect(() => {
    if (addExistingVendorSuccess) {
      dispatch({ type: VENDOR_EXISTING_ADD_RESET });
      setSelectedVendor(null);
      setExistingVendorError({});
      setInputValue("");
      setAddVendorData({
        pan_number: "",
        gstin_number: "",
        name: "",
        vendor_code: "",
        address: "",
        zip_code: "",
        state: "",
        state_code: "",
        phone: "",
        contact_name: "",
        country_code: null,
        email: "",
        msme: "",
        bank_name: "",
        branch_name: "",
        account_number: "",
        ifsc_code: "",
        swift: "",
        ap_invoice_id: "",
      });
      dispatch(getApInvoiceList(page, perPage,"", "", "", "", "",""));
      handleModalClose("#add_vendor");
      closeModal();
      messageApi.open({
        type: "success",
        content: "Vendor connected successfully",
        duration: 5,
      });
    }
  }, [addExistingVendorSuccess]);

  useEffect(() => {
    if (invoiceUploadSuccess) {
      dispatch({ type: UPLOAD_AP_INVOICE_RESET });
      setFile([]);
      handleModalClose("#upload_invoice");
      closeModal();
      setInputValue("");
      dispatch(getApInvoiceList(page, perPage,"", "", "", "", "",""));
      dispatch({ type: LEDGERLIST_INVOICE_RESET });
      messageApi.open({
        type: "success",
        content: "Invoice(s) uploaded successfully.Data is being processed",
        duration: 5,
      });
    }
  }, [invoiceUploadSuccess]);

  useEffect(() => {
    if (invoiceApprovalSuccess) {
      dispatch({ type: AP_INVOICE_APPROVAL_RESET });
      setFile([]);
      handleModalClose();
      setRejectionReason("");
      dispatch(getApInvoiceList(page, perPage,"", "", "", "", "",""));
      messageApi.open({
        type: "success",
        content: "Status updated successfully",
        duration: 5,
      });
    } else if (invoicePostApprovalSuccess) {
      dispatch({ type: AP_INVOICE_POST_APPROVAL_RESET });
      setFile([]);
      handleModalClose();
      setRejectionReason("");
      dispatch(getApInvoiceList(page, perPage,"", "", "", "", "",""));
      messageApi.open({
        type: "success",
        content: "Status updated successfully",
        duration: 5,
      });
    }
  }, [invoiceApprovalSuccess, invoicePostApprovalSuccess]);

  const closeModal = () => {
    setShow(false); // This will hide the modal
  };

  const handleAddClick = (invoiceDetail) => {
    if (invoiceDetail?.suggested_vendor) {
      setAddVendorData({
        pan_number: invoiceDetail?.suggested_vendor?.pan_number
          ? invoiceDetail?.suggested_vendor?.pan_number
          : "",
        gstin_number: invoiceDetail?.suggested_vendor?.gstin_number
          ? invoiceDetail?.suggested_vendor?.gstin_number
          : "",
        name: invoiceDetail?.suggested_vendor?.name
          ? invoiceDetail?.suggested_vendor?.name
          : "",
        vendor_code: invoiceDetail?.suggested_vendor?.vendor_code
          ? invoiceDetail?.suggested_vendor?.vendor_code
          : "",
        address: invoiceDetail?.suggested_vendor?.address
          ? invoiceDetail?.suggested_vendor?.address
          : "",
        zip_code: invoiceDetail?.suggested_vendor?.zip_code
          ? invoiceDetail?.suggested_vendor?.zip_code
          : "",
        state: invoiceDetail?.suggested_vendor?.state
          ? invoiceDetail?.suggested_vendor?.state
          : "",
        state_code: invoiceDetail?.suggested_vendor?.state_code
          ? invoiceDetail?.suggested_vendor?.state_code
          : "",
        phone: invoiceDetail?.suggested_vendor?.phone
          ? invoiceDetail?.suggested_vendor?.phone
          : "",
        contact_name: invoiceDetail?.suggested_vendor?.contact_name
          ? invoiceDetail?.suggested_vendor?.contact_name
          : "",
        country_code: invoiceDetail?.suggested_vendor?.country_code
          ? invoiceDetail?.suggested_vendor?.country_code
          : null,
        email: invoiceDetail?.suggested_vendor?.email
          ? invoiceDetail?.suggested_vendor?.email
          : "",
        msme: invoiceDetail?.suggested_vendor?.msme
          ? invoiceDetail?.suggested_vendor?.msme
          : "",
        bank_name: invoiceDetail?.suggested_vendor?.bank_name
          ? invoiceDetail?.suggested_vendor?.bank_name
          : "",
        branch_name: invoiceDetail?.suggested_vendor?.branch_name
          ? invoiceDetail?.suggested_vendor?.branch_name
          : "",
        account_number: invoiceDetail?.suggested_vendor?.account_number
          ? invoiceDetail?.suggested_vendor?.account_number
          : "",
        ifsc_code: invoiceDetail?.suggested_vendor?.ifsc_code
          ? invoiceDetail?.suggested_vendor?.ifsc_code
          : "",
        swift: invoiceDetail?.suggested_vendor?.swift
          ? invoiceDetail?.suggested_vendor?.swift
          : "",
        ap_invoice_id: invoiceDetail?.id,
      });
    } else {
      setAddVendorData((prevState) => ({
        ...prevState,
        ap_invoice_id: invoiceDetail?.id,
      }));
    }
  };

  const statusMenu = (text) => {
    const menuItems = {
      "pending approval": ["Approve", "Reject"],
      "user approved": ["Reject"],
      "rejected approval": ["Approve"],
    };
    const actions = menuItems[text] || [];

    return actions.map((each) => ({
      key: each,
      label: (
        <div
          onClick={() => {
            setCurrentStatus(text);
            setNewStatus(each);
            setShowStatusChangeModal(true);
          }}
        >
          <i className={dropdownClassNames[each]} /> {each}
        </div>
      ),
    }));
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-";

    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const columns = [
    {
      title: (
        <input
          type="checkbox"
          // checked={
          //   selectedRowKeys.length === apInvoiceList?.apInvoice?.results.length
          // }
          // onChange={handleSelectAll}
        />
      ),
      dataIndex: "select",
      render: (text, record) => (
        <input
          type="checkbox"
          // checked={selectedRowKeys.includes(record.id)}
          // onChange={() => handleCheckboxChange(record.id)}
        />
      ),
      width: 80,
    },
    {
      title: "File Name",
      dataIndex: "file_name",
      sorter: (a, b) => (a.file_name || "").length - (b.file_name || "").length,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "User",
      dataIndex: "created_by",
      sorter: (a, b) =>
        (a.created_by || "").length - (b.created_by || "").length,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Description",
      dataIndex: "nature_of_supply",
      sorter: (a, b) =>
        (a.nature_of_supply || "").length - (b.nature_of_supply || "").length,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Vendor",
      render: (text, record) => (
        <>
          {record?.linked_to_vendor === null ? (
            "-"
          ) : record?.linked_to_vendor ? (
            <div>{record?.vendor?.name ? record?.vendor?.name : "-"}</div>
          ) : (
            <>
              {permissions?.apModule?.transactionAddEdit && (
                <Link
                  className="btn btn-success btn-sm"
                  data-bs-toggle="modal"
                  to="#add_vendor"
                  role="button"
                  onClick={() => handleAddClick(record)}
                >
                  <i
                    className="fa fa-plus"
                    aria-hidden="true"
                    style={{ color: "white !important" }}
                  />
                  &nbsp;Vendors
                </Link>
              )}
            </>
          )}
        </>
      ),
    },
    {
      title: "Uploaded Date",
      dataIndex: "created_on",
      sorter: (a, b) => new Date(a.created_on) - new Date(b.created_on),
      render: (text) => formatDate(text),
    },

    {
      title: "Expense Ledger",
      dataIndex: "expense_ledger",
      render: (text, record) => (
        <>
          <p>{record?.expense_ledger?.title || "-"}</p>
        </>
      ),
      sorter: (a, b) =>
        (a?.expense_ledger?.title || "").length -
        (b?.expense_ledger?.title || "").length,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a, b) => (a.amount || "").length - (b.amount || "").length,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Tax",
      dataIndex: "tax",
      sorter: (a, b) => (a.tax || "").length - (b.tax || "").length,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "TDS",
      dataIndex: "tds",
      sorter: (a, b) => (a.tds || "").length - (b.tds || "").length,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Total",
      dataIndex: "total",
      sorter: (a, b) => (a.total || "").length - (b.total || "").length,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => (a.status || "").length - (b.status || "").length,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Approval Status",
      dataIndex: "approval_status",
      sorter: (a, b) =>
        (a.approval_status || "").length - (b.approval_status || "").length,
      render: (text, record) => {
        const isDisabled = record.submitted_to_erp || !record?.show_status;
        const cursorStyle = isDisabled ? "not-allowed" : "pointer";
        return (
          <>
            {text
              ? permissions?.apModule?.invoiceApproveReject && (
                  <Dropdown
                    menu={{ items: statusMenu(text) }}
                    trigger={["click"]}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  >
                    <Button
                      className={
                        "btn btn-grey btn-sm btn-rounded dropdown-toggle"
                      }
                      onClick={() => {
                        if (!isDisabled) {
                          dispatch(apEditInvoiceDetail(record?.id));
                        }
                      }}
                      disabled={isDisabled}
                      style={{ cursor: cursorStyle }}
                    >
                      <i className={dropdownClassNames[text]} /> {text}
                    </Button>
                  </Dropdown>
                )
              : "-"}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (text, record) => (
        <>
          {record?.approval_status
            ? (permissions?.apModule?.transactionAddEdit ||
                permissions.apModule.sendToErp) && (
                <div className="table-invoice d-flex align-items-center stickyBtns">
                  {permissions?.apModule?.transactionAddEdit && (
                    <Link
                      to={`/ap-module/edit-invoice/${record?.id}`}
                      className="btn btn-primary me-2"
                    >
                      <i className="far fa-edit" />
                    </Link>
                  )}
                  {!record?.submitted_to_erp && (
                    // ||
                    //   permissions.apModule.sendToErp
                    <Link
                      className="btn btn-grey"
                      data-bs-toggle="modal"
                      to="#send-approval"
                      role="button"
                      onClick={() => handleEditClick(record)}
                    >
                      <i className="far fa-send" />
                    </Link>
                  )}
                </div>
              )
            : "-"}
        </>
      ),
      sorter: (a, b) => (a.Action || "").length - (b.Action || "").length,
    },
  ];
  useEffect(() => {
    onModalClose();
  }, []);

  useEffect(() => {
    dispatch(
      getApInvoiceList(
        page,
        perPage,
        filterData?.invoice_date
          ? formatDateYYMMDD(filterData?.invoice_date)
          : "",
        filterData?.startDate
          ? formatDateYYMMDD(filterData?.startDate?.startDate)
          : "",
        filterData?.startDate
          ? formatDateYYMMDD(filterData?.startDate?.endDate)
          : "",
        filterData?.user ? filterData?.user?.value : "",
        filterData?.status ? filterData?.status?.value : "",
        filterData?.approval_status ? filterData?.approval_status?.value : ""
      )
    );
  }, [page, perPage, dispatch, filterData, refresh]);

  useEffect(() => {
    if (apInvoiceEditDetail) {
      setApInvoiceData(apInvoiceEditDetail);
    }
  }, [apInvoiceEditDetail]);

  const handleExpand = (expanded, record) => {
    if (expanded) {
      dispatch(apEditInvoiceDetail(record?.id));
    }
    const keys = expanded ? [record?.id] : [];
    setExpandedRowKeys(keys);
  };

  const expandedRowRender = (record) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ flex: 1 }}>
          {apInvoiceListError === 404 ? (
            <>
              <p>{record?.status}</p>
            </>
          ) : (
            <table>
              <thead>
                {apInvoiceEditDetail?.invoice_approval?.rejected_reason && (
                  <tr>
                    <th>
                      {" "}
                      Rejected Reason :{" "}
                      {apInvoiceEditDetail?.invoice_approval?.rejected_reason}
                    </th>
                  </tr>
                )}
                <tr>
                  <th>S.No</th>
                  <th>Posting Ledger</th>
                  <th>Description</th>
                  <th>HSN/SAC</th>
                  <th>Unit</th>
                  <th>Rate</th>
                  <th>GST Rate</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {apInvoiceEditDetail?.items?.map((ele, i) => {
                  return (
                    <tr key={ele?.id}>
                      <td>{i + 1}</td>
                      <td>{ele?.posting_ledger?.title}</td>
                      <td className="tdDesc">{ele?.description}</td>
                      <td>{ele?.product_code}</td>
                      <td>{ele?.quantity}</td>
                      <td>{ele?.rate}</td>
                      <td>{ele?.gst_rate}</td>
                      <td>{ele?.amount}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Total</td>
                  <td>{apInvoiceEditDetail?.total_amount}</td>
                </tr>
                {apInvoiceEditDetail?.tax_items?.map((tax) => (
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{tax?.tax_type}</td>
                    <td>{tax?.tax_amount}</td>
                  </tr>
                ))}

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Total Tax</td>
                  <td>{apInvoiceEditDetail?.total_tax}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>TDS</td>
                  <td>{apInvoiceEditDetail?.total_tds_amount}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Amount Payable</td>
                  <td>{apInvoiceEditDetail?.amount_payable}</td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
        <div>
          {apInvoiceEditDetail?.file_url && (
            <ImagePreview
              src={apInvoiceEditDetail?.file_url}
              width={250}
              height={320}
              moduleName="apModuleList"
            />
          )}
        </div>
      </div>
    );
  };

  const handleRequestToChange = (selectedOption, id) => {
    setRequestedTo((prevState) => ({
      ...prevState,
      [id]: selectedOption,
    }));
    setApiSendError((prevState) => ({
      ...prevState,
      [id]: "",
      requested_to: "",
      non_field_errors: "",
    }));
  };

  const handleTableChange = (page, perPage) => {
    dispatch(setPage(page, perPage));
  };

  const handleStatusChange = (reason) => {
    console.log(currentStatus, newStatus, "reason");

    // Create common data structure
    const createData = (status, reason = "") => ({
      approval_status: status,
      ap_invoice: parseInt(apInvoiceData?.id),
      rejected_reason: reason,
      requested_to: userId,
      requested_by: userId,
    });

    // Function to dispatch the action
    const dispatchApproval = (data) => {
      if (apInvoiceData?.invoice_approval) {
        dispatch(
          invoiceApproval(
            data,
            parseInt(apInvoiceEditDetail?.invoice_approval?.id)
          )
        );
      } else {
        dispatch(invoiceApprovalPost(data));
      }
    };

    // Handle different status transitions
    if (currentStatus === "pending approval") {
      if (newStatus === "Approve") {
        dispatchApproval(createData("approved"));
      } else if (newStatus === "Reject") {
        dispatchApproval(createData("rejected", reason));
      }
    } else if (currentStatus === "user approved" && newStatus === "Reject") {
      dispatchApproval(createData("rejected", reason));
    } else if (
      currentStatus === "rejected approval" &&
      newStatus === "Approve"
    ) {
      dispatchApproval(createData("approved"));
    }

    setShowStatusChangeModal(false); // Close the modal
  };

  useEffect(() => {
    if (addVendorData) {
      Object.keys(addVendorData).forEach((field) => {
        setValue(field, addVendorData[field]);
      });
    }
  }, [addVendorData, setValue]);

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        {contextHolder}
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper customers">
          <div className="content container-fluid">
            <div className="page-header">
              <APModuleHeader
                filterData={filterData}
                setFilterData={setFilterData}
                userListData={userListData}
                handleSelectFilterChange={handleSelectFilterChange}
                handleDateChange={handleDateChange}
                permissions={permissions}
                isHovered={isHovered}
                setIsHovered={setIsHovered}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                setRefresh={setRefresh}
                refresh={refresh}
                handleDateFilterChange={handleDateFilterChange}
              />
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card customers">
                  <div className="card-body">
                    <div className="table-responsive table-hover cmb-35">
                      <Table
                        className="table apmoduleList"
                        loading={load}
                        pagination={{
                          total: apInvoiceList?.apInvoice?.count,
                          current: page,
                          pageSize: perPage,
                          onChange: handleTableChange,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          // showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        expandable={{
                          expandedRowRender,
                          expandedRowKeys,
                          onExpand: handleExpand,
                        }}
                        columns={columns}
                        dataSource={apInvoiceList?.apInvoice?.results}
                        rowKey={(record) => record.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* sample modal content */}
        <UploadInvoiceModal
          file={file}
          uploadData={uploadData}
          setFile={setFile}
          fileInputRef={fileInputRef}
          invoiceUploadError={invoiceUploadError}
          setUploadData={setUploadData}
          data={ledgerListData}
          onPopUpScroll={handleLedgerPopupScroll}
          ledgerListLoading={ledgerListLoading}
          inputValue={inputValue}
          setInputValue={setInputValue}
          invoiceUploadLoading={invoiceUploadLoading}
        />
        <ApprovalModal
          requestedToOptions={roleUser}
          handleRequestToChange={handleRequestToChange}
          requestedTo={requestedTo}
          setRequestedTo={setRequestedTo}
          apInvoiceData={apInvoiceData}
          zohoApprovalLoading={zohoApprovalLoading || false}
          zohoApprovalSuccess={zohoApprovalSuccess || false}
          apiSubmitZohoError={apiSubmitZohoError || {}}
          setApiSubmitZohoError={setApiSubmitZohoError}
          apiSendError={apiSendError || {}}
          setApiSendError={setApiSendError}
          sendApprovalSuccess={sendApprovalSuccess || false}
          sendApprovalLoading={sendApprovalLoading || false}
          messageApi={messageApi}
          handleModalClose={handleModalClose}
          listApiCall={getApInvoiceList(page, perPage, "", "", "", "")}
        />
        <ConfirmationModal
          isVisible={showStatusChangeModal}
          onClose={() => setShowStatusChangeModal(false)}
          onConfirm={handleStatusChange}
          currentStatus={currentStatus}
          newStatus={newStatus}
          invoiceApprovalLoading={invoiceApprovalLoading}
          invoiceApprovalSuccess={invoiceApprovalSuccess}
          invoiceApprovalError={invoiceApprovalError}
          invoicePostApprovalLoading={invoicePostApprovalLoading}
          invoicePostApprovalError={invoicePostApprovalError}
          invoicePostApprovalSuccess={invoicePostApprovalSuccess}
          messageApi={messageApi}
          handleModalClose={handleModalClose}
          setRejectionReason={setRejectionReason}
          rejectionReason={rejectionReason}
        />
        <AddVendor
          show={show}
          modalRef={modalRef}
          handleSubmit={handleSubmit}
          addVendorData={addVendorData}
          setIsDisable={setIsDisable}
          isExistingVendor={isExistingVendor}
          setIsExistingVendor={setIsExistingVendor}
          setExistingVendorError={setExistingVendorError}
          setSelectedVendor={setSelectedVendor}
          selectedVendor={selectedVendor}
          vendorList={vendorList}
          selectRefs={selectRefs}
          inputValue={inputValue}
          existingVendorError={existingVendorError}
          setInputValue={setInputValue}
          apiError={apiError}
          setApiError={setApiError}
          setAddVendorData={setAddVendorData}
          errors={errors}
          isDisable={isDisable}
          addExistingVendorLoading={addExistingVendorLoading}
          control={control}
          loading={loading}
          vendorListLoading={vendorListLoading}
          handlePopupScroll={handlePopupScroll}
        />
      </div>
    </>
  );
};
export default Customers;
