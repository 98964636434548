/* eslint-disable */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import "../_components/antd.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  apRecurringEditInvoiceDetail,
  updateApRecurringInvoice,
} from "../redux/actions/RecurringAction";
import { message, Spin } from "antd";
import { getCompanyList } from "../redux/actions/commonApiAction";
import { AP_RECURRINGINVOICE_EDIT_RESET } from "../redux/constants/RecurringInvoiceConstant";
import {
  dayList,
  formatDateYYMMDD,
  initialLedgerData,
  scheduleTypes,
} from "../sales/invoices/constant";
import {
  getLedgerModuleList,
  getLedgerModuleListByGroup,
  postLedgerModuleList,
} from "../redux/actions/LedgerModuleAction";
import {
  LEDGERLIST_INVOICE_RESET,
  POST_LEDGERLIST_RESET,
} from "../redux/constants/ledgerListModuleConstant";
import useUpdateFooterData from "./updateFooterData";
import useCalculateTotals from "../helpers/calculation";
import AddEditApInvoiceRecurringModal from "../sales/invoices/recurring/addEditApRecurringModal";
import { getVendorSearchList } from "../redux/actions/VendorAction";
import {
  VENDOR_ADD_RESET,
  VENDOR_SEARCH_RESET,
} from "../redux/constants/VendorConstant";
import VendorModal from "../sales/invoices/vendorModal";

const schema = yup.object({
  vendor: yup
    .number()
    .required("Vendor is required")
    .typeError("Vendor must be a number"),
  start_date: yup
    .date()
    .required("Start date is required")
    .nullable()
    .required("State date is required"),
  end_date: yup
    .date()
    .required("End date is required")
    .nullable()
    .required("End date is required"),
});

const EditApInvoiceRecurring = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(false);
  const [recurringInvoiceData, setRecurringInvoiceData] = useState({});
  const [editError, setEditError] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const [data, setData] = useState(initialLedgerData);
  const [ledgerListData, setLedgerData] = useState([]);
  const [ledgerIndexData, setLedgerIndexData] = useState("");
  const [ledgerPage, setLedgerPage] = useState({ page: 1, perPage: 10 });
  const [calculationData, setCalculationData] = useState({});
  const [footerData, setFooterData] = useState({});
  const [gstSection, setGstSection] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [page, setPage] = useState({ page: 1, perPage: 10 });
  const [itemsError, setItemsError] = useState(null);
  const [vendorDetail, setVendorDetail] = useState({});
  const [editable, setEditable] = useState({});
  const [showErrors, setShowErrors] = useState(true);
  const ledgerList = useSelector((state) => state?.ledgerModuleListByGroup);
  const { loading: ledgerListLoading, ledger } = ledgerList;

  //vendor
  const [addVendorData, setAddVendorData] = useState({});
  const [apiVendorError, setApiVendorError] = useState({});
  const [vendorFile, setVendorFile] = useState(null);

  const addvendor = useSelector((state) => state.addvendor);
  const {
    success: vendorSuccess,
    loading: vendorLoading,
    error: vendorError,
    data: vendorPostData,
  } = addvendor;
  // const ledgerList = useSelector((state) => state?.ledgerList);
  // const { loading: ledgerListLoading, data: expenseList } = ledgerList;

  const [scheduleData, setScheduleData] = useState({
    schedule_type: null,
    quarterly: {
      quarter_month: "", // 1st month of the quarter

      day_of_month: "", // 15th day of the month

      repeat_every: "", // Every quarter
    },
    weekly: {
      days_of_week: [], // 1 = Monday, 5 = Friday

      repeat_every: "", // Every week
    },
    yearly: {
      month_of_year: "", // January

      day_of_month: "", // 15th day of the month

      repeat_every: "", // Every year
    },
    monthly: {
      day_of_month: "", // 15th day of the month

      repeat_every: "", // Every month
    },
  });
  const [editData, setEditData] = useState({
    schedule_type: null,
    quarterly: {
      quarter_month: "", // 1st month of the quarter

      day_of_month: "", // 15th day of the month

      repeat_every: "", // Every quarter
    },
    weekly: {
      days_of_week: [], // 1 = Monday, 5 = Friday

      repeat_every: "", // Every week
    },
    yearly: {
      month_of_year: "", // January

      day_of_month: "", // 15th day of the month

      repeat_every: "", // Every year
    },
    monthly: {
      day_of_month: "", // 15th day of the month

      repeat_every: "", // Every month
    },
  });

  const apRecurringInvoiceDetail = useSelector(
    (state) => state.apRecurringInvoiceDetail
  );
  const {
    recurringEditDetail,
    loading: recurringEditDetailLoading,
  } = apRecurringInvoiceDetail;
  const apRecurringInvoiceUpdation = useSelector(
    (state) => state.apRecurringInvoiceUpdation
  );
  const { loading, success, error } = apRecurringInvoiceUpdation;

  const [messageApi, contextHolder] = message.useMessage();

  const companyList = useSelector((state) => state?.companyList);
  const { company } = companyList;

  const [addLedgerData, setAddLedgerData] = useState({
    title: "",
    group: null,
    description: "",
  });
  const [apiError, setApiError] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  const totals = useCalculateTotals(data);

  useUpdateFooterData(
    footerData,
    setFooterData,
    totals,
    gstSection,
    company,
    vendorDetail
  );

  useEffect(() => {
    dispatch(getCompanyList());
  }, []);

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
    setIsModalVisible(false);
    setAddLedgerData({
      title: "",
      group: null,
      description: "",
    });
    setApiError({});
  };

  const postLedgerList = useSelector((state) => state?.postLedgerList);
  const {
    loading: postLedgerLoading,
    error: postLedgerError,
    success: postLedgerSuccess,
    data: postLedgerData,
  } = postLedgerList;

  useEffect(() => {
    if (postLedgerError) {
      setApiError(postLedgerError);
    }
  }, [postLedgerError]);

  const datePickerRef = {
    start_date: useRef(null),
    end_date: useRef(null),
    invoice_date: useRef(null),
  };
  const selectRefs = useRef({});

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const vendorListSearch = useSelector((state) => state.vendorListSearch);
  const { vendorSearchList, loading: vendorListLoading } = vendorListSearch;

  useEffect(() => {
    if ((page?.page > 1 && vendorSearchList?.next) || page?.page === 1) {
      dispatch(getVendorSearchList("", page?.page, page?.perPage));
    }
  }, [page?.page, page?.perPage]);

  useEffect(() => {
    return () => {
      dispatch({ type: AP_RECURRINGINVOICE_EDIT_RESET });
    };
  }, [dispatch]);

  useEffect(() => {
    if (vendorSearchList?.results) {
      const newVendors = vendorSearchList?.results;

      // Create a Set of the current vendor ids/names for quick look-up
      const existingVendorIds = new Set(
        vendorList?.map((vendor) => vendor?.id)
      ); // Assuming `id` is the unique identifier

      // Filter newVendors to only include those that are not already in vendorList
      const uniqueNewVendors = newVendors?.filter(
        (vendor) => !existingVendorIds?.has(vendor?.id)
      );

      // Combine the previous vendorList with the unique new ones
      const combinedVendorList = [...vendorList, ...uniqueNewVendors];

      // Update state with combined list
      setVendorList(combinedVendorList);
    }
  }, [vendorSearchList]);

  useEffect(() => {
    if (vendorSuccess && vendorPostData) {
      dispatch({ type: VENDOR_ADD_RESET });
      setAddVendorData({
        pan_number: "",
        gstin_number: "",
        name: "",
        vendor_code: "",
        address: "",
        zip_code: "",
        state: "",
        state_code: "",
        phone: "",
        contact_name: "",
        country_code: null,
        email: "",
        msme: "",
        bank_name: "",
        branch_name: "",
        account_number: "",
        ifsc_code: "",
        swift: "",
        ap_invoice_id: "",
      });
      setIsChanged(true);
      setRecurringInvoiceData((prevState) => ({
        ...prevState,
        vendor: vendorPostData ? vendorPostData?.id : null,
      }));
      // setValue("vendor_id", vendorPostData?.id);
      setVendorList([...vendorList, vendorPostData]);
      handleModalClose("#vendor_modal");
      messageApi.open({
        type: "success",
        content: "Vendor created successfully",
        duration: 5,
      });
    }
  }, [vendorSuccess,vendorPostData]);

  const handlePopupScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollHeight - scrollTop <= clientHeight + 10 &&
      !vendorListLoading &&
      vendorSearchList?.results?.length
    ) {
      setPage((prevState) => ({
        ...prevState,
        page: prevState?.page + 1,
        perPage: prevState?.perPage,
      }));
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
    setRecurringInvoiceData((prevState) => ({ ...prevState, [id]: value }));
    setIsChanged(true);
  };

  const handleCheckBox = (e, selectedOption) => {
    const { checked } = e.target;
    setIsChanged(true);
    if (checked) {
      const updatedData = { ...scheduleData };
      updatedData["weekly"] &&
        updatedData["weekly"]["days_of_week"]?.push(selectedOption);
      setScheduleData(updatedData);
      const edit_data = { ...editData };
      edit_data["weekly"] &&
        edit_data["weekly"]["days_of_week"]?.push(selectedOption);
      setEditData(edit_data);
    } else {
      setScheduleData((prevState) => ({
        ...prevState,
        weekly: {
          ...scheduleData?.weekly,
          days_of_week: scheduleData?.weekly?.days_of_week?.filter(
            (el) => el !== selectedOption
          ),
        },
      }));
      setEditData((prevState) => ({
        ...prevState,
        weekly: {
          ...editData?.weekly,
          days_of_week: editData?.weekly?.days_of_week?.filter(
            (el) => el !== selectedOption
          ),
        },
      }));
    }
  };

  const handleRepeatSelectChange = (selectedOption, scheduleType, id) => {
    setScheduleData((prevState) => ({
      ...prevState,
      [scheduleType]: { ...scheduleData[scheduleType], [id]: selectedOption },
    }));
    setEditData((prevState) => ({
      ...prevState,
      [scheduleType]: {
        ...editData[scheduleType],
        [id]: selectedOption?.value,
      },
    }));
    setIsChanged(true);
  };

  const handleItemChange = (e, itemIndex) => {
    const { id, value } = e.target;
    setIsChanged(true);
    const filterData = [...data];
    if (filterData[itemIndex]) {
      filterData[itemIndex][id] = value;
    }
    const newErrors = [...itemsError];
    if (newErrors[itemIndex]) {
      newErrors[itemIndex][id] = "";
    }
    setItemsError(newErrors);
    setData(filterData);
  };

  const handleSelectChange = (id, selectedOption) => {
    setIsChanged(true);
    setEditData((prevState) => ({ ...prevState, [id]: selectedOption }));
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleDateChange = (id, selectedDate) => {
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
    setEditData((prevState) => ({ ...prevState, [id]: selectedDate }));
    setIsChanged(true);
  };

  const formattedOptions = (options) =>
    options.map((option) => ({
      value: option.id,
      label: option.text,
    }));

  const parseDate = (dateString) => {
    return dateString ? new Date(dateString) : null;
  };

  const {
    handleSubmit,
    control,
    reset,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: recurringInvoiceData,
  });

  const editLedgerSubmit = (event) => {
    event.preventDefault();
    if (isModalVisible) {
      const postLedgerData = {
        title: addLedgerData?.title,
        ledger_group: addLedgerData?.group ? addLedgerData?.group?.value : null,
        description: addLedgerData?.description,
      };
      dispatch(postLedgerModuleList(postLedgerData));
    }
  };

  const editSubmit = (datas) => {
    const ledger = data?.map((ele) => ({
      ...ele,
      ledger:
        ele.ledger && typeof ele.ledger === "object"
          ? ele.ledger.value
          : ele.ledger,
      taxes: [],
    }));

    const postData = {
      invoice: {
        vendor: datas?.vendor,
        start_date: datas?.start_date
          ? formatDateYYMMDD(datas?.start_date)
          : "",
        end_date: datas?.end_date ? formatDateYYMMDD(datas?.end_date) : "",
        description: recurringInvoiceData?.description,
        items: ledger,
      },
      schedule: {
        schedule_type: scheduleData?.schedule_type
          ? scheduleData?.schedule_type?.value
          : "",
      },
    };

    if (editData?.schedule_type) {
      if (editData?.schedule_type === "monthly") {
        if (editData?.dayOfMonth) {
          postData["schedule"]["monthly"] = {
            day_of_month: editData["monthly"]["day_of_month"],
            repeat_every: editData["monthly"]["repeat_every"],
          };
        } else if (editData?.nthWeekday) {
          postData["schedule"]["monthly"] = {
            nth_day_of_week: editData["monthly"]["nth_day_of_week"],
            repeat_every: editData["monthly"]["repeat_every"],
            day_of_week: editData["monthly"]["day_of_week"],
          };
        }
      } else {
        postData["schedule"][editData?.schedule_type] =
          editData[editData?.schedule_type];
      }
    }

    dispatch(updateApRecurringInvoice(postData, id));
    setIsChanged(false);
  };

  useEffect(() => {
    if (!vendorDetail?.vendor_gstin && editData?.vendor) {
      const updatedData = data.map((item) => ({
        ...item,
        gst_rate: 0,
        gst_amount: 0,
      }));
      setData(updatedData);
      setFooterData((prevState) => ({
        ...prevState,
        gst_section: {},
        total_tax: 0,
      }));
    }
  }, [vendorDetail?.vendor_gstin, editData?.vendor]);

  useEffect(() => {
    if (recurringInvoiceData) {
      Object.keys(recurringInvoiceData).forEach((field) => {
        setValue(field, recurringInvoiceData[field]);
      });
      if (recurringInvoiceData?.invoice_date) {
        clearErrors("invoice_date");
      }
      if (recurringInvoiceData?.start_date) {
        clearErrors("start_date");
      }
      if (recurringInvoiceData?.end_date) {
        clearErrors("end_date");
      }
    }
  }, [recurringInvoiceData, setValue]);

  useEffect(() => {
    if (postLedgerSuccess && postLedgerData) {
      dispatch({ type: POST_LEDGERLIST_RESET });
      const updatedData = [...data];
      updatedData[ledgerIndexData]["ledger"] = postLedgerData?.id;
      setData(updatedData);
      setIsChanged(true);
      dispatch(getLedgerModuleListByGroup());
      handleModalClose("#add_ledger");
      setAddLedgerData({ title: "", group: null, description: "" });
      setApiError({});
      setIsModalVisible(false);
      messageApi.open({
        type: "success",
        content: "Ledger Created Successfully",
        duration: 5,
      });
    }
  }, [postLedgerSuccess, ledgerIndexData, postLedgerData]);

  useEffect(() => {
    if (error?.invoice) {
      setShowErrors(error?.invoice?.items ? true : false);
      setItemsError(error?.invoice?.items ? error.invoice.items : null);
    } else {
      setEditError(error);
    }
  }, [error]);

  useEffect(() => {
    const fieldOrder = ["vendor", "start_date", "end_date", "description"];
    const firstErrorField = fieldOrder.find((field) => errors[field]);
    if (firstErrorField) {
      let inputElement;
      if (firstErrorField === "vendor") {
        document.getElementById("vendor").focus();
      } else {
        inputElement = document.getElementById(firstErrorField);
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [errors]);

  useEffect(() => {
    const fieldOrder = ["vendor", "start_date", "end_date", "description"];
    const firstErrorField = fieldOrder.find((field) => error && error[field]);
    if (firstErrorField) {
      let inputElement;
      if (firstErrorField === "vendor") {
        inputElement = document.getElementById("vendor");
      } else {
        inputElement = document.getElementById(firstErrorField);
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [error]);

  useEffect(() => {
    let elements = Array.from(
      document.getElementsByClassName("react-datepicker-wrapper")
    );
    elements.map((element) => element.classList.add("w-100"));
  }, []);

  useEffect(() => {
    dispatch(apRecurringEditInvoiceDetail(id));
  }, [id]);

  useEffect(() => {
    if (recurringEditDetail) {
      const filteredScheduleType = scheduleTypes?.filter(
        (el) => el?.value === recurringEditDetail?.schedule_type
      );
      setRecurringInvoiceData({
        ...recurringEditDetail,
        start_date: parseDate(recurringEditDetail?.start_date)
          ? parseDate(recurringEditDetail?.start_date)
          : "",
        end_date: parseDate(recurringEditDetail?.end_date)
          ? parseDate(recurringEditDetail?.end_date)
          : "",
        description: recurringEditDetail?.description
          ? recurringEditDetail?.description
          : "",
        vendor: recurringEditDetail?.vendor
          ? recurringEditDetail?.vendor?.id
          : "",
      });

      setVendorDetail({
        vendor_code: recurringEditDetail?.vendor?.vendor_code,
        vendor_gstin: recurringEditDetail?.vendor?.gstin_number,
      });
      setFooterData(recurringEditDetail);
      setGstSection(
        recurringEditDetail?.gst_section
          ? Object.keys(recurringEditDetail?.gst_section)
          : []
      );
      setEditData((prevState) => ({
        ...prevState,
        schedule_type:
          filteredScheduleType?.length !== 0
            ? filteredScheduleType[0]?.value
            : null,
        vendor: recurringEditDetail?.vendor
          ? recurringEditDetail?.vendor?.id
          : "",
        dayOfMonth:
          recurringEditDetail?.schedule?.day_of_month &&
          recurringEditDetail?.schedule_type === "monthly"
            ? true
            : false,
        nthWeekday:
          recurringEditDetail?.schedule &&
          recurringEditDetail?.schedule?.nth_day_of_week &&
          recurringEditDetail?.schedule_type === "monthly"
            ? true
            : false,
        [recurringEditDetail?.schedule_type]: {
          ...recurringEditDetail?.schedule,
          day_of_month: recurringEditDetail?.schedule?.day_of_month
            ? recurringEditDetail?.schedule?.day_of_month
            : null,
          repeat_every: recurringEditDetail?.schedule?.repeat_every
            ? recurringEditDetail?.schedule?.repeat_every
            : null,
          nth_day_of_week: recurringEditDetail?.schedule?.nth_day_of_week
            ? recurringEditDetail?.schedule?.nth_day_of_week
            : null,
          day_of_week: recurringEditDetail?.schedule?.day_of_week
            ? recurringEditDetail?.schedule?.day_of_week
            : null,
          month_of_quarter: recurringEditDetail?.schedule?.month_of_quarter
            ? recurringEditDetail?.schedule?.month_of_quarter
            : null,
          month_of_year: recurringEditDetail?.schedule?.month_of_year
            ? recurringEditDetail?.schedule?.month_of_year
            : null,
          month_of_year: recurringEditDetail?.schedule?.month_of_year
            ? recurringEditDetail?.schedule?.month_of_year
            : null,
        },
      }));
      setScheduleData((prevState) => ({
        ...prevState,
        schedule_type:
          filteredScheduleType?.length !== 0
            ? {
                label: filteredScheduleType[0]?.label,
                value: filteredScheduleType[0]?.value,
              }
            : null,
        dayOfMonth:
          recurringEditDetail?.schedule?.day_of_month &&
          recurringEditDetail?.schedule_type === "monthly"
            ? true
            : false,
        nthWeekday:
          recurringEditDetail?.schedule &&
          recurringEditDetail?.schedule?.nth_day_of_week &&
          recurringEditDetail?.schedule_type === "monthly"
            ? true
            : false,
        [recurringEditDetail?.schedule_type]: {
          ...recurringEditDetail?.schedule,
          day_of_month: recurringEditDetail?.schedule?.day_of_month
            ? {
                label: recurringEditDetail?.schedule?.day_of_month,
                value: recurringEditDetail?.schedule?.day_of_month,
              }
            : null,
          repeat_every: recurringEditDetail?.schedule?.repeat_every
            ? {
                label: recurringEditDetail?.schedule?.repeat_every,
                value: recurringEditDetail?.schedule?.repeat_every,
              }
            : null,
          nth_day_of_week: recurringEditDetail?.schedule?.nth_day_of_week
            ? {
                label: recurringEditDetail?.schedule?.nth_day_of_week,
                value: recurringEditDetail?.schedule?.nth_day_of_week,
              }
            : null,
          day_of_week: recurringEditDetail?.schedule?.day_of_week
            ? {
                label: recurringEditDetail?.schedule?.day_of_week,
                value: recurringEditDetail?.schedule?.day_of_week,
              }
            : null,
          month_of_quarter: recurringEditDetail?.schedule?.month_of_quarter
            ? {
                label: recurringEditDetail?.schedule?.month_of_quarter,
                value: recurringEditDetail?.schedule?.month_of_quarter,
              }
            : null,
          month_of_year: recurringEditDetail?.schedule?.month_of_year
            ? {
                label: recurringEditDetail?.schedule?.month_of_year,
                value: recurringEditDetail?.schedule?.month_of_year,
              }
            : null,
        },
      }));
    }
    const ledger =
      recurringEditDetail?.items?.length &&
      recurringEditDetail?.items?.map((ele) => ({
        ...ele,
        ledger: { value: ele?.ledger?.id, label: ele?.ledger?.title },
      }));

    const postingLedgerData = recurringEditDetail?.items?.map(
      (el) => el?.ledger
    );

    postingLedgerData?.forEach((el) => {
      // Check if the object is already in ledgerListData
      const exists = ledgerListData.some((ledger) => ledger?.id === el?.id);

      // Only push if the object doesn't exist
      if (!exists) {
        ledgerListData.push(el);
      }
    });

    setData(recurringEditDetail?.items?.length ? ledger : initialLedgerData);
  }, [recurringEditDetail]);

  useEffect(() => {
    if (recurringEditDetail?.vendor && vendorList?.length) {
      const vendorExists = vendorList?.some(
        (detail) => detail?.id === recurringEditDetail?.vendor?.id
      );
      if (!vendorExists) {
        setVendorList([recurringEditDetail?.vendor, ...vendorList]);
      }
    }
  }, [recurringEditDetail?.vendor, vendorList]);

  useEffect(() => {
    // if ((ledgerPage?.page > 1 && expenseList?.next) || ledgerPage?.page === 1)
    //   dispatch(getLedgerModuleList("", ledgerPage?.page, ledgerPage?.perPage));

    dispatch(getLedgerModuleListByGroup());
  }, []);

  useEffect(() => {
    if (ledger?.length) {
      setLedgerData(ledger);
    }
  }, [ledger]);

  useEffect(() => {
    return () => {
      // setLedgerData([]);
      dispatch({ type: LEDGERLIST_INVOICE_RESET });
    };
  }, []);

  // useEffect(() => {
  //   if (expenseList?.results) {
  //     const newLedgers = expenseList.results;
  //     const existingLedgerIds = new Set(
  //       (ledgerListData || []).filter(Boolean).map((ledger) => ledger.id)
  //     );
  //     const uniqueNewLedgers = newLedgers.filter(
  //       (ledger) => ledger && !existingLedgerIds.has(ledger.id)
  //     );
  //     setLedgerData((prevList) => [...(prevList || []), ...uniqueNewLedgers]);
  //   }
  // }, [expenseList]);

  // const handleLedgerPopupScroll = (e) => {
  //   const { scrollTop, scrollHeight, clientHeight } = e.target;

  //   if (
  //     scrollHeight - scrollTop <= clientHeight + 10 &&
  //     !ledgerListLoading &&
  //     expenseList?.results?.length
  //   ) {
  //     setLedgerPage((prevState) => ({
  //       ...prevState,
  //       page: prevState?.page + 1,
  //       perPage: prevState?.perPage,
  //     }));
  //   }
  // };

  useEffect(() => {
    if (editData?.vendor) {
      const vendorDetails = vendorList?.find(
        (el) => el?.id === editData?.vendor
      );
      if (vendorDetails) {
        setVendorDetail({
          vendor_code: vendorDetails?.vendor_code,
          vendor_gstin: vendorDetails?.gstin_number,
        });
      }
    }
  }, [vendorList, editData?.vendor]);

  useEffect(() => {
    if (success) {
      dispatch({ type: AP_RECURRINGINVOICE_EDIT_RESET });
      dispatch(apRecurringEditInvoiceDetail(id));
      messageApi.open({
        type: "success",
        content: "Recurring Invoice updated successfully",
        duration: 5,
      });
    }
  }, [success]);

  const handleDoubleClick = (rowId, field) => {
    setEditable({ rowId, field });
  };

  const handleCellChange = (rowId, field, value, index) => {
    const updatedData = [...data];
    updatedData[index][field] = value;
    setData(updatedData);
    setCalculationData((prevState) => ({
      ...prevState,
      indexId: index,
      key: field,
    }));
    setIsChanged(true);
  };

  useEffect(() => {
    if (
      calculationData?.indexId !== "" &&
      calculationData?.key !== "gst_amount" &&
      calculationData?.key !== "amount" &&
      calculationData?.key !== "total"
    ) {
      const updatedData = [...data];
      if (updatedData[calculationData?.indexId]) {
        const rate =
          parseFloat(updatedData[calculationData?.indexId]["rate"]) || 0;
        const quantity =
          parseFloat(updatedData[calculationData?.indexId]["quantity"]) || 0;
        const gstRate =
          parseFloat(updatedData[calculationData?.indexId]["gst_rate"]) || 0;
        const amount = rate * quantity;

        updatedData[calculationData?.indexId]["amount"] = amount.toFixed(2);

        const gstAmount = (amount * gstRate) / 100;
        updatedData[calculationData?.indexId]["gst_amount"] = gstAmount.toFixed(
          2
        );

        const total = amount + gstAmount;
        updatedData[calculationData?.indexId]["total"] = total.toFixed(2);
      }
      setData(updatedData);
    }
  }, [calculationData]);

  const handleSelectCellChange = (selectedOption, id, rowKey, index) => {
    const updatedData = [...data];
    updatedData[index][id] = selectedOption;
    if (id === "tds_ledger") {
      setData(updatedData);
      const data = {
        tds_section_id: selectedOption?.value,
        item_id: rowKey,
      };

      dispatch(tdsCalculationApi(data));
    }
    setIsChanged(true);
    setData(updatedData);
  };

  useEffect(() => {
    return () => {
      dispatch({ type: VENDOR_SEARCH_RESET });
    };
  }, []);

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        {contextHolder}
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        {/* <!-- Page Wrapper --> */}
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="card mb-0">
              <div className="card-body">
                <div className="page-header">
                  <div className="content-page-header">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a>AP module</a>
                      </li>
                      <li className="breadcrumb-item active">
                        <Link to="/apRecurring-invoice">
                          Recurring Invoice List
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Edit Recurring Invoice
                      </li>
                    </ul>
                  </div>
                </div>
                {recurringEditDetailLoading ? (
                  <Spin />
                ) : (
                  <div className="row">
                    <AddEditApInvoiceRecurringModal
                      handleSubmit={handleSubmit}
                      recurringInvoiceSubmit={editSubmit}
                      control={control}
                      handleChange={handleChange}
                      errors={errors}
                      error={error}
                      showErrors={showErrors}
                      setShowErrors={setShowErrors}
                      addError={editError}
                      handleDateChange={handleDateChange}
                      datePickerRef={datePickerRef}
                      recurringInvoiceData={recurringInvoiceData}
                      selectRefs={selectRefs}
                      handleSelectChange={handleSelectChange}
                      vendorDetail={vendorDetail}
                      scheduleData={scheduleData}
                      setScheduleData={setScheduleData}
                      handleCheckBox={handleCheckBox}
                      formattedOptions={formattedOptions}
                      handleItemChange={handleItemChange}
                      data={data}
                      setData={setData}
                      itemsError={itemsError}
                      setIsChanged={setIsChanged}
                      isChanged={isChanged}
                      loading={loading}
                      handleRepeatSelectChange={handleRepeatSelectChange}
                      setEditData={setEditData}
                      editModal={true}
                      editable={editable}
                      setEditable={setEditable}
                      handleDoubleClick={handleDoubleClick}
                      handleCellChange={handleCellChange}
                      expenseList={ledger}
                      handleSelectCellChange={handleSelectCellChange}
                      setAddLedgerData={setAddLedgerData}
                      addLedgerData={addLedgerData}
                      apiError={apiError}
                      setApiError={setApiError}
                      setIsModalVisible={setIsModalVisible}
                      isModalVisible={isModalVisible}
                      postLedgerLoading={postLedgerLoading}
                      handleModalClose={handleModalClose}
                      setFooterData={setFooterData}
                      footerData={footerData}
                      addLedgerSubmit={editLedgerSubmit}
                      handlePopupScroll={handlePopupScroll}
                      vendorList={vendorList}
                      vendorListLoading={vendorListLoading}
                      moduleName="apModule"
                      // handleLedgerPopupScroll={handleLedgerPopupScroll}
                      // ledgerListLoading={ledgerListLoading}
                      ledgerListData={ledgerListData}
                      setLedgerIndexData={setLedgerIndexData}
                      setAddVendorData={setAddVendorData}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <VendorModal
          id="vendor_modal"
          title="Add Vendor"
          addData={addVendorData}
          setAddData={setAddVendorData}
          setApiError={setApiVendorError}
          apiError={apiVendorError}
          setFile={setVendorFile}
          file={vendorFile}
          handleModalClose={handleModalClose}
        />
      </div>
    </>
  );
};

export default EditApInvoiceRecurring;
