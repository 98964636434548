/* eslint-disable */
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Spin, Select as AntdSelect } from "antd";
import React from "react";
import CustomInput from "../_components/inputField/custominput";
import PropTypes from "prop-types";
import { transactionTypesList } from "./constant";
import CustomSelect from "../customers/customSelect";

const TransactionAddEditModal = ({
  addData,
  datePickerRef,
  selectRefs,
  handleChange,
  handleDateChange,
  handleCommonChange,
  handleSelectChange,
  editError,
  vendorList,
  vendorListLoading,
  handleVendorPopupScroll,
  customer,
  ledgerListData,
  ledgerListLoading,
  handleLedgerPopupScroll,
  addLedgerData,
  setAddLedgerData,
  isModalVisible,
  setIsModalVisible,
  apiError,
}) => {
  return (
    <div className="col-md-12">
      <div className="form-group-item border-0 mb-0">
        <div className="row align-item-center">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="input-block mb-3">
              <label>
                Date<span className="text-danger">*</span>
              </label>

              <div className="cal-icon cal-icon-info">
                <DatePicker
                  className="datetimepicker form-control"
                  selected={addData?.date}
                  onChange={(date) => handleDateChange("date", date)}
                  id="date"
                  ref={datePickerRef}
                  required
                />
              </div>

              {editError?.date && (
                <p className="text-danger">{editError?.date}</p>
              )}
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="input-block mb-3">
              <CustomInput
                id="amount"
                type="number"
                value={addData?.amount}
                onChange={handleCommonChange}
                placeholder="Amount"
                label="Amount"
                apiError={editError?.amount}
                required={true}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="input-block mb-3">
              <CustomInput
                id="description"
                type="text"
                value={addData?.description}
                onChange={handleCommonChange}
                placeholder="Description"
                label="Description"
                apiError={editError?.description}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="input-block mb-3">
              <label>Transaction Type</label>

              <Select
                id="transaction_type"
                ref={(el) => (selectRefs.current["transaction_type"] = el)}
                value={addData?.transaction_type}
                options={transactionTypesList}
                onChange={(selectedOption) =>
                  handleSelectChange("transaction_type", selectedOption)
                }
              />

              {editError?.transaction_type && (
                <p className="text-danger">{editError?.transaction_type}</p>
              )}
            </div>
          </div>
          {addData?.transaction_type?.value === "vendor_payment" && (
            <>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <label>
                    Vendor<span className="text-danger">*</span>
                  </label>
                  <>
                    <CustomSelect
                      id="vendor_id"
                      options={vendorList?.length ? vendorList : []}
                      value={addData?.vendor}
                      onPopupScroll={handleVendorPopupScroll}
                      onChange={(selectedOption) => {
                        handleSelectChange("vendor", selectedOption); // Your custom handler if needed
                      }}
                    />
                    {/* {errors?.vendor_id?.message && (
                          <p className="text-danger">
                            {errors?.vendor_id?.message}
                          </p>
                        )} */}
                    {/* {editError?.vendor_id && (
                          <p className="text-danger">{editError?.vendor_id}</p>
                        )} */}
                  </>
                  {/* )}
                  /> */}
                  {/* <AntdSelect
                    id="vendor"
                    required
                    showSearch
                    ref={(el) => (selectRefs.current["vendor"] = el)}
                    value={addData?.vendor}
                    style={{ width: "100%" }}
                    onChange={(selectedOption) =>
                      handleSelectChange("vendor", selectedOption)
                    }
                    filterOption={(input, option) =>
                      option?.children
                        .toLowerCase()
                        .includes(input?.toLowerCase())
                    }
                    onPopupScroll={handleVendorPopupScroll}
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        {vendorListLoading && (
                          <div
                            style={{
                              textAlign: "center",
                              padding: 8,
                            }}
                          >
                            <Spin />
                          </div>
                        )}
                      </>
                    )}
                  >
                    {vendorList
                      ?.map((option) => ({
                        value: option.id,
                        label: option.name,
                      }))
                      .map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                  </AntdSelect> */}

                  {editError?.vendor && (
                    <p className="text-danger">{editError?.vendor}</p>
                  )}
                </div>
              </div>
            </>
          )}
          {addData?.transaction_type?.value === "customer_payment" && (
            <>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <label>
                    Customer<span className="text-danger">*</span>
                  </label>

                  <Select
                    id="customer"
                    ref={(el) => (selectRefs.current["customer"] = el)}
                    value={addData?.customer}
                    required
                    options={customer?.map((el) => ({
                      label: el?.name,
                      value: el?.id,
                    }))}
                    onChange={(selectedOption) =>
                      handleSelectChange("customer", selectedOption)
                    }
                  />

                  {editError?.customer && (
                    <p className="text-danger">{editError?.customer}</p>
                  )}
                </div>
              </div>
            </>
          )}
          {addData?.transaction_type?.value === "expense" && (
            <>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <label>
                    Ledger<span className="text-danger">*</span>
                  </label>
                  <CustomSelect
                    id={"ledger"}
                    value={addData?.ledger}
                    onChange={(selectedOption) => {
                      handleSelectChange("ledger", selectedOption);
                    }}
                    options={ledgerListData?.length ? ledgerListData : []}
                    addLedgerData={addLedgerData}
                    setAddLedgerData={setAddLedgerData}
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    apiError={apiError}
                    onPopupScroll={handleLedgerPopupScroll}
                    ledgerListLoading={ledgerListLoading}
                  />
                  {/* <AntdSelect
                    id="ledger"
                    ref={(el) => (selectRefs.current["ledger"] = el)}
                    showSearch
                    placeholder="Select a ledger"
                    filterOption={(input, option) =>
                      option?.children
                        .toLowerCase()
                        .includes(input?.toLowerCase())
                    }
                    style={{ width: "100%" }}
                    onPopupScroll={handleLedgerPopupScroll}
                    value={addData?.ledger} // Ensure the value prop is set to field.value
                    onChange={(value) => {
                      handleSelectChange("ledger", value); // Your custom handler if needed
                    }}
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        {ledgerListLoading && (
                          <div
                            style={{
                              textAlign: "center",
                              padding: 8,
                            }}
                          >
                            <Spin />
                          </div>
                        )}
                      </>
                    )}
                  >
                    {ledgerListData.map((option) => (
                      <Option key={option.id} value={option.id}>
                        {option.title}
                      </Option>
                    ))}
                  </AntdSelect> */}

                  {editError?.ledger && (
                    <p className="text-danger">{editError?.ledger}</p>
                  )}
                </div>
              </div>
            </>
          )}
          {(addData?.transaction_type?.value === "customer_payment" ||
            addData?.transaction_type?.value === "other_income") && (
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="input-block mb-3">
                <CustomInput
                  id="deposit"
                  type="number"
                  value={addData?.deposit}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Deposit"
                  label="Deposit"
                  apiError={editError?.deposit || editError?.message}
                  required={true}
                />
              </div>
            </div>
          )}
          {(addData?.transaction_type?.value === "expense" ||
            addData?.transaction_type?.value === "vendor_payment") && (
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="input-block mb-3">
                <CustomInput
                  id="withdraw"
                  type="number"
                  value={addData?.withdraw}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="WithDraw"
                  label="WithDraw"
                  apiError={editError?.withdraw || editError?.message}
                  required={true}
                />
              </div>
            </div>
          )}
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="input-block mb-3">
              <CustomInput
                id="ref_number"
                type="text"
                value={addData?.ref_number}
                onChange={handleCommonChange}
                placeholder="Ref Number"
                label="Ref Number"
                apiError={editError?.ref_number}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TransactionAddEditModal;

TransactionAddEditModal.propTypes = {
  addData: PropTypes.object,
  datePickerRef: PropTypes.object,
  selectRefs: PropTypes?.object,
  handleChange: PropTypes.func,
  handleDateChange: PropTypes.func,
  handleCommonChange: PropTypes.func,
  handleSelectChange: PropTypes.func,
  editError: PropTypes.object,
  vendorList: PropTypes.array,
  vendorListLoading: PropTypes.bool,
  handleVendorPopupScroll: PropTypes.func,
  customer: PropTypes.array,
  ledgerListData: PropTypes.array,
  ledgerListLoading: PropTypes.bool,
  handleLedgerPopupScroll: PropTypes.func,
  addLedgerData: PropTypes.object,
  setAddLedgerData: PropTypes.func,
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
  apiError: PropTypes.object,
};