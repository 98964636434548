/* eslint-disable */
import React, { useMemo } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Divider, Select, Space, Spin, Tooltip } from "antd";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const CustomSelect = ({
  id,
  value,
  onChange,
  options,
  suggestedEntry,
  row,
  onPopupScroll,
  loading,
  index,
  setLedgerIndexData,
}) => {
  // const handleModalOpen = () => {
  // setActiveModal(true);
  // setIsModalVisible(true);
  // setApiError({});
  // setAddLedgerData({ title: "", type: "", description: "" });
  // };

  const handleClick = (event) => {
    event.preventDefault();
    if (id !== "customer") {
      setLedgerIndexData(index);
    }
  };

  const { regularOptions, suggestedOption } = useMemo(() => {
    let suggestedOpt = null;
    if (
      suggestedEntry &&
      row?.suggested_posting_ledger &&
      Object.keys(row.suggested_posting_ledger).length > 0
    ) {
      suggestedOpt = {
        value: row.suggested_posting_ledger.id,
        label: (
          <Tooltip title="Suggested Entry">
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginRight: "8px" }}>🌟</span>
              {row.suggested_posting_ledger.title}
            </div>
          </Tooltip>
        ),
        searchLabel: row.suggested_posting_ledger.title.toLowerCase(),
      };
    }

    const regularOpts =
      (options &&
        options
          ?.filter((option) => option?.id !== suggestedOpt?.value)
          .map((option) => ({
            label: option?.title || option?.name, // Using name for customer options
            searchLabel: (option?.title || option?.name)?.toLowerCase(),
            value: option?.id,
          }))) ||
      [];

    return { regularOptions: regularOpts, suggestedOption: suggestedOpt };
  }, [options, suggestedEntry, row]);

  const allOptions = useMemo(() => {
    if (suggestedOption) {
      return [
        {
          label: (
            <div style={{ fontWeight: "bold", padding: "8px 0" }}>
              Suggested Entry
            </div>
          ),
          options: [suggestedOption],
        },
        {
          label: "Other Options",
          options: regularOptions,
        },
      ];
    }
    return regularOptions;
  }, [regularOptions, suggestedOption]);

  return (
    <div>
      <Select
        style={{ width: 300 }}
        value={value}
        showSearch={true}
        onChange={onChange}
        id={id}
        placeholder="Select.."
        optionFilterProp="searchLabel"
        filterOption={(input, option) =>
          option.searchLabel.indexOf(input.toLowerCase()) >= 0
        }
        onPopupScroll={onPopupScroll}
        dropdownRender={(menu) => (
          <>
            {menu}
            {loading ? (
              <Spin />
            ) : (
              <>
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <Link
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    // to="#customer_modal"
                    to={
                      id === "customer"
                        ? "#customer_modal"
                        : id === "vendor_id"
                        ? "#vendor_modal"
                        : "#add_ledger"
                    }                    role="button"
                    onClick={(event) => {
                      if (id !== "customer" && index !== undefined) {
                        handleClick(event);
                      }
                    }}
                  >
                    <span
                      style={{ display: "flex", fontSize: "12px" }}
                      // onClick={handleModalOpen}
                    >
                      <PlusOutlined /> Add item
                    </span>
                  </Link>
                </Space>
              </>
            )}
          </>
        )}
        options={allOptions}
      />
    </div>
  );
};

CustomSelect.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  setAddLedgerData: PropTypes.func.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  setApiError: PropTypes.func.isRequired,
  suggestedEntry: PropTypes.bool,
  row: PropTypes.object,
  onPopupScroll: PropTypes.func,
  loading: PropTypes.func,
  index: PropTypes.number,
  setLedgerIndexData: PropTypes.func,
};

export default CustomSelect;
