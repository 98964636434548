// import axios from "axios";
import { HTTP } from "../api";
import {
  GET_GROUPLIST_FAIL,
  GET_GROUPLIST_REQUSET,
  GET_GROUPLIST_SUCCESS,
  GET_LEDGERLIST_FAIL,
  GET_LEDGERLIST_REQUSET,
  GET_LEDGERLIST_SUCCESS,
  POST_LEDGERLIST_FAIL,
  POST_LEDGERLIST_REQUSET,
  POST_LEDGERLIST_SUCCESS,
  GROUP_CREATE_REQUEST,
  GROUP_CREATE_SUCCESS,
  GROUP_CREATE_FAIL,
  UPDATE_LEDGERLIST_FAIL,
  UPDATE_LEDGERLIST_SUCCESS,
  UPDATE_LEDGERLIST_REQUSET,
  GET_LEDGER_GROUP_REQUSET,
  GET_LEDGER_GROUP_SUCCESS,
  GET_LEDGER_GROUP_FAIL,
  GROUP_UPDATE_REQUEST,
  GROUP_UPDATE_SUCCESS,
  GROUP_UPDATE_FAIL,
  GROUP_DETAIL_REQUEST,
  GROUP_DETAIL_SUCCESS,
  GROUP_DETAIL_FAIL,
  LEDGER_DETAIL_REQUEST,
  LEDGER_DETAIL_SUCCESS,
  LEDGER_DETAIL_FAIL,
  GET_LEDGER_LIST_REQUSET,
  GET_LEDGER_LIST_SUCCESS,
  GET_LEDGER_LIST_FAIL,
  GET_LEDGERLIST_BYGROUP_REQUSET,
  GET_LEDGERLIST_BYGROUP_SUCCESS,
  GET_LEDGERLIST_BYGROUP_FAIL,
} from "../constants/ledgerListModuleConstant";

export const getLedgerModuleList = (searchValue,page,per_page,income = false) => async (dispatch, getState) => {
  dispatch({ type: GET_LEDGERLIST_REQUSET });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const incomeQuery = income !== undefined ? `&income=${income}` : "";
    const { data } = await HTTP.get(
      `/ledger/ledger-account/?search=${searchValue}&page=${page}&per_page=${per_page}${incomeQuery}`,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({
      type: GET_LEDGERLIST_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    dispatch({
      type: GET_LEDGERLIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const getLedgerModuleListByGroup = () => async (dispatch) => {
  dispatch({ type: GET_LEDGERLIST_BYGROUP_REQUSET });
  try {
    const { data } = await HTTP.get(
      `ledger/ledger-account/by-groups/`,
    );
    dispatch({
      type: GET_LEDGERLIST_BYGROUP_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    dispatch({
      type: GET_LEDGERLIST_BYGROUP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const postLedgerModuleList = (payload) => async (dispatch, getState) => {
  dispatch({ type: POST_LEDGERLIST_REQUSET, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.post(
      `/ledger/ledger-account/`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({
      type: POST_LEDGERLIST_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    dispatch({
      type: POST_LEDGERLIST_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const updateLedgerModuleList = (payload, id) => async (
  dispatch,
  getState
) => {
  dispatch({ type: UPDATE_LEDGERLIST_REQUSET, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.patch(
      `/ledger/ledger-account/${id}/`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({
      type: UPDATE_LEDGERLIST_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    dispatch({
      type: UPDATE_LEDGERLIST_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const groupCreationApi = (payload) => async (dispatch, getState) => {
  dispatch({ type: GROUP_CREATE_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.post(
      `/ledger/ledger-group/`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({
      type: GROUP_CREATE_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    dispatch({
      type: GROUP_CREATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const groupUpdationApi = (payload, id) => async (dispatch, getState) => {
  dispatch({ type: GROUP_UPDATE_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.patch(
      `/ledger/ledger-group/${id}/`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({
      type: GROUP_UPDATE_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    dispatch({
      type: GROUP_UPDATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const groupDetailApi = (id) => async (dispatch, getState) => {
  dispatch({ type: GROUP_DETAIL_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get(
      `/ledger/ledger-group/${id}/`,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({
      type: GROUP_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GROUP_DETAIL_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const getLedgerGroupList = (page, perPage) => async (
  dispatch,
  getState
) => {
  dispatch({ type: GET_LEDGER_GROUP_REQUSET });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get(
      `/ledger/ledger-group/?page=${page}&per_page=${perPage}`,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({
      type: GET_LEDGER_GROUP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_LEDGER_GROUP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getGroupList = () => async (dispatch, getState) => {
  dispatch({ type: GET_GROUPLIST_REQUSET });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get(
      "/ledger/ledger-group/",
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({ type: GET_GROUPLIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_GROUPLIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const ledgerDetailApi = (id) => async (dispatch, getState) => {
  dispatch({ type: LEDGER_DETAIL_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get(
      `/ledger/ledger-account/${id}/`,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({
      type: LEDGER_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LEDGER_DETAIL_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const getLedgerListApi = (page, perPage) => async (
  dispatch,
  getState
) => {
  dispatch({ type: GET_LEDGER_LIST_REQUSET });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get(
      `/ledger/ledger-account/?page=${page}&per_page=${perPage}`,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({
      type: GET_LEDGER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_LEDGER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
