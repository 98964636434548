import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { InvoiceLogo1 } from '../_components/Imagepath';
import { HTTP } from '../redux/api';
import { message as antdMessage, Spin } from 'antd';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [messageApi, contextHolder] = antdMessage.useMessage();
  const [loading, setLoading] = useState(false)

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true)


    try {
      const response = await HTTP.post('/users/password-reset-link/', { email });
      const messageResponse = response.data.detail;
      messageApi.open({
        type: "success",
        content: messageResponse,
        duration: 8,
      });
      setLoading(false)
      setEmail('')

    } catch (error) {
      console.log(error.response)
      messageApi.open({
        type: "error",
        content: "Failed to send reset link. Please try again later.",
        duration: 5,
      });
      setLoading(false)

    }
  };

  return (
    <div className="main-wrapper login-body">
      <div className="login-wrapper">
        <div className="container">
          <img className="img-fluid logo-dark mb-2" src={InvoiceLogo1} alt="Logo" />
          <div className="loginbox">
            <div className="login-right">
              <div className="login-right-wrap">
                <h1>Forgot Password?</h1>
                <p className="account-subtitle">Enter your email to get a password reset link</p>
                
                {/* Form */}
                <form onSubmit={handleForgotPassword}>
                  <div className="input-block mb-3">
                    <label className="form-control-label">Email Address</label>
                    <input
                      className="form-control"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="input-block mb-0">
                    <button className="btn btn-lg btn-block w-100 btn-primary" type="submit">
                    {loading ? <Spin/> : 'Send reset password link'}  
                    </button>
                  </div>
                </form>
                {/* /Form */}

                {contextHolder}

                <div className="text-center dont-have">
                  Remember your password? <Link to="/">Login</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
