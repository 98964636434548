/* eslint-disable */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";

import "react-phone-input-2/lib/style.css";
import {
  apEditInvoiceDetail,
  getTdsRateList,
  updateApInvoice,
} from "../redux/actions/APmoduleAction";
import { useSelector } from "react-redux";
import { AP_INVOICE_EDIT_RESET } from "../redux/constants/APmoduleConstant";
import { message, Select, Spin } from "antd";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Controller, useForm } from "react-hook-form";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  getLedgerModuleList,
  getLedgerModuleListByGroup,
  postLedgerModuleList,
} from "../redux/actions/LedgerModuleAction";
import {
  getCompanyList,
  getTdsSectionList,
} from "../redux/actions/commonApiAction";
import CustomInput from "../_components/inputField/custominput";
import { formatDateYYMMDD } from "../sales/invoices/constant";
import TableEditInvoice from "./tableEditInvoice";
import useUpdateFooterData from "./updateFooterData";
import useCalculateTotals from "../helpers/calculation";
import ImagePreview from "./imagepreview";
import InvoiceErrorDisplay from "../components/CustomError";
import { LEDGERLIST_INVOICE_RESET } from "../redux/constants/ledgerListModuleConstant";
import { getVendorSearchList } from "../redux/actions/VendorAction";
import VendorModal from "../sales/invoices/vendorModal";
import CustomSelect from "./customSelect";
import { VENDOR_ADD_RESET } from "../redux/constants/VendorConstant";

const schema = yup.object({
  invoice_number: yup
    .string()
    .required("Invoice number is required")
    .trim(),
  nature_of_supply: yup
    .string()
    .required("Nature of supply is required")
    .trim(),
  invoice_date: yup
    .date()
    .required("Invoice date is required")
    .nullable()
    .required("Invoice date is required"),
});

const ledgerSchema = yup.object({
  title: yup
    .string()
    .required("Title is required")
    .trim(),
});

const editInvoice = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const invoiceEditDetailInvoice = useSelector(
    (state) => state.apInvoiceEditDetail
  );
  const {
    apInvoiceEditDetail,
    loading: editDetailLoading,
  } = invoiceEditDetailInvoice;

  const apModuleInvoiceEdit = useSelector((state) => state.apInvoiceEdit);
  const ledgerList = useSelector((state) => state?.ledgerModuleListByGroup);
  const { loading: ledgerListLoading, ledger } = ledgerList;

  const tdsSectionList = useSelector((state) => state?.tdsSectionList);
  const { loading: tdsSectionListLoading, tdsSectionData } = tdsSectionList;

  const { success, loading, error } = apModuleInvoiceEdit;

  const tdsCalculation = useSelector((state) => state.tdsCalculation);
  const {
    tdsAmount,
    loading: tdsCalculationLoading,
    error: tdsCalculationError,
  } = tdsCalculation;

  const vendorListSearch = useSelector((state) => state.vendorListSearch);
  const { vendorSearchList, loading: vendorListLoading } = vendorListSearch;

  const companyList = useSelector((state) => state.companyList);
  const { company } = companyList;

  const addvendor = useSelector((state) => state.addvendor);
  const {
    success: vendorSuccess,
    loading: vendorLoading,
    error: vendorError,
    data: vendorPostData,
  } = addvendor;

  const vendorSelectRef = useRef(null);
  const [menu, setMenu] = useState();
  const [editData, setEditData] = useState({});
  const [vendorDetail, setVendorDetail] = useState({});
  const [editError, setEditError] = useState({});
  const [ledgerListData, setLedgerData] = useState([]);
  const [ledgerIndexData, setLedgerIndexData] = useState("");
  const [page, setPage] = useState({ page: 1, perPage: 10 });
  const [tdsListData, setTdsListData] = useState([]);
  const [tdsPage, setTdsPage] = useState({ page: 1, perPage: 10 });
  const [invoiceData, setInvoiceData] = useState({});
  const [itemId, setItemId] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const [vendorPage, setVendorPage] = useState({ page: 1, perPage: 10 });
  const [data, setData] = useState([]);
  const [footerData, setFooterData] = useState();
  const [gstSection, setGstSection] = useState([]);
  const [tdsData, setTdsData] = useState({});
  const [editable, setEditable] = useState({});
  const [editTdstable, setEditTdsTable] = useState({});
  const [messageApi, contextHolder] = message.useMessage();

  const [isChanged, setIsChanged] = useState(false);

  //vendor
  const [addVendorData, setAddVendorData] = useState({});
  const [apiVendorError, setApiVendorError] = useState({});
  const [vendorFile, setVendorFile] = useState(null);

  const [addLedgerData, setAddLedgerData] = useState({
    title: "",
    group: null,
    description: "",
  });
  const [apiError, setApiError] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showErrors, setShowErrors] = useState(true);
  const [itemsError, setItemsError] = useState([]);

  const totals = useCalculateTotals(data);

  useUpdateFooterData(
    footerData,
    setFooterData,
    totals,
    gstSection,
    company,
    vendorDetail
  );

  const datePickerRef = useRef(null);
  const selectRefs = useRef({});

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const formattedOptions = (options) =>
    options.map((option) => ({
      value: option.id,
      label: option.text,
    }));

  const handleFooterChange = (field, value) => {
    setFooterData((prevFooterData) => ({
      ...prevFooterData,
      [field]: value,
    }));
    setIsChanged(true);
  };

  const renderFooterCell = (field, value) => {
    if (editable.rowId === "footer" && editable.field === field) {
      return (
        <input
          type="text"
          value={value}
          onChange={(e) => handleFooterChange(field, e.target.value)}
          onBlur={() => setEditable({})}
          autoFocus
        />
      );
    }
    return (
      <input
        type="text"
        value={value}
        readOnly
        onDoubleClick={() => setEditable({ rowId: "footer", field })}
      />
    );
  };

  const renderJournalCell = (field, value) => {
    // Check if the field is editable
    if (editable.field === field) {
      return (
        <input
          type="text"
          value={value}
          onChange={(e) => {
            handleJournalChange(field, e.target.value);
          }}
          onBlur={() => {
            setEditable({ field: null });
          }}
          autoFocus
        />
      );
    }

    if (field === "expense_ledger.title") {
      const expenseLedgerTitle = value || "";

      return (
        <input
          type="text"
          value={expenseLedgerTitle}
          readOnly
          onDoubleClick={() => {
            handleJournalDoubleClick(field);
          }}
          onClick={() => {
            setEditable({ field }); // Set the field to editable on click
          }}
        />
      );
    }

    // Default case for non-editable fields

    return (
      <input
        type="text"
        value={value || ""}
        readOnly
        onDoubleClick={() => {
          handleJournalDoubleClick(field);
        }}
        onClick={() => {
          setEditable({ field }); // Set the field to editable on click
        }}
      />
    );
  };

  const handleJournalChange = (field, value) => {
    setInvoiceData((prevData) => {
      // Clone the previous state
      const newInvoiceData = { ...prevData };

      // Split the field by dots to handle nested properties
      const fieldParts = field.split(".");

      // Navigate through the nested structure and update the target field
      let nestedObject = newInvoiceData;
      for (let i = 0; i < fieldParts.length; i++) {
        const key = fieldParts[i];
        if (i === fieldParts.length - 1) {
          // Update the value of the nested field
          nestedObject[key] = value;
        } else {
          // Clone the nested object to maintain immutability
          nestedObject[key] = { ...nestedObject[key] };
          // Move deeper into the nested structure
          nestedObject = nestedObject[key];
        }
      }

      return newInvoiceData;
    });

    // Optionally, set a flag indicating that the data has been changed
    setIsChanged(true);
  };

  const handleJournalDoubleClick = (field) => {
    setEditable({ field });
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setIsChanged(true);
    setEditData((prevState) => ({ ...prevState, [id]: value }));
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleCommonChange = (e) => {
    const { id, value } = e.target;
    setEditData((prevState) => ({ ...prevState, [id]: value }));
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
    setIsChanged(true);
  };

  useEffect(() => {
    dispatch(apEditInvoiceDetail(id));
    dispatch(getTdsRateList());
  }, [id]);

  useEffect(() => {
    dispatch(getCompanyList());
  }, []);

  useEffect(() => {
    if (!vendorDetail?.vendor_gstin && editData?.vendor_name) {
      const updatedData = data.map((item) => ({
        ...item,
        gst_rate: 0,
        gst_amount: 0,
      }));
      setData(updatedData);
      setFooterData((prevState) => ({
        ...prevState,
        gst_section: {},
        total_tax: 0,
      }));
    }
  }, [vendorDetail?.vendor_gstin, editData?.vendor_name]);

  useEffect(() => {
    return () => {
      dispatch({ type: AP_INVOICE_EDIT_RESET });
    };
  }, [dispatch]);

  console.log(vendorDetail, "vendorDetail");

  useEffect(() => {
    if (
      (vendorPage?.page > 1 && vendorSearchList?.next) ||
      vendorPage?.page === 1
    ) {
      dispatch(getVendorSearchList("", vendorPage?.page, vendorPage?.perPage));
    }
  }, [vendorPage?.page, vendorPage?.perPage]);

  useEffect(() => {
    if (apInvoiceEditDetail?.items?.length) {
      const postingLedgerData = apInvoiceEditDetail?.items?.map(
        (el) => el?.posting_ledger
      );
      postingLedgerData?.forEach((el) => {
        // Check if the object is already in ledgerListData
        const exists = ledgerListData.some((ledger) => ledger?.id === el?.id);

        // Only push if the object doesn't exist
        if (!exists && el?.id) {
          ledgerListData.push(el);
        }
      });
      const tdsLedgerData = apInvoiceEditDetail?.items?.map(
        (el) => el?.tds_ledger
      );
      tdsLedgerData?.forEach((el) => {
        // Check if the object is already in ledgerListData
        const exists = tdsListData.some((ledger) => ledger?.id === el?.id);

        // Only push if the object doesn't exist
        if (!exists && el?.id) {
          tdsListData.push(el);
        }
      });
      const updatedItemsList = apInvoiceEditDetail?.items?.map((each) => ({
        ...each,
        posting_ledger: each?.posting_ledger?.id,
        discount: each?.discount ? each?.discount : "",
        quantity: each?.quantity ? parseFloat(each?.quantity)?.toFixed(2) : "",
        rate: each?.rate ? parseFloat(each?.rate)?.toFixed(2) : "",
        amount: each?.amount ? parseFloat(each?.amount)?.toFixed(2) : "",
        gst_rate: each?.gst_rate ? parseFloat(each?.gst_rate)?.toFixed(2) : "",
        gst_amount: each?.gst_amount
          ? parseFloat(each?.gst_amount)?.toFixed(2)
          : "",

        total: each?.total ? parseFloat(each?.total).toFixed(2) : "",
        tds_ledger: each?.tds_ledger ? each?.tds_ledger?.id : null,
        tds_amount: parseInt(each?.tds_amount) ? each?.tds_amount : null,
      }));

      setData(updatedItemsList || []);
    }
    setGstSection(
      apInvoiceEditDetail?.gst_section
        ? Object?.keys(apInvoiceEditDetail?.gst_section)
        : []
    );
    setFooterData(apInvoiceEditDetail);
    setInvoiceData(apInvoiceEditDetail || {});
    setTdsData(apInvoiceEditDetail?.tds_section || {});
  }, [apInvoiceEditDetail]);

  useEffect(() => {
    setEditError(error);
  }, [error]);

  useEffect(() => {
    if (error) {
      setShowErrors(error?.items ? true : false);
      setItemsError(
        error?.items
          ? error?.items
          : [
              {
                ledger: "",
                description: "",
                quantity: "",
                rate: "",
                amount: "",
                gst_rate: "",
                gst_amount: "",
                total: "",
              },
            ]
      );
    }
  }, [error]);

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: isModalVisible ? yupResolver(ledgerSchema) : yupResolver(schema),
    defaultValues: isModalVisible ? addLedgerData : editData,
  });

  useEffect(() => {
    const fieldOrder = [
      "vendor_name",
      "vendor_gstin",
      "invoice_number",
      "amount",
      "total",
      "invoice_date",
      "nature_of_supply",
    ];
    const firstErrorField = fieldOrder.find((field) => errors && errors[field]);
    if (firstErrorField) {
      let inputElement;

      inputElement = document.getElementById(firstErrorField);

      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [errors]);

  useEffect(() => {
    if (editData) {
      Object.keys(editData).forEach((field) => {
        setValue(field, editData[field]);
      });
    }
  }, [editData, setValue]);

  useEffect(() => {
    const fieldOrder = [
      "vendor_name",
      "vendor_code",
      "vendor_gstin",
      "invoice_number",
      "amount",
      "total",
      "invoice_date",
      "nature_of_supply",
      "detail",
    ];
    const firstErrorField = fieldOrder.find(
      (field) => editError && editError[field]
    );
    if (firstErrorField) {
      let inputElement;
      if (firstErrorField === "detail") {
        inputElement = selectRefs.current["tds_rate"];
      } else {
        inputElement = document.getElementById(firstErrorField);
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [editError]);

  const parseDate = (dateString) => {
    if (!dateString) {
      return null;
    }

    const parsedDate = new Date(dateString);
    return isNaN(parsedDate) ? null : parsedDate;
  };

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
    setIsModalVisible(false);
    setAddLedgerData({
      title: "",
      group: null,
      description: "",
    });
    setApiError({});
  };

  const handleEditSubmit = (datas) => {
    if (isModalVisible) {
      const postData = {
        title: addLedgerData?.title,
        ledger_group: addLedgerData?.group ? addLedgerData?.group?.value : null,
        description: addLedgerData?.description,
      };
      dispatch(postLedgerModuleList(postData));
    } else {
      const ledger = data?.map((ele) => {
        const { suggested_posting_ledger, ...rest } = ele; // Remove suggested_posting_ledger
        return {
          ...rest,
          posting_ledger: ele?.posting_ledger,
          tds_ledger: ele?.tds_ledger,
          gst_amount: ele?.gst_amount ? parseFloat(ele?.gst_amount) : "0.00",
          quantity: ele?.quantity ? ele?.quantity : null,
          rate: ele?.rate ? ele?.rate : null,
          amount: ele?.amount ? ele?.amount : null,
          gst_rate: ele?.gst_rate ? ele?.gst_rate : null,
          total: ele?.total ? ele?.total : null,
          tds_amount: ele?.tds_amount ? ele?.tds_amount : null,
        };
      });

      const postData = {
        vendor: datas?.vendor_name,
        vendor_code: datas?.vendor_code,
        vendor_gstin: datas?.vendor_gstin,
        invoice_number: datas?.invoice_number ? datas?.invoice_number : null,
        invoice_date: datas?.invoice_date
          ? formatDateYYMMDD(datas?.invoice_date)
          : null,
        expense_ledger: datas?.expense_ledger?.value,
        nature_of_supply: datas?.nature_of_supply
          ? datas?.nature_of_supply
          : "",
        items: ledger,
        tds_rate: editData?.tds_rate ? editData?.tds_rate?.value : "",
      };
      dispatch(updateApInvoice(postData, id));
    }
  };

  useEffect(() => {
    if (success) {
      dispatch({ type: AP_INVOICE_EDIT_RESET });
      setItemId("");
      dispatch(apEditInvoiceDetail(id));
      messageApi.open({
        type: "success",
        content: "invoice updated successfully",
        duration: 5,
      });
    }
  }, [success]);

  useEffect(() => {
    if (editData?.vendor && vendorList?.length) {
      const vendorExists = vendorList?.some(
        (detail) => detail?.id === editData?.vendor?.id
      );
      if (!vendorExists) {
        setVendorList([editData?.vendor, ...vendorList]);
      }
    }
  }, [editData?.vendor, vendorList]);

  useEffect(() => {
    if (tdsAmount) {
      const updatedData = [...data];
      if (updatedData[itemId]) {
        updatedData[itemId]["tds_amount"] = tdsAmount?.tds_amount
          ? parseFloat(tdsAmount?.tds_amount)?.toFixed(2)
          : 0;
        setData(updatedData);
      }
    }
  }, [itemId, tdsAmount]);

  useEffect(() => {
    if (apInvoiceEditDetail) {
      setEditData({
        ...apInvoiceEditDetail,

        invoice_date: parseDate(apInvoiceEditDetail?.invoice_date)
          ? parseDate(apInvoiceEditDetail?.invoice_date)
          : "",
        expense_ledger: {
          value: apInvoiceEditDetail?.expense_ledger?.id,
          label: apInvoiceEditDetail?.expense_ledger?.title,
        },
        vendor_name: apInvoiceEditDetail?.vendor?.id,

        tds_rate:
          apInvoiceEditDetail?.tds_rate?.section_id &&
          apInvoiceEditDetail?.tds_rate?.rate
            ? {
                label: `${apInvoiceEditDetail?.tds_rate?.section_id} - ${apInvoiceEditDetail?.tds_rate?.rate} `,
                value: apInvoiceEditDetail?.tds_rate?.id,
              }
            : null,
      });
      setVendorDetail({
        vendor_code: apInvoiceEditDetail?.vendor?.vendor_code,
        vendor_gstin: apInvoiceEditDetail?.vendor?.gstin_number,
      });
    }
  }, [invoiceEditDetailInvoice?.apInvoiceEditDetail]);

  useEffect(() => {
    // if ((page?.page > 1 && expenseList?.next) || page?.page === 1) {
    //   dispatch(getLedgerModuleList("", page?.page, page?.perPage));
    // }
    dispatch(getLedgerModuleListByGroup());
  }, []);

  useEffect(() => {
    return () => {
      setLedgerData([]);
      dispatch({ type: LEDGERLIST_INVOICE_RESET });
    };
  }, []);

  useEffect(() => {
    if (ledger?.length) {
      setLedgerData(ledger);
    }
  }, [ledger]);

  // useEffect(() => {
  //   if (expenseList?.results) {
  //     const newVendors = expenseList?.results;

  //     // Create a Set of the current vendor ids/names for quick look-up
  //     const existingVendorIds = new Set(
  //       ledgerListData?.map((vendor) => vendor?.id)
  //     ); // Assuming `id` is the unique identifier

  //     // Filter newVendors to only include those that are not already in vendorList
  //     const uniqueNewVendors = newVendors?.filter(
  //       (vendor) => !existingVendorIds?.has(vendor?.id)
  //     );

  //     // Combine the previous vendorList with the unique new ones
  //     const combinedVendorList = [...ledgerListData, ...uniqueNewVendors];
  //     setLedgerData(combinedVendorList);
  //     // Update state with the combined list
  //   }
  // }, [expenseList]);

  const handleSelectChange = (id, selectedValue) => {
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
    setIsChanged(true);
    setEditData((prevState) => ({
      ...prevState,
      [id]: selectedValue, // Here you're setting the vendor ID
    }));
  };

  useEffect(() => {
    if (editData?.vendor_name && vendorList?.length) {
      const filteredVendor = vendorList?.find(
        (el) => el?.id === editData?.vendor_name
      );
      if (filteredVendor) {
        setVendorDetail({
          vendor_code: filteredVendor?.vendor_code
            ? filteredVendor?.vendor_code
            : "",
          vendor_gstin: filteredVendor?.gstin_number
            ? filteredVendor?.gstin_number
            : "",
        });
      }
    }
  }, [vendorList, editData?.vendor_name]);

  const handlePopupScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (
      scrollHeight - scrollTop <= clientHeight + 10 &&
      !vendorListLoading &&
      vendorSearchList?.results?.length
    ) {
      setVendorPage((prevState) => ({
        ...prevState,
        page: prevState?.page + 1,
        perPage: prevState?.perPage,
      }));
    }
  };

  useEffect(() => {
    if ((tdsPage?.page > 1 && tdsSectionData?.next) || tdsPage?.page === 1) {
      dispatch(getTdsSectionList(tdsPage?.page, tdsPage?.perPage));
    }
  }, [tdsPage?.page, tdsPage?.perPage]);

  useEffect(() => {
    if (tdsSectionData?.results) {
      const newVendors = tdsSectionData?.results;

      // Create a Set of the current vendor ids/names for quick look-up
      const existingVendorIds = new Set(
        tdsListData?.map((vendor) => vendor?.id)
      ); // Assuming `id` is the unique identifier

      // Filter newVendors to only include those that are not already in vendorList
      const uniqueNewVendors = newVendors?.filter(
        (vendor) => !existingVendorIds?.has(vendor?.id)
      );

      // Combine the previous vendorList with the unique new ones
      const combinedVendorList = [...tdsListData, ...uniqueNewVendors];

      setTdsListData(combinedVendorList); // Update state with the combined list
    }
  }, [tdsSectionData]);

  useEffect(() => {
    if (vendorSuccess) {
      dispatch({ type: VENDOR_ADD_RESET });
      setAddVendorData({
        pan_number: "",
        gstin_number: "",
        name: "",
        vendor_code: "",
        address: "",
        zip_code: "",
        state: "",
        state_code: "",
        phone: "",
        contact_name: "",
        country_code: null,
        email: "",
        msme: "",
        bank_name: "",
        branch_name: "",
        account_number: "",
        ifsc_code: "",
        swift: "",
        ap_invoice_id: "",
      });
      setIsChanged(true);
      setEditData((prevState) => ({
        ...prevState,
        vendor_name: vendorPostData ? vendorPostData?.id : null,
      }));
      // setValue("vendor_id", vendorPostData?.id);
      setVendorList([...vendorList, vendorPostData]);
      handleModalClose("#vendor_modal");
      messageApi.open({
        type: "success",
        content: "Vendor created successfully",
        duration: 5,
      });
    }
  }, [vendorSuccess]);

  const handleTdsPopupScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollHeight - scrollTop <= clientHeight + 10 &&
      !tdsSectionListLoading &&
      tdsSectionData?.results?.length
    ) {
      setTdsPage((prevState) => ({
        ...prevState,
        page: prevState?.page + 1,
        perPage: prevState?.perPage,
      }));
    }
  };

  const handleDateChange = (id) => {
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
    setIsChanged(true);
  };

  const renderTdsCell = (field, value) => {
    if (editTdstable === field) {
      return (
        <input
          style={{ cursor: "pointer" }}
          type="text"
          value={value}
          onChange={(e) => handleTdsCellChange(field, e.target.value)}
          onBlur={() => setEditTdsTable(null)}
          autoFocus
        />
      );
    }
    return (
      <input
        type="text"
        value={value}
        readOnly
        onDoubleClick={() => handleTdsDoubleClick(field)}
      />
    );
  };

  const handleTdsCellChange = (field, value) => {
    setTdsData((prevTdsData) => ({
      ...prevTdsData,
      [field]: value,
    }));
    setIsChanged(true);
  };

  const handleTdsDoubleClick = (field) => {
    setEditTdsTable(field);
  };
  const onModalClose = () => {
    $("#add_ledger").on("hide.bs.modal", () => {
      setAddLedgerData({
        title: "",
        group: null,
        description: "",
      });
      setApiError({});
    });
    $("#vendor_modal").on("hide.bs.modal", () => {
      setAddVendorData({
        pan_number: "",
        gstin_number: "",
        name: "",
        vendor_code: "",
        address: "",
        zip_code: "",
        state: "",
        state_code: "",
        phone: "",
        contact_name: "",
        country_code: null,
        email: "",
        msme: "",
        bank_name: "",
        branch_name: "",
        account_number: "",
        ifsc_code: "",
        swift: "",
        ap_invoice_id: "",
      });
    });
  };
  useEffect(() => {
    onModalClose();
  }, []);

  useEffect(() => {
    if (vendorSearchList?.results) {
      const newVendors = vendorSearchList.results;

      // Create a Set of the current vendor ids/names for quick look-up
      const existingVendorIds = new Set(vendorList.map((vendor) => vendor.id)); // Assuming `id` is the unique identifier

      // Filter newVendors to only include those that are not already in vendorList
      const uniqueNewVendors = newVendors.filter(
        (vendor) => !existingVendorIds.has(vendor.id)
      );

      // Combine the previous vendorList with the unique new ones
      const combinedVendorList = [...vendorList, ...uniqueNewVendors];

      setVendorList(combinedVendorList); // Update state with the combined list
    }
  }, [vendorSearchList]);

  useEffect(() => {
    document.body.classList.add("mini-sidebar");
  }, []);

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        {contextHolder}
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="card mb-0">
              <div className="card-body">
                {/* Page Header */}
                <div className="page-header">
                  <div className="content-page-header">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/apmodule">AP module</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        <Link to="/apmodule">Invoice List</Link>
                      </li>
                      <li className="breadcrumb-item active">Invoice Edit</li>
                    </ul>
                  </div>
                </div>
                {/* /Page Header */}
                {editDetailLoading ? (
                  <Spin />
                ) : (
                  <div className="row">
                    <div
                      className={
                        apInvoiceEditDetail?.file_url ? "col-md-7" : "col-md-12"
                      }
                    >
                      <form onSubmit={handleSubmit(handleEditSubmit)}>
                        <div className="">
                          <div className="">
                            <div className="row">
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="input-block mb-3">
                                  <label>
                                    Vendor Name
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Controller
                                    name="vendor_name"
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        {/* <Select
                                          id="vendor_name"
                                          {...field}
                                          ref={vendorSelectRef}
                                          placeholder="Select a vendor"
                                          showSearch
                                          style={{ width: "100%" }}
                                          filterOption={(input, option) =>
                                            option.children
                                              .toLowerCase()
                                              .includes(input.toLowerCase())
                                          }
                                          onPopupScroll={handlePopupScroll}
                                          value={field.value} // Ensure the value prop is set to field.value
                                          onChange={(value) => {
                                            handleSelectChange(
                                              value,
                                              "vendor_name",
                                              field.onChange(value)
                                            ); // Your custom handler if needed
                                          }}
                                          dropdownRender={(menu) => (
                                            <>
                                              {menu}
                                              {vendorListLoading && (
                                                <div
                                                  style={{
                                                    textAlign: "center",
                                                    padding: 8,
                                                  }}
                                                >
                                                  <Spin />
                                                </div>
                                              )}
                                            </>
                                          )}
                                        >
                                          {vendorList
                                            ?.map((option) => ({
                                              value: option.id,
                                              label: option.name,
                                            }))
                                            .map((option) => (
                                              <Option
                                                key={option.value}
                                                value={option.value}
                                              >
                                                {option.label}
                                              </Option>
                                            ))}
                                        </Select> */}
                                        <CustomSelect
                                          {...field}
                                          id="vendor_id"
                                          options={
                                            vendorList?.length ? vendorList : []
                                          }
                                          onPopupScroll={handlePopupScroll}
                                          onChange={(value) => {
                                            handleSelectChange(
                                              "vendor_name",
                                              value,
                                              field.onChange(value)
                                            ); // Your custom handler if needed
                                          }}
                                        />
                                        {/* <CustomInput
                                          id="vendor_name"
                                          type="text"
                                          value={value}
                                          onChange={(e) => {
                                            handleChange(e);
                                            onChange(e);
                                          }}
                                          placeholder="Vendor Name"
                                          label="Vendor Name"
                                          errorMsg={
                                            errors?.vendor_name?.message
                                          }
                                          error={errors?.vendor_name}
                                          apiError={editError?.vendor_name}
                                          autoComplete="false"
                                          disabled={true}
                                        /> */}
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="input-block mb-3">
                                  <CustomInput
                                    id="vendor_code"
                                    type="text"
                                    value={vendorDetail?.vendor_code}
                                    onChange={handleCommonChange}
                                    placeholder="Vendor Code"
                                    label="Vendor Code"
                                    apiError={editError?.vendor_code}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="input-block mb-3">
                                  <CustomInput
                                    id="vendor_gstin"
                                    type="text"
                                    value={vendorDetail?.vendor_gstin}
                                    onChange={handleCommonChange}
                                    placeholder="Vendor GSTIN"
                                    label="Vendor GSTIN"
                                    apiError={editError?.vendor_gstin}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="input-block mb-3">
                                  <Controller
                                    name="invoice_number"
                                    control={control}
                                    render={({
                                      field: { value, onChange },
                                    }) => (
                                      <>
                                        <CustomInput
                                          id="invoice_number"
                                          type="text"
                                          value={value}
                                          onChange={(e) => {
                                            handleChange(e);
                                            onChange(e);
                                          }}
                                          placeholder="Invoice Number"
                                          label="Invoice Number"
                                          errorMsg={
                                            errors?.invoice_number?.message
                                          }
                                          error={errors?.invoice_number}
                                          apiError={editError?.invoice_number}
                                          autoComplete="false"
                                          required={true}
                                        />
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="input-block mb-3">
                                  <label>
                                    Invoice Date
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="cal-icon cal-icon-info">
                                    <Controller
                                      name="invoice_date"
                                      control={control}
                                      render={({ field }) => (
                                        <div className="cal-icon cal-icon-info">
                                          {console.log(field, "fiekds")}
                                          <DatePicker
                                            {...field}
                                            className="datetimepicker form-control"
                                            selected={field?.value}
                                            onChange={(date) =>
                                              handleDateChange(
                                                "invoice_date",
                                                date,
                                                field?.onChange(date)
                                              )
                                            }
                                            id="invoice_date"
                                            ref={datePickerRef}
                                          />
                                        </div>
                                      )}
                                    />
                                    {errors?.invoice_date?.message && (
                                      <p className="text-danger">
                                        {errors?.invoice_date?.message}
                                      </p>
                                    )}
                                    {editError?.invoice_date && (
                                      <p className="text-danger">
                                        {editError?.invoice_date}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="input-block mb-3">
                                  <Controller
                                    name="nature_of_supply"
                                    control={control}
                                    render={({
                                      field: { value, onChange },
                                    }) => (
                                      <CustomInput
                                        id="nature_of_supply"
                                        type="text"
                                        value={value}
                                        onChange={(e) => {
                                          handleChange(e);
                                          onChange(e);
                                        }}
                                        placeholder="Nature of Supply"
                                        label="Nature of Supply"
                                        errorMsg={
                                          errors?.nature_of_supply?.message
                                        }
                                        error={errors?.nature_of_supply}
                                        apiError={editError?.nature_of_supply}
                                        autoComplete="false"
                                        required={true}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="complex-invoice-table">
                          <div
                            className="ag-theme-alpine"
                            style={{ width: "100%" }}
                          >
                            <h6>Invoice Intent</h6>
                            <TableEditInvoice
                              setData={setData}
                              data={data}
                              expenseList={ledger}
                              setItemId={setItemId}
                              setIsChanged={setIsChanged}
                              setEditable={setEditable}
                              editable={editable}
                              footerData={footerData}
                              tdsSectionData={tdsSectionData}
                              addLedgerData={addLedgerData}
                              setAddLedgerData={setAddLedgerData}
                              handleSubmit={handleSubmit}
                              handleEditSubmit={handleEditSubmit}
                              isModalVisible={isModalVisible}
                              setIsModalVisible={setIsModalVisible}
                              control={control}
                              errors={errors}
                              handleModalClose={handleModalClose}
                              setApiError={setApiError}
                              apiError={apiError}
                              ledgerListData={ledgerListData}
                              tdsListData={tdsListData}
                              handleTdsPopupScroll={handleTdsPopupScroll}
                              tdsSectionListLoading={tdsSectionListLoading}
                              setLedgerPage={setPage}
                              setLedgerIndexData={setLedgerIndexData}
                              ledgerIndexData={ledgerIndexData}
                              setLedgerData={setLedgerData}
                              getLedgerModuleListByGroup={
                                getLedgerModuleListByGroup
                              }
                              editData={vendorDetail}
                            />
                          </div>
                        </div>

                        {itemsError && error?.items && showErrors && (
                          <>
                            <InvoiceErrorDisplay
                              itemsError={itemsError}
                              onClose={() => setShowErrors(false)}
                            />
                            ;
                          </>
                        )}
                        <div className="row">
                          <div className="col">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={!isChanged || loading}
                            >
                              {loading ? <Spin /> : "Save"}
                            </button>
                            &nbsp;
                            <button
                              type="reset"
                              className="btn btn-primary cancel me-2"
                              onClick={() => history.push("/apmodule")}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                    {apInvoiceEditDetail?.file_url && (
                      <div className="col-md-5">
                        <ImagePreview
                          src={apInvoiceEditDetail?.file_url}
                          width="100%"
                          height="90%"
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <VendorModal
          id="vendor_modal"
          title="Add Vendor"
          addData={addVendorData}
          setAddData={setAddVendorData}
          setApiError={setApiVendorError}
          apiError={apiVendorError}
          setFile={setVendorFile}
          file={vendorFile}
          handleModalClose={handleModalClose}
        />
      </div>
    </>
  );
};
export default editInvoice;
