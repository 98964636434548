import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { InvoiceLogo1 } from '../_components/Imagepath';
import { HTTP } from '../redux/api';
import { message as antdMessage, Spin } from 'antd';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ResetPassword = () => {
  const { uidb64, token } = useParams(); // Extract uidb64 and token from URL
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [messageApi, contextHolder] = antdMessage.useMessage();
  const [loading, setLoading] = useState(false);
  const history = useHistory();


  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      messageApi.open({
        type: "error",
        content: "Passwords do not match. Please try again.",
        duration: 5,
      });
      return;
    }

    setLoading(true);

    try {
      const response = await HTTP.post(`/users/reset-password/${uidb64}/${token}/`, { password });
      const messageResponse = response.data.detail;
      messageApi.open({
        type: "success",
        content: messageResponse,
        duration: 8,
      });
      setLoading(false);
      history.push('/')
    } catch (error) {
      console.log(error.response);
      const errorMesssage =error.response.data.detail
      messageApi.open({
        type: "error",
        content: errorMesssage,
        duration: 5,
      });
      setLoading(false);
    }
  };

  return (
    <div className="main-wrapper login-body">
      <div className="login-wrapper">
        <div className="container">
          <img className="img-fluid logo-dark mb-2" src={InvoiceLogo1} alt="Logo" />
          <div className="loginbox">
            <div className="login-right">
              <div className="login-right-wrap">
                <h1>Reset Password</h1>
                <p className="account-subtitle">Enter a new password to reset your account</p>
                
                {/* Form */}
                <form onSubmit={handleResetPassword}>
                  <div className="input-block mb-3">
                    <label className="form-control-label">New Password</label>
                    <input
                      className="form-control"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>
                  <div className="input-block mb-3">
                    <label className="form-control-label">Confirm New Password</label>
                    <input
                      className="form-control"
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                  </div>
                  <div className="input-block mb-0">
                    <button className="btn btn-lg btn-block w-100 btn-primary" type="submit">
                      {loading ? <Spin /> : 'Reset Password'}
                    </button>
                  </div>
                </form>
                {/* /Form */}

                {contextHolder}

                <div className="text-center dont-have">
                  Remember your password? <Link to="/">Login</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
