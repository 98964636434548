import {
  CUSTOMERLISTSET_PAGE,
  CUSTOMER_EDIT_DETAIL_FAIL,
  CUSTOMER_EDIT_DETAIL_REQUEST,
  CUSTOMER_EDIT_DETAIL_SUCCESS,
  CUSTOMER_EDIT_FAIL,
  CUSTOMER_EDIT_REQUEST,
  CUSTOMER_EDIT_RESET,
  CUSTOMER_EDIT_SUCCESS,
  GET_CUSTOMERLIST_FAIL,
  GET_CUSTOMERLIST_REQUSET,
  GET_CUSTOMERLIST_SUCCESS,
  CUSTOMER_ADD_FAIL,
  CUSTOMER_ADD_REQUEST,
  CUSTOMER_ADD_RESET,
  CUSTOMER_ADD_SUCCESS,
} from "../constants/CustomerModuleCnstant";

const initialState = {
  customerList: [],
  loading: false,
  error: null,
  page: 1,
  perPage: 10,
};

export const customerModuleListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMERLIST_REQUSET:
      return { ...state, loading: true, error: null };
    case GET_CUSTOMERLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        customerList: action.payload.data,
        page: action.payload.page,
        perPage: action.payload.perPage,
      };
    case GET_CUSTOMERLIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CUSTOMERLISTSET_PAGE:
      return { ...state, page: action.payload };
    default:
      return state;
  }
};

export const customerEditDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOMER_EDIT_DETAIL_REQUEST:
      return { loading: true };
    case CUSTOMER_EDIT_DETAIL_SUCCESS:
      return { loading: false, customerEditDetail: action.payload };
    case CUSTOMER_EDIT_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const customerEditReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOMER_EDIT_REQUEST:
      return { loading: true };

    case CUSTOMER_EDIT_SUCCESS:
      return { loading: false,success: true };

    case CUSTOMER_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case CUSTOMER_EDIT_RESET:
      return {};
    default:
      return state;
  }
};

export const customerAddReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOMER_ADD_REQUEST:
      return { loading: true };

    case CUSTOMER_ADD_SUCCESS:
      return { loading: false,data:action.payload.data, success: true };

    case CUSTOMER_ADD_FAIL:
      return { loading: false, error: action.payload };
    case CUSTOMER_ADD_RESET:
      return {};
    default:
      return state;
  }
};
