import React, { useState } from 'react';
import { HTTP } from '../../redux/api';
import { Button, Input, Spin, message } from 'antd';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// Define validation schema
const schema = yup.object().shape({
  currentPassword: yup.string().required('Current password is required'),
  newPassword: yup
    .string()
    .min(8, 'New password must be at least 8 characters')
    .required('New password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .required('Please confirm your new password'),
});

function ChangePassword() {
  const [loadingPassword, setLoadingPassword] = useState(false);
  const userData = useSelector((state) => state.userSignin);
  const { userInfo } = userData;

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setLoadingPassword(true);

    try {
      const response = await HTTP.post(
        '/users/update-password/',
        {
          current_password: data.currentPassword,
          new_password: data.newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo}`,
          },
        }
      );

      if (response.data) {
        message.success('Password updated successfully');
        reset(); // Clear the form
      }
    } catch (error) {
      // Handle different types of errors from the API response
      if (error.response && error.response.data) {
        const errorData = error.response.data;

        if (errorData.current_password) {
          setError('currentPassword', {
            type: 'manual',
            message: errorData.current_password, // Get the first error message
          });
        }
        if (errorData.new_password) {
          setError('newPassword', {
            type: 'manual',
            message: errorData.new_password,
          });
        }
        if (errorData.confirmPassword) {
          setError('confirmPassword', {
            type: 'manual',
            message: errorData.confirmPassword,
          });
        }
      } else {
        // Fallback error message if no specific response is received
        setError('currentPassword', {
          type: 'manual',
          message: 'Failed to update password. Please try again.',
        });
      }
    } finally {
      setLoadingPassword(false);
    }
  };

  return (
    <div>
      <div className="col-lg-6">
        <div className="card">
          <div className="card-header">
            <h5 className="card-title">
              <span>Change Password</span>
            </h5>
          </div>
          <div className="card-body" style={{ padding: '6PX 30PX 0PX 20PX' }}>
            <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '20px' }}>
              <div className="form-group" style={{ marginBottom: '20px' }}>
                <label style={{ display: 'block', marginBottom: '8px', fontWeight: '500' }}>
                  Current Password
                </label>
                <Controller
                  name="currentPassword"
                  control={control}
                  render={({ field }) => <Input.Password {...field} style={{ width: '100%' }} />}
                />
                {errors.currentPassword && (
                  <div style={{ color: '#ff4d4f', marginBottom: '16px' }}>
                    {errors.currentPassword.message}
                  </div>
                )}
              </div>

              <div className="form-group" style={{ marginBottom: '20px' }}>
                <label style={{ display: 'block', marginBottom: '8px', fontWeight: '500' }}>
                  New Password
                </label>
                <Controller
                  name="newPassword"
                  control={control}
                  render={({ field }) => <Input.Password {...field} style={{ width: '100%' }} />}
                />
                {errors.newPassword && (
                  <div style={{ color: '#ff4d4f', marginBottom: '16px' }}>
                    {errors.newPassword.message}
                  </div>
                )}
              </div>

              <div className="form-group" style={{ marginBottom: '20px' }}>
                <label style={{ display: 'block', marginBottom: '8px', fontWeight: '500' }}>
                  Confirm New Password
                </label>
                <Controller
                  name="confirmPassword"
                  control={control}
                  render={({ field }) => <Input.Password {...field} style={{ width: '100%' }} />}
                />
                {errors.confirmPassword && (
                  <div style={{ color: '#ff4d4f', marginBottom: '16px' }}>
                    {errors.confirmPassword.message}
                  </div>
                )}
              </div>

              <Button
                type="primary"
                htmlType="submit"
                style={{
                  width: '40%',
                  padding: '8px',
                  backgroundColor: '#6C5DD3',
                  borderColor: '#6C5DD3',
                  marginBottom: '20PX',
                }}
              >
                {loadingPassword ? <Spin /> : 'Change Password'}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
