/* eslint-disable */

import React from "react";
// import { Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { customerCreation } from "../../redux/actions/CustomerModuleAction";
// import { Spin } from "antd";
import CreateModal from "../../_components/modalbox/createmodal";
// import CustomInput from "../../_components/inputField/custominput";
import PropTypes from "prop-types";
import CustomerAddEditModal from "./customerAddEditModal";

const CustomerAdd = ({
  addCustomer,
  setIsDisable,
  modalRef,
  show,
  handleSubmit,
  control,
  setApiError,
  apiError,
  setAddCustomer,
  errors,
  isDisable,
  createCustomerLoading,
}) => {
  const dispatch = useDispatch();

  const handleAddSubmit = (data) => {
    const postData = {
      pan_number: addCustomer?.pan_number ? addCustomer?.pan_number : null,
      gstin_number: addCustomer?.gstin_number
        ? addCustomer?.gstin_number
        : null,
      name: data?.name ? data?.name : "",
      address: data?.address ? data?.address : "",
      zip_code: data?.zip_code ? data?.zip_code : "",
      state: data?.state ? data?.state : "",
      phone: data?.phone ? data?.phone : "",
      contact_name: data?.contact_name ? data?.contact_name : "",
      email: data?.email ? data?.email : "",
      location: data?.location,
      customer_code: addCustomer?.customer_code
        ? addCustomer?.customer_code
        : null,
    };
    dispatch(customerCreation(postData));
    setIsDisable(false);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setApiError((prevState) => ({ ...prevState, [id]: "" }));
    setAddCustomer((prevState) => ({ ...prevState, [id]: value }));
    setIsDisable(true);
  };

  const addCustomerModal = (
    <CreateModal
      id="add_customer"
      modalRef={modalRef}
      show={show}
      title="Add Customer"
      modalbody={
        <>
          <div className="modal-body p-4">
            <div className="row">
              <div className="col-md-12">
                <div className="">
                  <div className="form-group-item">
                    <CustomerAddEditModal
                      id={"add_customer"}
                      handleSubmit={handleSubmit}
                      handleEditSubmit={handleAddSubmit}
                      control={control}
                      handleChange={handleChange}
                      errors={errors}
                      apiError={apiError}
                      isDisable={isDisable}
                      createCustomerLoading={createCustomerLoading}
                      addCustomer={addCustomer}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    />
  );

  return <>{addCustomerModal}</>;
};

CustomerAdd.propTypes = {
  addCustomer: PropTypes.object.isRequired,
  setIsDisable: PropTypes.func.isRequired,
  modalRef: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  setApiError: PropTypes.func.isRequired,
  apiError: PropTypes.object,
  setAddCustomer: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isDisable: PropTypes.bool.isRequired,
  createCustomerLoading: PropTypes.bool,
};

export default CustomerAdd;
