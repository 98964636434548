/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import "../_components/antd.css";
import { message, Spin, Table } from "antd";
import {
  onShowSizeChange,
  itemRender,
} from "../_components/paginationfunction";
import Select from "react-select";
import { Select as AntdSelect } from "antd";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import {
  apRecurringSchedulerApi,
  getAPRecurringInvoiceList,
  setPage,
} from "../redux/actions/RecurringAction";

import { useSelector } from "react-redux";
import {
  clearButtonHoverStyle,
  clearButtonStyle,
  formatDateYYMMDD,
  intervalList,
} from "../sales/invoices/constant";
import SubmitToErpModal from "../sales/invoices/submitToErpModal";
import { sendArApproval } from "../redux/actions/ARmoduleAction";
import { INVOICE_SUBMIT_ERP_RESET } from "../redux/constants/ARmoduleConstant";
import { AP_RECURRINGINVOICE_SCHEDULE_RESET } from "../redux/constants/RecurringInvoiceConstant";
import SchedulerModal from "../sales/invoices/schedulerModal";
import { getVendorSearchList } from "../redux/actions/VendorAction";
import { VENDOR_SEARCH_RESET } from "../redux/constants/VendorConstant";
import VendorModal from "../sales/invoices/vendorModal";

const ApRecurringInvoice = () => {
  const [menu, setMenu] = useState(false);

  const [filterData, setFilterData] = useState({
    vendor_name: "",
    start_date: "",
    end_date: "",
    interval: null,
    day: null,
    nature_of_supply: "",
  });
  const [editData, setEditData] = useState({});
  const [show, setShow] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [vendorPage, setVendorPage] = useState({ page: 1, perPage: 20 });
  const [submitToErpError, setSubmitToErpError] = useState("");
  const [apSchedulerError, setApSchedulerError] = useState("");

  const [isHovered, setIsHovered] = React.useState(false);

  //vendor
  const [addVendorData, setAddVendorData] = useState({});
  const [apiVendorError, setApiVendorError] = useState({});
  const [vendorFile, setVendorFile] = useState(null);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const [messageApi, contextHolder] = message.useMessage();

  const apRecurringInvoiceList = useSelector(
    (state) => state.apRecurringInvoiceList
  );
  const {
    loading: recurringInvoiceListLoading,
    recurringList,
    page,
    perPage,
  } = apRecurringInvoiceList;

  const userData = useSelector((state) => state.userSignin);
  const { permissions } = userData;

  const arModuleSubmitErp = useSelector((state) => state?.arModuleSubmitErp);
  const { loading, success, error } = arModuleSubmitErp;

  const apRecurringInvoiceScheduler = useSelector(
    (state) => state?.apRecurringInvoiceScheduler
  );
  const {
    loading: schedulerLoading,
    error: schedulerError,
    success: schedulerSuccess,
  } = apRecurringInvoiceScheduler;

  const dispatch = useDispatch();

  const formattedOptions = (options) =>
    options.map((option) => ({
      value: option.id,
      label: option.text,
    }));

  const vendorListSearch = useSelector((state) => state.vendorListSearch);
  const { vendorSearchList, loading: vendorListLoading } = vendorListSearch;

  useEffect(() => {
    if (
      (vendorPage?.page > 1 && vendorSearchList?.next) ||
      vendorPage?.page === 1
    ) {
      dispatch(getVendorSearchList("", vendorPage?.page, vendorPage?.perPage));
    }
  }, [vendorPage?.page, vendorPage?.perPage]);

  useEffect(() => {
    if (vendorSearchList?.results) {
      const newVendors = vendorSearchList.results;

      // Create a Set of the current vendor ids/names for quick look-up
      const existingVendorIds = new Set(vendorList.map((vendor) => vendor.id)); // Assuming `id` is the unique identifier

      // Filter newVendors to only include those that are not already in vendorList
      const uniqueNewVendors = newVendors.filter(
        (vendor) => !existingVendorIds.has(vendor.id)
      );

      // Combine the previous vendorList with the unique new ones
      const combinedVendorList = [...vendorList, ...uniqueNewVendors];

      setVendorList(combinedVendorList); // Update state with the combined list
    }
  }, [vendorSearchList]);

  const handlePopupScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollHeight - scrollTop <= clientHeight + 10 &&
      !vendorListLoading &&
      vendorSearchList?.results?.length
    ) {
      setVendorPage((prevState) => ({
        ...prevState,
        page: prevState?.page + 1,
        perPage: prevState?.perPage,
      }));
    }
  };

  const handleFilterChange = (e) => {
    const { id, value } = e.target;
    dispatch(setPage(1, 10));
    setFilterData((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleSelectFilterChange = (selectedOption, id) => {
    setFilterData((prevState) => ({
      ...prevState,
      [id]: selectedOption,
    }));
    dispatch(setPage(1, 10));
  };

  const handleDateFilterChange = (selectedDate, id) => {
    setFilterData((prevState) => ({
      ...prevState,
      [id]: selectedDate,
    }));
    dispatch(setPage(1, 10));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(sendArApproval(editData?.id));
  };

  const handleScheduler = (event) => {
    event.preventDefault();
    dispatch(apRecurringSchedulerApi());
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const handleEditClick = (record) => {
    setEditData(record);
  };

  useEffect(() => {
    if (error) {
      setSubmitToErpError(error);
    }
  }, [error]);

  useEffect(() => {
    if (schedulerError) {
      setApSchedulerError(schedulerError);
    }
  }, [schedulerError]);

  useEffect(() => {
    if (schedulerSuccess) {
      handleModalClose("#invoice_scheduler");
      closeModal();
      messageApi.open({
        type: "success",
        content: "Scheduled AP invoice(s) generated successfully",
        duration: 5,
      });
      dispatch({ type: AP_RECURRINGINVOICE_SCHEDULE_RESET });
    }
  });

  const onModalClose = () => {
    $("#submit_to_erp").on("hide.bs.modal", () => {
      setEditData({});
      setSubmitToErpError("");
      dispatch({ type: INVOICE_SUBMIT_ERP_RESET });
    });
    $("#vendor_modal").on("hide.bs.modal", () => {
      setAddVendorData({
        pan_number: "",
        gstin_number: "",
        name: "",
        vendor_code: "",
        address: "",
        zip_code: "",
        state: "",
        state_code: "",
        phone: "",
        contact_name: "",
        country_code: null,
        email: "",
        msme: "",
        bank_name: "",
        branch_name: "",
        account_number: "",
        ifsc_code: "",
        swift: "",
        ap_invoice_id: "",
      });
    });
  };

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
  };

  const closeModal = () => {
    setShow(false); // This will hide the modal
  };

  useEffect(() => {
    if (success) {
      handleModalClose("#submit_to_erp");
      closeModal();
      setEditData({});
      dispatch({ type: INVOICE_SUBMIT_ERP_RESET });
      setSubmitToErpError("");
      messageApi.open({
        type: "success",
        content: "Submitted to Zoho",
        duration: 5,
      });
    }
  }, [success]);

  useEffect(() => {
    onModalClose();
  }, []);

  const columns = [
    {
      title: "Sno.",
      dataIndex: "index",
      render: (text, record, index) => perPage * (page - 1) + index + 1,
    },
    {
      title: "Vendor Name",
      dataIndex: "vendor",
      sorter: (a, b) => a?.vendor - b?.vendor,
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      sorter: (a, b) => new Date(a.start_date) - new Date(b.start_date),
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      sorter: (a, b) => new Date(a.end_date) - new Date(b.end_date),
    },
    {
      title: "Interval",
      dataIndex: "schedule_type",
      sorter: (a, b) => a.schedule_type.localeCompare(b.schedule_type),
    },

    {
      title: "Invoice Amount",
      dataIndex: "invoice_amount",
      sorter: (a, b) => a.invoice_amount - b.invoice_amount,
    },
    {
      title: "Tax Amount",
      dataIndex: "tax_amount",
      sorter: (a, b) => a.tax_amount - b.tax_amount,
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (text, record) => (
        <>
          {/* {permissions?.arModule?.addEditTransactions && ( */}
          <>
            <Link
              to={`/edit-ap-recurring-invoice/${record?.id}`}
              className="btn btn-primary me-2"
            >
              <i className="far fa-edit" />
            </Link>
            {/* <Link
                className="btn btn-greys"
                data-bs-toggle="modal"
                to="#submit_to_erp"
                role="button"
                onClick={() => handleEditClick(record)}
              >
                <i className="far fa-send" />
              </Link> */}
          </>
          {/* // )} */}
        </>
      ),
    },
  ];

  const handleTableChange = (page, perPage) => {
    dispatch(setPage(page, perPage));
  };

  useEffect(() => {
    return () => {
      dispatch({ type: VENDOR_SEARCH_RESET });
    };
  }, []);

  const handleSelectChange = (id, selectedOption) => {
    const fetchVendorDetails = vendorList?.find(
      (el) => el?.id === selectedOption
    );
    setFilterData((prevState) => ({
      ...prevState,
      [id]: fetchVendorDetails ? fetchVendorDetails?.name : "",
    }));
  };

  useEffect(() => {
    dispatch(
      getAPRecurringInvoiceList(
        page,
        perPage,
        filterData?.vendor_name ? filterData?.vendor_name : "",
        filterData?.start_date ? formatDateYYMMDD(filterData?.start_date) : "",
        filterData?.end_date ? formatDateYYMMDD(filterData?.end_date) : "",
        filterData?.interval ? filterData?.interval?.value : "",
        filterData?.day ? filterData?.day?.value : "",
        filterData?.nature_of_supply
      )
    );
  }, [page, perPage, filterData]);

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        {contextHolder}
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper customers">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="content-page-header">
                <div className="col">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a>AP module</a>
                    </li>
                    <li className="breadcrumb-item active">
                      Recurring Invoice List
                    </li>
                  </ul>
                </div>
                {/* {permissions?.arModule?.addEditTransactions && ( */}

                <div className="list-btn">
                  <ul className="filter-list filterbutton">
                    <li>
                      <Link
                        className="btn btn-greys me-2"
                        data-bs-toggle="modal"
                        to="#invoice_scheduler"
                        role="button"
                        style={{ backgroundColor: "#E7BD17" }}
                      >
                        Run Scheduler Now (Test Mode)
                      </Link>
                    </li>{" "}
                    &nbsp;
                    <li>
                      <Link
                        className="btn btn-primary"
                        to={"/add-ap-recurring-invoice"}
                      >
                        <i
                          className="fa fa-plus-circle me-2"
                          aria-hidden="true"
                        />
                        Schedule Recurring Invoice
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* )} */}
              </div>
              <div className="list-btn">
                <ul className="filter-list">
                  <li>
                    <AntdSelect
                      id="vendor_name"
                      placeholder="Vendor Name"
                      style={{ width: "200px" }}
                      value={
                        filterData?.vendor_name
                          ? vendorList?.find(
                              (el) => el?.name === filterData?.vendor_name
                            )?.id
                          : undefined
                      }
                      showSearch
                      onChange={(value) =>
                        handleSelectChange("vendor_name", value)
                      }
                      filterOption={(input, option) =>
                        option?.label
                          ?.toLowerCase()
                          ?.includes(input?.toLowerCase())
                      }
                      options={
                        vendorList?.length
                          ? [
                              { label: "Select Vendor", value: "" },
                              ...vendorList.map((vendor) => ({
                                label: vendor?.name,
                                value: vendor?.id,
                              })),
                            ]?.reduce((acc, current) => {
                              if (
                                !acc.find(
                                  (item) => item.value === current.value
                                )
                              ) {
                                acc.push(current);
                              }
                              return acc;
                            }, [])
                          : []
                      }
                      onPopupScroll={handlePopupScroll}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          {vendorListLoading && (
                            <div style={{ textAlign: "center", padding: 8 }}>
                              <Spin />
                            </div>
                          )}
                        </>
                      )}
                    />
                  </li>
                  <li
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <DatePicker
                      className="datetimepicker form-control"
                      id="start_date"
                      placeholderText="Start Date"
                      selected={filterData?.start_date}
                      onChange={(selectedDate) =>
                        handleDateFilterChange(selectedDate, "start_date")
                      }
                    />
                    {filterData?.start_date && (
                      <button
                        type="button"
                        style={{
                          ...clearButtonStyle,
                          ...(isHovered ? clearButtonHoverStyle : {}),
                        }}
                        onClick={() =>
                          handleDateFilterChange(null, "start_date")
                        }
                        aria-label="Clear Date"
                      >
                        <i className="fas fa-times" />
                      </button>
                    )}
                  </li>

                  <li
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <DatePicker
                      className="datetimepicker form-control"
                      id="end_date"
                      placeholderText="End Date"
                      selected={filterData?.end_date}
                      onChange={(selectedDate) =>
                        handleDateFilterChange(selectedDate, "end_date")
                      }
                    />
                    {filterData?.end_date && (
                      <button
                        type="button"
                        style={{
                          ...clearButtonStyle,
                          ...(isHovered ? clearButtonHoverStyle : {}),
                        }}
                        onClick={() => handleDateFilterChange(null, "end_date")}
                        aria-label="Clear Date"
                      >
                        <i className="fas fa-times" />
                      </button>
                    )}
                  </li>
                  <li className="customDrpdwn">
                    <Select
                      options={formattedOptions(intervalList)}
                      placeholder={"Interval"}
                      value={filterData?.interval}
                      onChange={(selectedOption) =>
                        handleSelectFilterChange(selectedOption, "interval")
                      }
                    />
                  </li>
                  <li className="customDrpdwn">
                    <input
                      id="nature_of_supply"
                      type="text"
                      placeholder="Nature of Supply"
                      value={filterData?.nature_of_supply}
                      className="form-control"
                      onChange={handleFilterChange}
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card customers">
                  <div className="card-body">
                    <div className="table-responsive table-hover cmb-55">
                      <Table
                        className="table armoduleList"
                        loading={recurringInvoiceListLoading}
                        pagination={{
                          current: page,
                          pageSize: perPage,
                          onChange: handleTableChange,
                          total: recurringList?.count,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        columns={columns}
                        dataSource={recurringList?.results}
                        rowKey={(record) => record.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Other modals and components */}
        <SubmitToErpModal
          show={show}
          submitToErpError={submitToErpError}
          handleSubmit={handleSubmit}
          loading={loading}
        />
        <SchedulerModal
          show={show}
          schedulerError={schedulerError}
          handleSubmit={handleScheduler}
          loading={schedulerLoading}
        />
        <VendorModal
          id="vendor_modal"
          title="Add Vendor"
          addData={addVendorData}
          setAddData={setAddVendorData}
          setApiError={setApiVendorError}
          apiError={apiVendorError}
          setFile={setVendorFile}
          file={vendorFile}
          handleModalClose={handleModalClose}
        />
      </div>
    </>
  );
};

export default ApRecurringInvoice;
