/* eslint-disable */
import { Spin, message } from "antd";
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import CustomInput from "../../_components/inputField/custominput";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { addVendor } from "../../redux/actions/VendorAction";
import { VENDOR_ADD_RESET } from "../../redux/constants/VendorConstant";
import { useDispatch } from "react-redux";
// import DatePicker from "react-datepicker";

const VendorModal = ({
  id,
  title,
  addData,
  setAddData,
  setApiError,
  apiError,
  setFile,
  file,
  loading,
  handleModalClose,
}) => {
  const addvendor = useSelector((state) => state.addvendor);
  const {
    success: addVendorSuccess,
    loading: addVendorLoading,
    error: addVendorError,
  } = addvendor;
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();

  const handleEditSubmit = (event) => {
    event.preventDefault();
    const form_data = new FormData();
    if (file) {
      form_data.append("tds_certificate", file[0]);
    }
    const postData = {
      name: addData?.name ? addData?.name : "",
      vendor_code: addData?.vendor_code ? addData?.vendor_code : "",
      address: addData?.address ? addData?.address : "",
      zip_code: addData?.zip_code ? addData?.zip_code : "",
      pan_number: addData?.pan_number ? addData?.pan_number : "",

      approved_amount: addData?.approved_amount ? addData?.approved_amount : "",
      tds_certificate_number: addData?.tds_certificate_number
        ? addData?.tds_certificate_number
        : "",
      gstin_number: addData?.gstin_number ? addData?.gstin_number : "",
      state: addData?.state ? addData?.state : "",
      // state_code: addData?.state_code ? addData?.state_code : "",
      phone: addData?.phone ? addData?.phone : "",
      contact_name: addData?.contact_name ? addData?.contact_name : "",
      email: addData?.email ? addData?.email : "",
      msme: addData?.msme ? addData?.msme : "",
      bank_name: addData?.bank_name ? addData?.bank_name : "",
      branch_name: addData?.branch_name ? addData?.branch_name : "",
      account_number: addData?.account_number ? addData?.account_number : "",
      ifsc_code: addData?.ifsc_code ? addData?.ifsc_code : "",
      swift: addData?.swift ? addData?.swift : "",
    };
    if (addData?.start_date) {
      postData.start_date = formatDateYYMMDD(addData.start_date);
    }
    if (addData?.end_date) {
      postData.end_date = formatDateYYMMDD(addData.end_date);
    }
    // Append postData properties to form_data
    for (const key in postData) {
      // eslint-disable-next-line react/prop-types
      // if (postData.hasOwnProperty(key)) {
      form_data.append(key, postData[key]);
      // }
    }
    // Dispatch the addVendor action with the form_data
    dispatch(addVendor(form_data));
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setAddData((prevState) => ({ ...prevState, [id]: value }));
    setApiError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleChangeField = (e) => {
    const { id, value } = e.target;
    setAddData((prevState) => ({ ...prevState, [id]: value }));
    setApiError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleFileChange = (event) => {
    setFile(event.target.files);
    setApiError((prevState) => ({ ...prevState, file: "" }));
  };

  useEffect(() => {
    setApiError(addVendorError);
  }, [addVendorError]);

  useEffect(() => {
    if (addVendorSuccess) {
      dispatch({ type: VENDOR_ADD_RESET });
      setAddData({
        pan_number: "",
        start_date: "",
        end_date: "",
        approved_amount: "",
        tds_certificate_number: "",
        tds_certificate: "",
        gstin_number: "",
        name: "",
        vendor_code: "",
        address: "",
        zip_code: "",
        state: "",
        // state_code: "",
        phone: "",
        contact_name: "",
        country_code: null,
        email: "",
        msme: "",
        bank_name: "",
        branch_name: "",
        account_number: "",
        ifsc_code: "",
        swift: "",
        ap_invoice_id: "",
      });
      // dispatch(getVendorList(page, perPage));
      // closeModal();
      handleModalClose("#vendor_modal");
      messageApi.open({
        type: "success",
        content: "Vendor created successfully",
        duration: 5,
      });
    }
  }, [addVendorSuccess]);

  console.log("id>>", id);

  const createVendor = (
    <div
      id={id}
      className="modal fade"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog"
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: "1050",
        }}
      >
        <div className="modal-content ">
          <div className="modal-header">
            <h4 className="modal-title">{title}</h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <CustomInput
                    id="name"
                    type="text"
                    value={addData?.name}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    placeholder="Name"
                    label="Name"
                    // errorMsg={errors?.name?.message}
                    // error={errors?.name}
                    apiError={apiError?.name}
                    autoComplete="false"
                    required={true}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <CustomInput
                    id="email"
                    type="email"
                    value={addData?.email}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    placeholder="Email"
                    label="Email"
                    // errorMsg={errors?.email?.message}
                    // error={errors?.email}
                    apiError={apiError?.email}
                    autoComplete="false"
                    // required={true}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <CustomInput
                    id="state"
                    type="type"
                    value={addData?.state}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    placeholder="State"
                    label="State"
                    // errorMsg={errors?.state?.message}
                    // error={errors?.state}
                    apiError={apiError?.state}
                    autoComplete="false"
                    // required={true}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <label>Address</label>

                  <textarea
                    // className={`form-control  ${
                    //   errors?.address ? "error-input" : ""
                    // }`}
                    id="address"
                    type="text"
                    placeholder="Address"
                    value={addData?.address}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    autoComplete="false"
                  />
                  {apiError?.address && (
                    <p className="text-danger">{apiError?.address}</p>
                  )}
                  {/* {errors?.address?.message && (
                    <p className="text-danger">{errors?.address?.message}</p>
                  )} */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <CustomInput
                    id="vendor_code"
                    type="type"
                    value={addData?.vendor_code}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    placeholder="Vendor Code"
                    label="Vendor Code"
                    apiError={apiError?.vendor_code}
                    // errorMsg={errors?.vendor_code?.message}
                    // error={errors?.vendor_code}
                    autoComplete="false"
                    // required={true}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <CustomInput
                    id="pan_number"
                    type="type"
                    value={addData?.pan_number}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    placeholder="Enter PAN number"
                    label="PAN"
                    // errorMsg={errors?.pan_number?.message}
                    // error={errors?.pan_number}
                    apiError={apiError?.pan_number}
                    autoComplete="false"
                    // required={true}
                  />
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <CustomInput
                    id="msme"
                    type="type"
                    value={addData?.msme}
                    onChange={handleChangeField}
                    placeholder="MSME"
                    label="MSME"
                    apiError={apiError?.msme}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <CustomInput
                    id="contact_name"
                    type="type"
                    value={addData?.contact_name}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    placeholder="Enter Contact Name"
                    label="Contact Name"
                    // errorMsg={errors?.contact_name?.message}
                    // error={errors?.contact_name}
                    apiError={apiError?.contact_name}
                    autoComplete="false"
                    // required={true}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <CustomInput
                    id="gstin_number"
                    type="type"
                    value={addData?.gstin_number}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    placeholder="GSTIN"
                    label="GSTIN"
                    apiError={apiError?.gstin_number}
                    autoComplete="false"
                    // required={true}
                  />
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <CustomInput
                    id="bank_name"
                    type="type"
                    value={addData?.bank_name}
                    onChange={handleChangeField}
                    placeholder="Bank Name"
                    label="Bank Name"
                    apiError={apiError?.bank_name}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <CustomInput
                    id="phone"
                    type="phone"
                    value={addData?.phone}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    placeholder="Enter phone number"
                    label="Phone"
                    // errorMsg={errors?.phone?.message}
                    // error={errors?.phone}
                    apiError={apiError?.phone}
                    autoComplete="false"
                    // required={true}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <CustomInput
                    id="zip_code"
                    type="type"
                    value={addData?.zip_code}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    placeholder="Zipcode"
                    label="Zipcode"
                    // errorMsg={errors?.zip_code?.message}
                    // error={errors?.zip_code}
                    apiError={apiError?.zip_code}
                    autoComplete="false"
                    // required={true}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <CustomInput
                    id="account_number"
                    type="type"
                    value={addData?.account_number}
                    onChange={handleChangeField}
                    placeholder="A/C No"
                    label="A/C No"
                    apiError={apiError?.account_number}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <CustomInput
                    id="ifsc_code"
                    type="type"
                    value={addData?.ifsc_code}
                    onChange={handleChangeField}
                    placeholder="IFSC"
                    label="IFSC"
                    apiError={apiError?.ifsc_code}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <CustomInput
                    id="branch_name"
                    type="type"
                    value={addData?.branch_name}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    placeholder="Bank branch"
                    label="Bank branch"
                    // errorMsg={errors?.branch_name?.message}
                    // error={errors?.branch_name}
                    apiError={apiError?.branch_name}
                    autoComplete="false"
                    // required={true}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <CustomInput
                    id="swift"
                    type="type"
                    value={addData?.swift}
                    onChange={handleChangeField}
                    placeholder="Swift"
                    label="Swift"
                    apiError={apiError?.swift}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <CustomInput
                    id="approved_amount"
                    type="type"
                    value={addData?.approved_amount}
                    onChange={handleChangeField}
                    placeholder="Approved Amount"
                    label="Approved Amount"
                    apiError={apiError?.approved_amount}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <CustomInput
                    id="tds_certificate_number"
                    type="type"
                    value={addData?.tds_certificate_number}
                    onChange={handleChangeField}
                    placeholder="TDS Certificate Number"
                    label="TDS Certificate Number"
                    apiError={apiError?.tds_certificate_number}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <label className="form-label">TDS Certificate</label>
                  <input
                    className="form-control"
                    type="file"
                    onChange={handleFileChange}
                    accept=".jpeg,.jpg,.png,.pdf"
                  />
                  {file && (
                    <blockquote>
                      <p className="mb-0">{file.name}</p>
                    </blockquote>
                  )}

                  {/* <blockquote>
                    <p className="mb-0">{editData?.tds_certificate}</p>
                  </blockquote> */}

                  {apiError?.tds_certificate && (
                    <p className="text-danger">{apiError?.tds_certificate}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* {id === "add_vendor" ? ( */}

          {/* ) : ( */}
          <div className="add-customer-btns text-end">
            {/* <Link to="/chartofaccount"> */}
            <button type="reset" className="btn btn-primary cancel me-2">
              Cancel
            </button>
            {/* </Link> */}
            <button className="btn btn-primary" onClick={handleEditSubmit}>
              {addVendorLoading ? <Spin /> : "Save"}
            </button>
          </div>
          {/* )} */}
        </div>
      </div>
    </div>
    // </div>
    // </div>
  );
  return <>{createVendor}</>;
};

VendorModal.propTypes = {
  id: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  handleEditSubmit: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  apiError: PropTypes.object,
  setAddData: PropTypes.func,
  addData: PropTypes.object,
  setApiError: PropTypes.func.isRequired,
  editData: PropTypes.object,
  handleDateChange: PropTypes.func,
  handleFileChange: PropTypes.func.isRequired,
  file: PropTypes.object,
  addVendorLoading: PropTypes.bool,
  isChanged: PropTypes.bool,
  loading: PropTypes.string,
  handleChangeField: PropTypes.func,
};

export default VendorModal;
