/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import {
  getUserActivityModuleList,
  getUserProfile,
} from "../../redux/actions/UserAction";
// import { itemRender } from "../../_components/paginationfunction";

import { Button, Input, Pagination, Spin, message } from "antd";
import { USER_PROFILE_UPDATE_RESET } from "../../redux/constants/UserConstant";
import ProfileUpdate from "./profileUpdate";
import OrganizationModal from "./organizationModal";
import { getCompanyList } from "../../redux/actions/commonApiAction";
import ChangePassword from "./ChangePassword";
import ProfileDetailUpdateModal from "./profileDetailUpdate";

export const formatMMDD = (date) => {
  const dateObj = new Date(date);

  // Get the month and day
  const options = { month: "long", day: "numeric" };
  const formattedDate = dateObj.toLocaleDateString("en-US", options);
  return formattedDate;
};

const Profile = () => {
  const [menu, setMenu] = useState(false);
  const [page, setPage] = useState({ page: 1, perPage: 50 });
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [profileUpdateError, setProfileUpdateError] = useState({});
  const [isChange, setIsChange] = useState(false);
  const dispatch = useDispatch();
  const fileRef = useRef(null);
  const [organizationData, setOrganizationData] = useState({});

  const userActivityList = useSelector((state) => state?.userActivityList);
  const { loading, data } = userActivityList;

  const userProfileList = useSelector((state) => state?.getuserProfile);
  const { profileloading, profileData } = userProfileList;

  const companyList = useSelector((state) => state.companyList);
  const { company } = companyList;
  const userData = useSelector((state) => state.userSignin);
  const { role } = userData;
  const permission = ["owner", "admin"];

  const userProfileUpdation = useSelector(
    (state) => state?.userProfileUpdation
  );
  const {
    loading: userProfileUpdateLoading,
    success: userProfileUpdateSuccess,
    error: userProfileUpdateError,
  } = userProfileUpdation;

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const handleTableChange = (page, pageSize) => {
    setPage({ page: page, perPage: pageSize });
  };

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
    fileRef.current.value = "";
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const onModalClose = () => {
    $("#profile_update_modal").on("hide.bs.modal", () => {
      dispatch({ type: USER_PROFILE_UPDATE_RESET });
      setIsChange(false);
      setSelectedFile(null);
      setProfileUpdateError({});
      setIsChange(false);
      setIsModalVisible(false);
      fileRef.current.value = "";
      dispatch({ type: USER_PROFILE_UPDATE_RESET });
    });
  };

  useEffect(() => {
    dispatch(getCompanyList());
  }, []);

  const handleEditClick = (event) => {
    event.preventDefault();
    dispatch(getCompanyList());
  };
  const handleEditProfileClick = (event) => {
    event.preventDefault();
    dispatch(getUserProfile());
  };
  useEffect(() => {
    if (userProfileUpdateSuccess) {
      dispatch({ type: USER_PROFILE_UPDATE_RESET });
      setSelectedFile(null);
      setProfileUpdateError({});
      setIsChange(false);
      setIsModalVisible(false);
      handleModalClose("#profile_update_modal");
      closeModal();
      dispatch(getUserProfile());
      messageApi.open({
        type: "success",
        content: "Profile Updated Successfully",
        duration: 5,
      });
    }
  }, [userProfileUpdateSuccess]);

  useEffect(() => {
    if (userProfileUpdateError) {
      setProfileUpdateError(userProfileUpdateError);
    }
  }, [userProfileUpdateError]);

  useEffect(() => {
    onModalClose();
  }, []);

  useEffect(() => {
    dispatch(getUserActivityModuleList(page?.page, page?.perPage));
    dispatch(getUserProfile());
  }, [page?.page, page?.perPage]);

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        {contextHolder}
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        {/** Page Wrapper  */}
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="row justify-content-lg-center">
              <div className="col-lg-10">
                {/* Page Header */}
                <div className="page-header">
                  <div className="content-page-header">
                    <h5>Profile</h5>
                  </div>
                </div>
                <br />
                <br />
                <br />

                <ProfileUpdate
                  profileData={profileData}
                  setIsModalVisible={setIsModalVisible}
                  isModalVisible={isModalVisible}
                  setSelectedFile={setSelectedFile}
                  selectedFile={selectedFile}
                  setProfileUpdateError={setProfileUpdateError}
                  profileUpdateError={profileUpdateError}
                  isChange={isChange}
                  setIsChange={setIsChange}
                  userProfileUpdateLoading={userProfileUpdateLoading}
                  fileRef={fileRef}
                />
                <div className="row">
                  <div className="col-lg-6">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title d-flex justify-content-between">
                          <span>Profile</span>
                          <Link
                            className="btn btn-sm btn-white"
                            data-bs-toggle="modal"
                            to="#edit_profile"
                            role="button"
                            onClick={handleEditProfileClick}
                          >
                            Edit
                          </Link>
                        </h5>
                      </div>
                      <div className="card-body">
                        <ul className="list-unstyled mb-0">
                          <li className="py-0">
                            <h6>Name</h6>
                          </li>
                          <li>
                            {profileData?.first_name || profileData?.last_name
                              ? `${profileData.first_name} ${profileData.last_name}`
                              : profileData?.first_name ||
                                profileData?.last_name ||
                                "-"}
                          </li>
                          <li className="pt-2 pb-0">
                            <h6>About</h6>
                          </li>
                          <li>{profileData?.username}</li>
                          <li>{profileData?.role}</li>
                          {/* <li>Hafner Pvt Ltd.</li> */}
                          <li className="pt-2 pb-0">
                            <h6>Contacts</h6>
                          </li>
                          <li>{profileData?.email}</li>
                          {/* <li>charleshafner@example.com</li> */}
                          {/* <li>+1 (304) 499-13-66</li> */}
                          <li>{profileData?.phone}</li>
                          <li className="pt-2 pb-0">
                            <h6>Glance Email</h6>
                          </li>
                          <li>{profileData?.glance_email}</li>
                          {/* <li className="pt-2 pb-0">
                            <h6>Address</h6>
                          </li>
                          <li>
                            4663 Agriculture Lane,
                            <br />
                            Miami,
                            <br />
                            Florida - 33165,
                            <br />
                            United States.
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title d-flex justify-content-between">
                          <span>Organization</span>
                          {permission.includes(role.toLowerCase()) && (
                            <Link
                              className="btn btn-sm btn-white"
                              data-bs-toggle="modal"
                              to="#edit_organization"
                              role="button"
                              onClick={handleEditClick}
                            >
                              Edit
                            </Link>
                          )}
                        </h5>
                      </div>
                      <div className="card-body">
                        <ul className="list-unstyled mb-0">
                          <li className="py-0">
                            <h6>Organization Name</h6>
                          </li>
                          <li>{company?.name}</li>
                          <li className="pt-2 pb-0">
                            <h6>GST</h6>
                          </li>
                          <li>{company?.gstin_number}</li>
                          <li className="pt-2 pb-0">
                            <h6>PAN</h6>
                          </li>
                          <li>{company?.pan_number}</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <ChangePassword />

                    {/* <div className="col-lg-8">
                    <div className="card" style={{ marginBottom: 0 }}>
                      <div className="card-header">
                        <h5 className="card-title">Activity</h5>
                      </div>
                      {loading ? (
                        <Spin />
                      ) : (
                        <div className="card-body card-body-height">
                          <ul className="activity-feed">
                            {data?.results?.map((ele, index) => (
                              <li className="feed-item" key={index}>
                                <div className="feed-date">
                                  {ele?.timestamp
                                    ? formatMMDD(ele?.timestamp)
                                    : ""}
                                </div>
                                <span className="feed-text">
                                  <b>{ele?.description}</b>
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      {data?.results?.length > 0 && (
                        <>
                          <Pagination
                            total={data?.count}
                            defaultPageSize={page?.perPage}
                            // showTotal={(total, range) =>
                            //   `Showing ${range[0]} to ${range[1]} of ${total} entries`
                            // }
                            current={page?.page}
                            onChange={handleTableChange}
                            pageSizeOptions={[20, 30, 50, 100]}
                            itemRender={(current, type) => {
                              if (type === "page") {
                                return (
                                  <span style={{ margin: "0 5px" }}>
                                    {current}
                                  </span>
                                );
                              }
                              return null; // Hide other elements (prev and next buttons)
                            }}
                            style={{
                              position: "relative",
                              marginTop: "0px !important",
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <OrganizationModal
          id={"edit_organization"}
          title={"Edit Organization"}
          setOrganizationData={setOrganizationData}
          organizationData={organizationData}
          company={company}
        />
        <ProfileDetailUpdateModal
          id="edit_profile"
          title="Edit Profile"
          profileData={profileData}
        />
      </div>
    </>
  );
};
export default Profile;
