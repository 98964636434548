/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { addInvoice } from "../../redux/actions/ARmoduleAction";
import { INVOICE_CREATE_RESET } from "../../redux/constants/ARmoduleConstant";
import { formatDateYYMMDD } from "./constant";
import AddEditInvoiceModal from "./addEditInvoiceModal";
import { tdsCalculationApi } from "../../redux/actions/APmoduleAction";
import { useSelector } from "react-redux";
import {
  getGroupList,
  getLedgerModuleList,
  postLedgerModuleList,
} from "../../redux/actions/LedgerModuleAction";
import { message } from "antd";
import Header from "../../layouts/Header";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { POST_LEDGERLIST_RESET } from "../../redux/constants/ledgerListModuleConstant";
import {
  getCompanyList,
  getCustomerList,
} from "../../redux/actions/commonApiAction";
import Sidebar from "../../layouts/Sidebar";
import useCalculateTotals from "../../helpers/calculation";
import useUpdateFooterData from "../../customers/updateFooterData";
import CustomerModal from "./recurring/customerModal";
import { customerCreation } from "../../redux/actions/CustomerModuleAction";
import { CUSTOMER_ADD_RESET } from "../../redux/constants/CustomerModuleCnstant";

const schema = yup.object({
  // invoice_number: yup
  //   .string()
  //   .required("Invoice number is required")
  //   .trim(),
  nature_of_supply: yup
    .string()
    .required("Nature of supply is required")
    .trim(),
  invoice_date: yup
    .date()
    .required("Invoice date is required")
    .nullable()
    .required("Invoice date is required"),
  // billing_period: yup
  //   .date()
  //   .required("Billing period is required")
  //   .nullable()
  //   .required("Billing period is required"),
  customer: yup
    .number()
    .required("Customer is required")
    .typeError("Customer must be a number"),
});

const AddInvoice = () => {
  const dispatch = useDispatch();
  const datePickerRef = useRef(null);
  // const modalRef = useRef(null);
  const selectRefs = useRef({});
  const history = useHistory();
  const [menu, setMenu] = useState(false);
  const [data, setData] = useState([
    {
      ledger: null,
      description: "",
      quantity: "",
      rate: "",
      amount: "",
      gst_rate: "",
      gst_amount: "",
      total: "",
    },
  ]);
  const [calculationData, setCalculationData] = useState({});
  const [editable, setEditable] = useState({});
  const [invoiceData, setInvoiceData] = useState({
    invoice_number: "",
    invoice_date: null,
    billing_period: null,
    nature_of_supply: "",
    po_num: "",
    po_date: "",
    payment_term: "",
    remark: "",
    photo: null,
    customer: null,
  });
  const [addError, setAddError] = useState({});
  const [itemsError, setItemsError] = useState(null);
  const [showErrors, setShowErrors] = useState(true);

  const [footerData, setFooterData] = useState({});
  const [ledgerData, setLedgerData] = useState([
    {
      description: "",
      quantity: "",
      rate: "",
      total: "",
    },
  ]);
  const [file, setFile] = useState(null);
  const [customerDetail, setCustomerDetail] = useState({});
  const [ledgerListData, setLedgerListData] = useState([]);
  const [ledgerIndexData, setLedgerIndexData] = useState("");
  const [incomeId, setIncomeId] = useState([]);
  const [ledgerPage, setLedgerPage] = useState({ page: 1, perPage: 10 });
  const [addLedgerData, setAddLedgerData] = useState({
    title: "",
    group: null,
    description: "",
  });
  const [apiError, setApiError] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [preventApiCall, setPreventApiCall] = useState(false);
  //customer
  const [addCustomer, setAddCustomer] = useState({});
  const customerModuleList = useSelector((state) => state?.customerModuleList);
  const { page, perPage } = customerModuleList;
  const createCustomer = useSelector((state) => state?.createCustomer);
  const [customerApiError, setCustomerApiError] = useState({});
  const [isDisable, setIsDisable] = useState(false);
  const {
    loading: createCustomerLoading,
    error: createCustomerError,
    success: createCustomerSuccess,
    data: postCustomerData,
  } = createCustomer;

  const ledgerList = useSelector((state) => state?.ledgerList);
  const { loading: ledgerListLoading, data: expenseList } = ledgerList;

  const createInvoice = useSelector((state) => state.addInvoice);
  const { success, loading, error } = createInvoice;

  const customerList = useSelector((state) => state?.customerList);
  const { customer } = customerList;

  const companyList = useSelector((state) => state.companyList);
  const { company } = companyList;

  const [messageApi, contextHolder] = message.useMessage();

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: !isModalVisible && yupResolver(schema),
    defaultValues: invoiceData,
  });

  const totals = useCalculateTotals(data);

  useUpdateFooterData(
    footerData,
    setFooterData,
    totals,
    [],
    company,
    customerDetail
  );

  useEffect(() => {
    const fieldOrder = ["nature_of_supply", "invoice_date", "customer"];
    const firstErrorField = fieldOrder.find((field) => errors[field]);
    if (firstErrorField) {
      let inputElement;
      if (firstErrorField === "customer") {
        inputElement = selectRefs.current[firstErrorField];
      } else {
        inputElement = document.getElementById(firstErrorField);
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [errors]);

  useEffect(() => {
    return () => {
      dispatch({ type: INVOICE_CREATE_RESET });
    };
  }, [dispatch]);

  useEffect(() => {
    if (error?.items) {
      setShowErrors(true);
      setItemsError(error?.items);
    } else {
      setAddError(error?.error ? error?.error : {});
    }
  }, [error]);

  useEffect(() => {
    const fieldOrder = [
      "nature_of_supply",
      "invoice_date",
      "invoice_date",
      "customer",
      "photo",
    ];
    const firstErrorField = fieldOrder.find((field) => error && error[field]);
    if (firstErrorField) {
      let inputElement;
      if (firstErrorField === "company") {
        inputElement = selectRefs.current[firstErrorField];
      } else {
        inputElement = document.getElementById(firstErrorField);
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [error]);

  useEffect(() => {
    dispatch(getCustomerList());
    dispatch(getGroupList());
  }, [dispatch]);

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
    setPreventApiCall(false);
    setIsModalVisible(false);
    setAddLedgerData({
      title: "",
      group: null,
      description: "",
    });
    setApiError({});
  };

  // useEffect(() => {
  //   onModalOpen();
  //   onModalClose();
  // }, []);

  useEffect(() => {
    dispatch(getCompanyList());
  }, []);

  useEffect(() => {
    if (success) {
      dispatch({ type: INVOICE_CREATE_RESET });
      setInvoiceData({
        invoice_number: "",
        invoice_date: "",
        billing_period: "",
        nature_of_supply: "",
        po_num: "",
        po_date: "",
        payment_term: "",
        remark: "",
        photo: null,
        company: "",
      });
      setData([
        {
          ledger: null,
          description: "",
          quantity: "",
          rate: "",
          amount: "",
          gst_rate: "",
          gst_amount: "",
          total: "",
        },
      ]);
      setItemsError([
        {
          description: "",
          quantity: "",
          rate: "",
          tax_rate: "",
          amount: "",
          product_code: "",
        },
      ]);
      handleModalClose("#add_invoice");
      messageApi.open({
        type: "success",
        content: "invoice created successfully",
        duration: 5,
      });
      history.push("/invoice-list");
    }
  }, [success]);

  useEffect(() => {
    setCustomerApiError(createCustomerError);
  }, [createCustomerError]);

  useEffect(() => {
    if (createCustomerSuccess && postCustomerData) {
      dispatch({ type: CUSTOMER_ADD_RESET });
      dispatch(getCustomerList());
      // dispatch(getCustomerModuleList(page, perPage));
      setAddCustomer({
        pan_number: "",
        gstin_number: "",
        name: "",
        zoho_contact_id: null,
        customer_code: "",
        address: "",
        zip_code: "",
        state: "",
        phone: "",
        contact_name: "",
        email: "",
        zoho_address_id: null,
        location: "",
      });
      setInvoiceData((prevState) => ({
        ...prevState,
        customer: postCustomerData ? postCustomerData?.id : null,
      }));
      setValue("customer", postCustomerData?.id);
      handleModalClose("#customer_modal");
      messageApi.open({
        type: "success",
        content: "customer created successfully",
        duration: 5,
      });
    }
  }, [createCustomerSuccess, postCustomerData]);

  //   const onModalOpen = () => {
  //     $("#add_ledger").on("shown.bs.modal", () => {
  //       console.log("Im triggered");
  //       setIsModalVisible(true);
  //       setAddLedgerData({ title: "", type: "", description: "" });
  //       setApiError({});
  //     });
  //   };
  // console.log("isMo",isModalVisible);
  //   const onModalClose = () => {
  //     console.log("Im cllose");

  //     $("#add_ledger").on("hide.bs.modal", () => {
  //       setIsModalVisible(false);
  //       setAddLedgerData({ title: "", type: "", description: "" });
  //     });
  //   };

  const postLedgerList = useSelector((state) => state?.postLedgerList);
  const {
    loading: postLedgerLoading,
    error: postLedgerError,
    success: postLedgerSuccess,
    data: postLedgerData,
  } = postLedgerList;

  const groupList = useSelector((state) => state?.groupList);
  const { groupData } = groupList;

  useEffect(() => {
    if (postLedgerError) {
      setApiError(postLedgerError);
    }
  }, [postLedgerError]);

  useEffect(() => {
    if (groupData?.length) {
      const fetchIncomeIds = groupData
        ?.filter((el) => el?.title === "Income" || el?.title === "Other Income")
        ?.map((el) => el?.id);
      setIncomeId(fetchIncomeIds);
    }
  }, [groupData]);

  useEffect(() => {
    if (postLedgerSuccess && postLedgerData && incomeId?.length) {
      dispatch({ type: POST_LEDGERLIST_RESET });
      if (incomeId?.includes(postLedgerData?.ledger_group)) {
        const updatedData = [...data];
        updatedData[ledgerIndexData]["ledger"] = postLedgerData?.id;
        setData(updatedData);
        setLedgerListData([...ledgerListData, postLedgerData]);
      }
      handleModalClose("#add_ledger");
      setAddLedgerData({ title: "", group: null, description: "" });
      setApiError({});
      setIsModalVisible(false);
      messageApi.open({
        type: "success",
        content: "Ledger Created Successfully",
        duration: 5,
      });
    }
  }, [postLedgerSuccess, postLedgerData, incomeId, ledgerIndexData]);

  useEffect(() => {
    if (invoiceData?.customer) {
      const customerDetails = customer?.find(
        (el) => el?.id === invoiceData?.customer
      );
      setCustomerDetail({
        customer_code: customerDetails?.customer_code,
        gstin_number: customerDetails?.gstin_number,
        location: customerDetails?.location,
      });
    }
  }, [customer, invoiceData?.customer]);

  useEffect(() => {
    if (!customerDetail?.gstin_number && invoiceData?.customer) {
      const updatedData = data.map((item) => ({
        ...item,
        gst_rate: 0,
        gst_amount: 0,
      }));
      setData(updatedData);
      setFooterData((prevState) => ({
        ...prevState,
        gst_section: {},
        total_tax: 0,
      }));
    }
  }, [customerDetail?.gstin_number, invoiceData?.customer]);

  const addInvoiceSubmit = (postData) => {
    const ledger = data?.map((ele) => ({
      ...ele,
      ledger: ele?.ledger,
    }));

    const payload = {
      nature_of_supply: postData?.nature_of_supply,
      invoice_date: postData?.invoice_date
        ? formatDateYYMMDD(postData?.invoice_date)
        : "",
      customer: postData?.customer ? postData?.customer : null,
      items: ledger,
    };
    dispatch(addInvoice(payload));
  };

  const addLedgerSubmit = (event) => {
    event.preventDefault();
    if (isModalVisible) {
      const postData = {
        title: addLedgerData?.title,
        ledger_group: addLedgerData?.group ? addLedgerData?.group?.value : null,
        description: addLedgerData?.description,
      };
      dispatch(postLedgerModuleList(postData));
    }
  };

  const handleCellChange = (rowId, field, value, index) => {
    const updatedData = [...data];
    if (updatedData[index]) {
      updatedData[index][field] = value;
      setData(updatedData);
    }

    setCalculationData((prevState) => ({
      ...prevState,
      indexId: index,
      key: field,
    }));
  };

  useEffect(() => {
    if (
      calculationData?.indexId !== "" &&
      calculationData?.key !== "gst_amount" &&
      calculationData?.key !== "amount" &&
      calculationData?.key !== "total"
    ) {
      const updatedData = [...data];
      if (updatedData[calculationData?.indexId]) {
        const rate =
          parseFloat(updatedData[calculationData?.indexId]["rate"]) || 0;
        const quantity =
          parseFloat(updatedData[calculationData?.indexId]["quantity"]) || 0;
        const gstRate =
          parseFloat(updatedData[calculationData?.indexId]["gst_rate"]) || 0;
        const amount = rate * quantity;

        updatedData[calculationData?.indexId]["amount"] = amount.toFixed(2);

        const gstAmount = (amount * gstRate) / 100;
        updatedData[calculationData?.indexId]["gst_amount"] = gstAmount.toFixed(
          2
        );

        const total = amount + gstAmount;
        updatedData[calculationData?.indexId]["total"] = total.toFixed(2);
      }
      setData(updatedData);
    }
  }, [calculationData]);

  const handleDoubleClick = (rowId, field) => {
    setEditable({ rowId, field });
  };

  const handleSelectCellChange = (selectedOption, id, rowKey, index) => {
    const updatedData = [...data];
    updatedData[index][id] = selectedOption;
    if (id === "tds_ledger") {
      setData(updatedData);
      // setItemId(index);
      const data = {
        tds_section_id: selectedOption?.value,
        item_id: rowKey,
      };

      dispatch(tdsCalculationApi(data));
    }
    setData(updatedData);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    dispatch({ type: INVOICE_CREATE_RESET });
    setInvoiceData((prevState) => ({ ...prevState, [id]: value }));
    setAddError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleDateChange = (id, date) => {
    setInvoiceData((prevState) => ({ ...prevState, [id]: date }));
    setAddError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleCommonChange = (e) => {
    const { id, value } = e.target;
    setInvoiceData((prevState) => ({ ...prevState, [id]: value }));
    setAddError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleCommonDateChange = (id, date) => {
    setInvoiceData((prevState) => ({ ...prevState, [id]: date }));
    setAddError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleSelectChange = (id, selectedOption) => {
    setAddError((prevState) => ({ ...prevState, [id]: "" }));
    setInvoiceData((prevState) => ({ ...prevState, [id]: selectedOption }));
  };

  const handleItemChange = (e, itemIndex) => {
    const { id, value } = e.target;
    const filterData = [...ledgerData];
    if (filterData[itemIndex]) {
      filterData[itemIndex][id] = value;
    }
    const newErrors = [...itemsError];
    if (newErrors[itemIndex]) {
      newErrors[itemIndex][id] = "";
    }
    setItemsError(newErrors);
    setLedgerData(filterData);
  };

  const handleRemove = (event, tabIndex) => {
    event.preventDefault();
    const filterData = data?.filter((el, index) => index !== tabIndex);
    setData(filterData);
  };

  // const handleFileChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   setAddError((prevState) => ({ ...prevState, photo: "" }));
  //   setFile(selectedFile);
  // };

  const handleAddClick = (event) => {
    event.preventDefault();
    setData([
      ...data,
      {
        ledger: null,
        description: "",
        quantity: "",
        rate: "",
        amount: "",
        gst_rate: "",
        gst_amount: "",
        total: "",
      },
    ]);
  };

  const handleAddSubmit = (event) => {
    event.preventDefault();
    const postData = {
      pan_number: addCustomer?.pan_number ? addCustomer?.pan_number : null,
      gstin_number: addCustomer?.gstin_number
        ? addCustomer?.gstin_number
        : null,
      name: addCustomer?.name ? addCustomer?.name : "",
      address: addCustomer?.address ? addCustomer?.address : "",
      zip_code: addCustomer?.zip_code ? addCustomer?.zip_code : "",
      state: addCustomer?.state ? addCustomer?.state : "",
      phone: addCustomer?.phone ? addCustomer?.phone : "",
      contact_name: addCustomer?.contact_name ? addCustomer?.contact_name : "",
      email: addCustomer?.email ? addCustomer?.email : "",
      location: addCustomer?.location,
      customer_code: addCustomer?.customer_code
        ? addCustomer?.customer_code
        : null,
    };
    dispatch(customerCreation(postData));
    setIsDisable(false);
  };

  useEffect(() => {
    if ((ledgerPage?.page > 1 && expenseList?.next) || ledgerPage?.page === 1) {
      dispatch(
        getLedgerModuleList("", ledgerPage?.page, ledgerPage?.perPage, true)
      );
      dispatch(
        getLedgerModuleList("", ledgerPage?.page, ledgerPage?.perPage, true)
      );
    }
  }, [ledgerPage?.page, ledgerPage?.perPage]);

  useEffect(() => {
    if (expenseList?.results) {
      const newVendors = expenseList?.results;

      // Create a Set of the current vendor ids/names for quick look-up
      const existingVendorIds = new Set(
        ledgerListData.map((vendor) => vendor.id)
      ); // Assuming `id` is the unique identifier

      // Filter newVendors to only include those that are not already in vendorList
      const uniqueNewVendors = newVendors.filter(
        (vendor) => !existingVendorIds.has(vendor.id)
      );

      // Combine the previous vendorList with the unique new ones
      const combinedVendorList = [...ledgerListData, ...uniqueNewVendors];
      setLedgerListData(combinedVendorList); // Update state with the combined list
    }
  }, [expenseList]);

  const handleLedgerPopupScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollHeight - scrollTop <= clientHeight + 10 &&
      !ledgerListLoading &&
      expenseList?.results?.length
    ) {
      setLedgerPage((prevState) => ({
        ...prevState,
        page: prevState?.page + 1,
        perPage: prevState?.perPage,
      }));
    }
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  // const addInvoiceModal = (
  //   <CreateModal
  //     id="add_invoice"
  //     modalRef={modalRef}
  //     show={show}
  //     title="Create invoice"
  //     modalbody={
  //       <>
  //         <div className="modal-body p-4">
  //           <div className="row">
  //             <AddEditInvoiceModal
  //               id={"add_invoice"}
  //               handleSubmit={handleSubmit}
  //               addInvoiceSubmit={addInvoiceSubmit}
  //               control={control}
  //               handleChange={handleChange}
  //               errors={errors}
  //               addError={addError}
  //               handleDateChange={handleDateChange}
  //               invoiceData={invoiceData}
  //               handleCommonChange={handleCommonChange}
  //               handleCommonDateChange={handleCommonDateChange}
  //               datePickerRef={datePickerRef}
  //               selectRefs={selectRefs}
  //               customer={customer}
  //               handleSelectChange={handleSelectChange}
  //               customerDetail={customerDetail}
  //               // handleFileChange={handleFileChange}
  //               file={file}
  //               ledgerData={ledgerData}
  //               handleItemChange={handleItemChange}
  //               itemsError={itemsError}
  //               handleAddClick={handleAddClick}
  //               handleRemove={handleRemove}
  //               handleModalClose={handleModalClose}
  //               loading={loading}
  //               handleCellChange={handleCellChange}
  //               handleSelectCellChange={handleSelectCellChange}
  //               data={data}
  //               setData={setData}
  //               editable={editable}
  //               setEditable={setEditable}
  //               handleDoubleClick={handleDoubleClick}
  //               expenseList={expenseList}
  //               ledgerListLoading={ledgerListLoading}
  //               setAddLedgerData={setAddLedgerData}
  //               addLedgerData={addLedgerData}
  //               apiError={apiError}
  //               setApiError={setApiError}
  //               setIsModalVisible={setIsModalVisible}
  //               isModalVisible={isModalVisible}
  //               postLedgerLoading={postLedgerLoading}
  //             />
  //           </div>
  //         </div>
  //       </>
  //     }
  //   />
  // );

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        {contextHolder}
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        {/* <!-- Page Wrapper --> */}
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="card mb-0">
              <div className="card-body">
                <div className="page-header">
                  <div className="content-page-header">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a>AR module</a>
                      </li>
                      <li className="breadcrumb-item active">
                        <Link to="/invoice-list">Invoice List</Link>
                      </li>
                      <li className="breadcrumb-item active">Add Invoice</li>
                    </ul>
                  </div>
                </div>
                {/* {editInvoiceLoading ? (
                  <Spin />
                ) : ( */}
                <div className="row">
                  <AddEditInvoiceModal
                    id={"add_invoice"}
                    showErrors={showErrors}
                    setShowErrors={setShowErrors}
                    error={error}
                    itemsError={itemsError}
                    handleSubmit={handleSubmit}
                    addInvoiceSubmit={addInvoiceSubmit}
                    control={control}
                    handleChange={handleChange}
                    errors={errors}
                    addError={addError}
                    handleDateChange={handleDateChange}
                    invoiceData={invoiceData}
                    handleCommonChange={handleCommonChange}
                    handleCommonDateChange={handleCommonDateChange}
                    datePickerRef={datePickerRef}
                    selectRefs={selectRefs}
                    customer={customer}
                    handleSelectChange={handleSelectChange}
                    customerDetail={customerDetail}
                    // handleFileChange={handleFileChange}
                    file={file}
                    ledgerData={ledgerData}
                    handleItemChange={handleItemChange}
                    handleAddClick={handleAddClick}
                    handleRemove={handleRemove}
                    handleModalClose={handleModalClose}
                    loading={loading}
                    handleCellChange={handleCellChange}
                    handleSelectCellChange={handleSelectCellChange}
                    data={data}
                    setData={setData}
                    editable={editable}
                    setEditable={setEditable}
                    handleDoubleClick={handleDoubleClick}
                    expenseList={expenseList}
                    ledgerListLoading={ledgerListLoading}
                    setAddLedgerData={setAddLedgerData}
                    addLedgerData={addLedgerData}
                    apiError={apiError}
                    setApiError={setApiError}
                    setIsModalVisible={setIsModalVisible}
                    isModalVisible={isModalVisible}
                    postLedgerLoading={postLedgerLoading}
                    footerData={footerData}
                    setFooterData={setFooterData}
                    addLedgerSubmit={addLedgerSubmit}
                    handleLedgerPopupScroll={handleLedgerPopupScroll}
                    ledgerListData={ledgerListData}
                    setLedgerIndexData={setLedgerIndexData}
                  />
                </div>
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
        <CustomerModal
          setAddCustomer={setAddCustomer}
          addCustomer={addCustomer}
          setCustomerApiError={setCustomerApiError}
          customerApiError={customerApiError}
          handleModalClose={handleModalClose}
          handleEditSubmit={handleAddSubmit}
          loading={createCustomerLoading}
          isDisable={isDisable}
          setIsDisable={setIsDisable}
          setIsModalVisible={setIsModalVisible}
          isModalVisible={isModalVisible}
        />
      </div>
    </>
  );
};

export default AddInvoice;
